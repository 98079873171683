import IconClose from '../../assets/new-header/botao_fechar.png';
import StatusEmConsulta from '../../assets/consulta_status/em_consulta.svg';

import StatusNaoIniciada from "../../assets/consulta_status/nao_iniciada.svg";
import StatusAgendada from "../../assets/consulta_status/agendada.svg";
import StatusSalaEspera from "../../assets/consulta_status/sala_de_espera.svg";
import StatusConcluida from '../../assets/consulta_status/concluida.svg';
import StatusNaoAconteceu from '../../assets/consulta_status/nao_aconteceu.svg';
import StatusPosConsulta from '../../assets/consulta_status/pos_consulta.svg';

import IconPara from '../../assets/new-header/icon_alterar_etapa.png';
import { getTransicoesFromResponse } from '@utils/conversaoV2';
import {
  Container,
  ButtonContainer,
  ButtonClose,
  Popup,
  PopupHeader,
  PopUpContent,
  ItemMenu,
  ItemMenuTitle,
  ItemMenuImages
} from './styles';
import { useConsulta } from '@hooks/useConsulta';
import { useEffect, useState } from 'react';
import { getStatusIcon } from '@utils/consultaHelper';

type TransicaoDTO = {
  id: string;
  nome: string;
  proximaEtapa: string; //"sala_de_espera"
}

export function TransicaoJornada({ onClose, handleAlterarStatus }) {
  const [ transicoes, setTransicoes] = useState<TransicaoDTO[]>([]);

  const { consultaData } = useConsulta();

  useEffect(() => {
    const new_transicoes = getTransicoesFromResponse(consultaData);
    setTransicoes(new_transicoes);
  }, [consultaData])

  return (
  <Container>
    <ButtonContainer>
      <ButtonClose onClick={onClose}>
        <img src={IconClose} alt="Fechar"/>
      </ButtonClose>
    </ButtonContainer>
    <Popup>
      <PopupHeader>Confirme a transição desejada</PopupHeader>
      <PopUpContent>
        {transicoes.map(transicao => (
          <ItemMenu onClick={() => handleAlterarStatus(transicao.id)} key={transicao.id}>
            <ItemMenuTitle>{transicao.nome}</ItemMenuTitle>
            <ItemMenuImages>
              <img src={getStatusIcon(consultaData.payload.situacao.etapa)} alt="em consulta" />
              <img src={IconPara} alt="para" />
              <img src={getStatusIcon(transicao.proximaEtapa)} alt={transicao.proximaEtapa} />
            </ItemMenuImages>
          </ItemMenu>
        ))}
      </PopUpContent>
    </Popup>
  </Container>
  );
}