import { FundoDeOlhoDTO } from '@dtos/ConsultaDTO';
import { FundoDeOlhoOlho } from './olho';
import { FundoDeOlhoRetina } from './retina';
import {
  Tabela,
  Coluna,
  Label,
  LabelOlho,
} from './styles';
import { Radio } from '@components/Radio';

interface SegmentoAnteriorOlhoProps {
  data: FundoDeOlhoDTO,
  handleChange: (values: FundoDeOlhoDTO) => void;
}

export function FundoDeOlho({
  data,
  handleChange,  
} : SegmentoAnteriorOlhoProps) {
  function handleChangeOlhoDireito(values) {
    const new_data = {...data, "OD": values};
    handleChange(new_data);
  }

  function handleChangeOlhoEsquerdo(values) {
    const new_data = {...data, "OE": values};
    handleChange(new_data);
  }
  
  function handleAtivar() {
    handleChange({...data, "ativarFundoDeOlho": !data.ativarFundoDeOlho});
  }

  if(!data) {
    data = {
      "ativarFundoDeOlho": false, 
      "OD": {
        "escDiscoV": "", 
        "escDiscoH": "", 
        "coloracao": "róseo",
        "margens": "delimitadas", 
        "rimaNeural": "ISNT preservado",
        "macula": "seca",
        "poloPosterior": "", 
        "vasos": "sem alterações", 
        "periferia": "sem alterações", 
        "vitreo": "transparente"
      },
      "OE": {
        "escDiscoV": "", 
        "escDiscoH": "", 
        "coloracao": "róseo",
        "margens": "delimitadas", 
        "rimaNeural": "ISNT preservado",
        "macula": "seca",
        "poloPosterior": "", 
        "vasos": "sem alterações", 
        "periferia": "sem alterações", 
        "vitreo": "transparente"
      },
    }
  }

  return (
  <>
    <Radio label="Fundo de olho" status={data.ativarFundoDeOlho} 
      onChange={handleAtivar}
    />
    { data.ativarFundoDeOlho && 
      <>
        <Tabela key="nervo_otico">
          <Coluna>
            <Label className="ui_labels_bold">Nervo ótico</Label>
            <Label className="ui_labels">Esc/Disco V</Label>
            <Label className="ui_labels">Esc/Disco H</Label>
            <Label className="ui_labels">Coloração</Label>
            <Label className="ui_labels">Margens</Label>
            <Label className="ui_labels">Rima neural</Label>
          </Coluna>
          <Coluna>
            <LabelOlho>OD</LabelOlho>
            <FundoDeOlhoOlho 
              data={data && data.OD} 
              handleChange={handleChangeOlhoDireito}
            />
          </Coluna>
          <Coluna>
            <LabelOlho>OE</LabelOlho>
            <FundoDeOlhoOlho 
              data={data && data.OE} 
              handleChange={handleChangeOlhoEsquerdo}
            />
          </Coluna>
        </Tabela>
        <Tabela key="retina">
          <Coluna>
            <Label className="ui_labels_bold">Retina</Label>
            <Label className="ui_labels">Mácula</Label>
            <Label className="ui_labels">Pólo posterior</Label>
            <Label className="ui_labels">Vasos</Label>
            <Label className="ui_labels">Periferia</Label>
            <Label className="ui_labels">Vítreo</Label>
          </Coluna>
          <Coluna>
            <LabelOlho>OD</LabelOlho>
            <FundoDeOlhoRetina 
              data={data && data.OD} 
              handleChange={handleChangeOlhoDireito}
            />
          </Coluna>
          <Coluna>
            <LabelOlho>OE</LabelOlho>
            <FundoDeOlhoRetina 
              data={data && data.OE} 
              handleChange={handleChangeOlhoEsquerdo}
            />
          </Coluna>
        </Tabela>
      </>
    }
  </>
  );
}