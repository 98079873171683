import { AUTH_STORAGE } from './storageConfig';

type StorageAuthTokenProps = {
  token: string;
  refresh_token: string;
}

export async function storageAuthTokenSave({token, refresh_token}: StorageAuthTokenProps) {
  await localStorage.setItem(AUTH_STORAGE, JSON.stringify({ token, refresh_token }));
}

export async function storageAuthTokenGet() {
  const response = await localStorage.getItem(AUTH_STORAGE);

  const { token, refresh_token }: StorageAuthTokenProps = response ? JSON.parse(response) : {}

  return { token, refresh_token };
}

export async function storageAuthTokenRemove() {
  await localStorage.removeItem(AUTH_STORAGE);
}