import { useState } from "react";

import { object, string } from 'yup';
import { useForm } from "react-hook-form";

import { LoginHeader } from '../../components/LoginHeader';
import { useYupValidationResolver } from '../../utils/YupResolver';

//import { login } from '../../services/auth';
import { useAuth } from '../../hooks/useAuth';
import { AppError } from '../../utils/AppError';

import { FaEye, FaEyeSlash } from "react-icons/fa";
import FooterImage from '../../assets/footer_full.svg';

import { 
  Container, 
  Form,
  Label,
  Input,
  Button,
  LinkSignUp,
  Footer,
  PassContainer,
  Icon
} from './styles';
import { toast } from "react-toastify";

const authSchema = object({
  email: string()
    .email("Insira um e-mail válido")
    .required("O e-mail é obrigatório"),
  password: string().required("A senha é obrigatória")
});

export function Login() {
  const resolver = useYupValidationResolver(authSchema);

  const { register, handleSubmit,formState: { errors }, reset } = useForm({ resolver });
  const [ loading, setLoading ] = useState(false);
  const [ showPass, setShowPass ] = useState(false);

  const { signIn } = useAuth();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await signIn(data.email, data.password);
    } catch (error) {
      const isAppError = error instanceof AppError;

      const title = isAppError ? error.message : 'Não foi possível entrar. Tente novamente mais tarde.';
      toast.error(title);
    } finally {
      setLoading(false);
    };    
  };

  return (
  <Container>
    <LoginHeader />
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Label htmlFor="email">Email</Label>
      <Input 
        id="name"
        type="email" 
        aria-invalid={errors.name ? "true" : "false"} 
        {...register("email")} 
      />
      <p>{errors.email?.message?.toString()}</p>
      <Label>Senha</Label>
      <PassContainer>
        <Input
          name="password"
          type={showPass ? "text" : "password"}
          autoComplete="password"
          {...register("password")}
          style={{width: '100%'}}
        />
        <Icon onClick={() => setShowPass(!showPass)}>
          {showPass ? <FaEyeSlash size={24}/> : <FaEye size={24}/>}
        </Icon>
      </PassContainer>
      <p>{errors.password?.message?.toString()}</p>
      <Button type="submit">{loading ? "Processando..." : "Entrar"}</Button>
    </Form>
    <LinkSignUp href="https://ver.ai/medicos" target="_blank">Nova conta gratuita para Oftalmologistas</LinkSignUp>
    <Footer><img src={FooterImage} alt="footer"/></Footer>
  </Container>);
};