import { AlteradoPorAIDTO, CirurgiaImplanteLioDTO, CirurgiaRefrativaDTO, DadosClinicosDTO, JornadaCadastradaDTO, PacienteDTO, ReceitaDTO } from "@dtos/ConsultaDTO";

export function getAlteradosPorAI(
  alterados: AlteradoPorAIDTO,
  paciente: PacienteDTO, 
  paciente_new: PacienteDTO, 
  receita: ReceitaDTO, 
  receita_new: ReceitaDTO, 
  jornadas: JornadaCadastradaDTO, 
  jornadas_new: JornadaCadastradaDTO
) {  
  if(alteraDadosCadastrais(paciente, paciente_new)) {
    alterados.paciente = true;
  }
  if(alteraAnamnese(paciente, paciente_new)) {
    alterados.anamnese = true;
  }
  if(alteraDadosClinicos(paciente.dadosClinicos, paciente_new.dadosClinicos)) {
    alterados.dadosClinicos = true;
  }
  if(alteraReceita(receita, receita_new)) {
    alterados.receita = true;
  }
  if(alteraCirurgiaImplanteLio(jornadas.cirurgiaImplanteLIO, jornadas_new.cirurgiaImplanteLIO)) {
    alterados.cirurgiaImplanteLio = true;
  }
  if(alteraCirurgiaRefrativa(jornadas.cirurgiaRefrativa, jornadas_new.cirurgiaRefrativa)) {
    alterados.cirurgiaRefrativa = true;
  }
  return alterados;
}

function alteraDadosCadastrais(obj1 : PacienteDTO, obj2 : PacienteDTO) {
  if(obj1.nome !== obj2.nome ||
    obj1.CPF !== obj2.CPF ||
    obj1.whatsapp !== obj2.whatsapp ||
    obj1.dataNascimento !== obj2.dataNascimento
  ) {
    return true;
  }
  return false;
}

function alteraAnamnese(obj1 : PacienteDTO, obj2 : PacienteDTO) {
  if(obj1.anamneseMedica != obj2.anamneseMedica) {
    return true;
  }
  return false;
}

function alteraReceita(obj1 : ReceitaDTO, obj2 : ReceitaDTO) {
  if(JSON.stringify(obj1) !== JSON.stringify(obj2) ) {
    return true;
  }
  return false;
}

function alteraDadosClinicos(obj1 : DadosClinicosDTO, obj2 : DadosClinicosDTO) {
  if(JSON.stringify(obj1) !== JSON.stringify(obj2) ) {
    return true;
  }
  return false;
}

function alteraCirurgiaImplanteLio(obj1 : CirurgiaImplanteLioDTO, obj2 : CirurgiaImplanteLioDTO) {
  if(JSON.stringify(obj1) !== JSON.stringify(obj2) ) {
    return true;
  }
  return false;
}

function alteraCirurgiaRefrativa(obj1 : CirurgiaRefrativaDTO, obj2 : CirurgiaRefrativaDTO) {
  if(JSON.stringify(obj1) !== JSON.stringify(obj2) ) {
    return true;
  }
  return false;
}