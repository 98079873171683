import React, { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';

import { useConsulta } from '@hooks/useConsulta';
import { Loading } from '../../components/Loading';

import ImgDitar from '../../assets/new-header/falar_enabled.svg';
import ImgDitarDisable from '../../assets/new-header/falar_disabled.svg';
import ImgParar from '../../assets/new-header/botao_parar.svg';

import { Container, Image, DefaultButton, Button } from './styles'; 
import { isChrome, isIOS, isSafari } from "react-device-detect";

export function BotaoDitar({ disabled, loading, changeLoading }) {
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [audioChunks, setAudioChunks] = useState([]);

  const [ enviandoAudio, setEnviandoAudio ] = useState(false);

  const { 
    enviarAudio, 
  } = useConsulta();

  const startRecording = async () => {
    setRecordingStatus("recording");

    navigator.mediaDevices.getUserMedia({
      audio: true,
    })
    .then((stream) => start(stream))
    .catch((err) => toast.error(err))
  }

  // padrao para chrome on windows
  var format = "audio/ogg"; 
  var type = "audio/ogg; codecs=opus";
  var options = {};
  // chrome no ios
  if(MediaRecorder.isTypeSupported('audio/mp4') && isChrome && isIOS) {
    options = { mimeType: "audio/mp4" };
    format = "audio/mp4"; 
    type = "audio/mp4; codecs=opus"; // Blob
  } else if(MediaRecorder.isTypeSupported('video/mp4') && isSafari) {
    options = { mimeType: "video/mp4" };
    format = "video/mp4";
    type = "video/mp4"; // Blob
  }

  function start(stream) {    
    const media = new MediaRecorder(stream, options);
    mediaRecorder.current = media;
    mediaRecorder.current.start(1000);
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
       if (typeof event.data === "undefined") return;
       if (event.data.size === 0) return;
       localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type });

      var reader = new window.FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = async function () {
         var base64data = reader.result;
         setEnviandoAudio(true);
         await enviarAudio(base64data, format);
         setEnviandoAudio(false);
         changeLoading(false);
      }

       setAudioChunks([]);
    };
  };

  if(disabled) {
    return (<Image src={ImgDitarDisable} alt="ditar" disabled={true} />)
  }
  
  if(recordingStatus === 'recording') {
    return (
    <Button onClick={stopRecording}>
      <Image src={ImgParar} />
    </Button>
    );
  }

  if(enviandoAudio) {
    return (
    <DefaultButton>
      <Loading />
    </DefaultButton>
    );
  }

  return (
      <Container onClick={startRecording}>
        <Image src={ImgDitar} alt="ditar" />
      </Container>
  );
}