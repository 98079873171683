import styled from 'styled-components'

export const Tabela = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;

export const Label = styled.div`
  color: #BFB3AF;
  height: 18px;
  text-align: right;
  white-space: nowrap;
`;

export const LabelOlho = styled.h3`
  color: #BFB3AF;
  height: 18px;
  text-align: center;
`;

export const Select = styled.select`
  width: 109px;
  height: 18px;
  border: none;
  text-align: center;
`;