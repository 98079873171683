import { useEffect } from 'react';
import { merge } from '@utils/conversaoV2';
import {
  Coluna,
  MiniLabel,
  Valor
} from './styles';

const options = [
  "",
  "20/10",
  "20/15",
  "20/20",
  "20/25",
  "20/30",
  "20/35",
  "20/40",
  "20/45",
  "20/50",
  "20/60",
  "20/70",
  "20/80",
  "20/90",
  "20/100",
  "20/200",
  "20/300",
  "20/400",
  "CD",
  "MM",
  "PL",
  "SPL",
];

export function AcuidadeVisualLongeLinha({ data, handleChange }) {
  function handleChangeSemCorrecao(e) {
    const new_data = {...data, "semCorrecao": e.target.value};
    handleChange(new_data);
  }

  function handleChangeCorrecaoAnterior(e) {
    const new_data = {...data, "correcaoAnterior": e.target.value};
    handleChange(new_data);
  }

  function handleChangeNovaCorrecao(e) {
    const new_data = {...data, "novaCorrecao": e.target.value};
    handleChange(new_data);
  }

  return (
    <>
      <Coluna key="sem_correcao">
        <MiniLabel className="ui_mini_labels">Sem correção</MiniLabel>
        <Valor className="ui_labels" 
          value={data.semCorrecao}
          onChange={handleChangeSemCorrecao}
        >
          {options.map((e) => <option key={e} value={e}>{e}</option>)}
        </Valor>
      </Coluna>
      <Coluna key="correcao_anterior">
        <MiniLabel className="ui_mini_labels">Correção anterior</MiniLabel>
        <Valor className="ui_labels"
          value={data.correcaoAnterior}
          onChange={handleChangeCorrecaoAnterior}
        >
          {options.map((e) => <option key={e} value={e}>{e}</option>)}
        </Valor>
      </Coluna>
      <Coluna key="nova_correcao">
        <MiniLabel className="ui_mini_labels">Nova correção</MiniLabel>
        <Valor className="ui_labels"
          value={data.novaCorrecao}
          onChange={handleChangeNovaCorrecao}    
        >
          {options.map((e) => <option key={e} value={e}>{e}</option>)}
        </Valor>
      </Coluna>
    </>
  );
}