import { error } from 'console';
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`;

export const Valor = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

export const Input = styled.input`
  padding: 3px;
  border: none;
  flex: 1;
  min-width: 330px;
  border: ${props => props.error === "true" ? "2px inset #C71173" : "none"};
  width: ${props => props.error === "true" ? "100%" : "auto"};
  border-radius: 5px;

  &:focus {
    border: 2px inset #EBE9ED;
    border:default;
    border-radius: 5px;
    border-color: #e0e0e0;
  }  
`;

export const Texto = styled.span.attrs(props => ({
  error: props.error || "false"
}))`
  border: none; // ${props => props.error === "true" ? "2px inset #C71173" : "none"};
  width: auto; // ${props => props.error === "true" ? "100%" : "auto"};
  border-radius: 5px;
  min-height: 28px;
  display: flex;
  //justify-content: center;
  align-items: center;
`;

export const Imagem = styled.img`
  margin-left: 5px;
`;
