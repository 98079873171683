import { useState, useRef } from "react";

import BtnAlterar from '../../assets/btn_alterar.svg';

import {
  Container,
  Input,
  Imagem,
  Valor,
  Texto
} from './styles';

export function EditableNome(props) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef();

  function handleFocus () {
    setIsMouseOver(false);
  }

  function handleMouseOver () {
    setIsMouseOver(true);
  };

  function handleMouseOut () {
    setIsMouseOver(false);
  }

  function handleClick() {
    setIsEditing(true);
    setIsMouseOver(false);
  }

  function handleInputBlur() {
    setIsEditing(false);
    if(props.hasOwnProperty("onBlur")) {
      props.onBlur();
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }  

  var content;

  if(!isEditing && props.error != "true") {
    content = (
    <Valor >
      <Texto className="ui_labels">{props.value}</Texto>
      {isMouseOver && <Imagem src={BtnAlterar} alt="editar"></Imagem> }
    </Valor>
    );
  } else {
    content = <Input
    {...props }
    onBlur={handleInputBlur}
    onFocus={handleFocus}
    value={props.value ? props.value : ''}
    ref={inputRef}
    autoFocus
    onKeyDown={handleKeyDown}
    />;
  }

  return (
    <Container onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleClick}>
      {content}
    </Container>
  );
}