import React, { useCallback, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import BtnAlterar from '../../assets/btn_alterar.svg';

import { ConsultaBloco } from '../ConsultaBloco';
import { useConsulta } from '@hooks/useConsulta';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { 
  Content,
  Valor,
  Imagem
} from './styles';

export function Preanamnese({ }) {
  const { paciente, alteraAnamnese, alteradoPorAi } = useConsulta();
  
  const [title, setTitle] = useState('Pré-anamnese');
  const [texto, setTexto] = useState('');
  const [minitext, setMinitext] = useState('');

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // se tem anamnese medica, esse texto que conta
    if(paciente && paciente.anamneseMedica) {
      setTitle("Anamnese");
      setTexto(paciente.anamneseMedica);
      setMinitext('');
    } else { // se nao, verifica se tem pre anamnese
      if(paciente && paciente.preAnamnese && paciente.preAnamnese.respostasConsolidadas) {
        var pre_texto = (paciente.preAnamnese && paciente.preAnamnese.respostasConsolidadas ? paciente.preAnamnese.respostasConsolidadas.replace(/(\r\n|\r|\n)/g, "<br />") : ""); 
        var minitext = (paciente.preAnamnese.respondidaEm ? "respondida em "+format(new Date(paciente.preAnamnese.respondidaEm), "dd/MM/yyyy H'h'mm") : ""); //respondida em 30/06/2024 às 10h02
        setTexto(pre_texto);
        setMinitext(minitext);
      }
    }
  }, [paciente]);

  function handleMouseOver () {
    setIsMouseOver(true);
  };

  function handleMouseOut () {
    setIsMouseOver(false);
  }

  function handleClick() {
    setIsMouseOver(false);
    setIsEditing(true);
    setTitle("Anamnese");
  }

  function handleFocus() {
    setIsEditing(true);    
    setTitle("Anamnese");
  }

  function handleChangeTexto(text) {
    setTexto(text);
  }  

  function handleBlurTexto() {
    alteraAnamnese(texto);
    setIsEditing(false)
  }  

  function handleBlurContent(e) {
    if (
      e.relatedTarget !== e.currentTarget &&
      !e.currentTarget.contains(e.relatedTarget)
    ) {
      alteraAnamnese(texto);
      setIsEditing(false)
    }
  }
  
  var modules = {
    toolbar: [['bold', 'italic']]
  };
    
  return (
    <ConsultaBloco text={title} minitext={minitext} ai={alteradoPorAi.anamnese}>
    {!isEditing ?
      <Content id="preanamnese" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} tabIndex="0" onFocus={handleFocus} >
        {isMouseOver && <Imagem src={BtnAlterar} alt="editar" onClick={handleClick}/>}
        <Valor dangerouslySetInnerHTML={{__html: texto}}></Valor>
      </Content>
    :
      <div tabIndex={0} onBlur={handleBlurContent}>
        <ReactQuill value={texto} onChange={handleChangeTexto} modules={modules} onBlur={handleBlurTexto} ref={input => input && input.focus()}/>
      </div>
    }
    </ConsultaBloco>
  )
}