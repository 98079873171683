import styled from 'styled-components'

export const Container = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const Linha = styled.div`
  gap: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
`;

export const Coluna = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h3`
  gap: 10px;
  color: #BFB3AF;
`;

export const MiniLabel = styled.label`
  color: #BFB3AF;
`;

export const Valor = styled.select`
  border: none;
  height: 18px;
`;
