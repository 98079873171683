import React, { useState } from "react";
import { toast } from 'react-toastify';
import { useConsulta } from '@hooks/useConsulta';
import { Loading } from '../../components/Loading';

import ImgColarDisable from '@assets/new-header/colar_disabled.svg';
import ImgColar from '@assets/new-header/colar_enabled.svg';

import { Container, Image, DefaultButton } from './styles';

export function BotaoColar({ disabled, loading, changeLoading }) {
  const { enviarDoClipboard } = useConsulta();
  const inExtension = window.chrome && chrome.runtime && chrome.runtime.id;

  async function getClipboard() {
    if(inExtension) {
      var t = document.createElement("input");
      document.body.appendChild(t);
      t.focus();
      document.execCommand("paste");
      var clipboardText = t.value; //this is your clipboard data
      document.body.removeChild(t);
      return clipboardText;
    } else {
      const text = await navigator.clipboard.readText();
      return text;
    }
  }

  async function handleColar() {
    changeLoading(true);
    try {
      await enviarDoClipboard(await getClipboard());
    } catch(error) {
      toast.error(error.message);
    } finally {
      changeLoading(false);
    }
  }

  if(disabled) {
    return (<Image src={ImgColarDisable} alt="Colar" disabled={true}/>);
  }

  if(loading) {
    return (<DefaultButton><Loading /></DefaultButton>);
  }

  //<Image src={ImgColar} alt="Colar" />
  return (<Container onClick={handleColar} className="ui_labels_bold">
    <Image src={ImgColar} alt="Colar" />
  </Container>);
}