import { useState } from "react";
import { toast } from 'react-toastify';
import BtnRemoverUm from '../../assets/btn_remover_um.svg';
import IconRemover from '../../assets/icon_remover.svg';
import IconAdd from '../../assets/icon_add.svg';

import { 
  Container,
  Botoes,

  Linha,
  Botao,
  Lista,
  ListaItem,
  Input
} from './styles';

type ListaTextoProps = {
  adicionar_texto: string;
  items: ItensProps[];
  handleChange: (items: ItensProps[]) => void;
}

type ItensProps = {
  codigo?: string;
  descricao: string;
}

export function ListaTexto({ adicionar_texto, items, handleChange }: ListaTextoProps) {
  const [isAdding, setIsAdding] = useState(false);
  const [texto, setTexto] = useState('');

  function handleIncluir(e) {
    e.preventDefault();
    setIsAdding(!isAdding);
  }

  function handleRemover(descricao) {
    const new_items = items.filter(e => e.descricao !== descricao);
    handleChange(new_items);
  }

  function handleAdiciona() {
    if(!texto)
      return;
    // verifica se ja existe
    const exists = items.find(e => e.descricao === texto);
    if(exists) {
      toast.error('Item já adicionado');
      return;
    }
    const news = [...items, {
      descricao: texto,
    }];
    handleChange(news);
    setTexto('');    
    setIsAdding(false);
  }

  function handleRemoverTodas() {
    handleChange([]);
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleAdiciona();
    }
  }

  return (
    <Container>
      <Botoes>
        <Botao onClick={handleIncluir} className="ui_labels">
          <img src={IconAdd} alt="incluir"/>{adicionar_texto}
        </Botao>
        {items && items.length > 0 &&
        <Botao onClick={handleRemoverTodas} className="ui_labels" style={{ display: "flex", justifyContent: "center" }}>
          <img src={IconRemover} alt="remover"/>Remover todos
        </Botao>}
      </Botoes>
    
      {isAdding &&
        <Linha>
          <Input placeholder="Digite aqui" className="ui_labels" onKeyDown={handleKeyDown} value={texto} 
            onChange={(e) => setTexto(e.target.value)}
            autoFocus 
          />
          <input type="image" src={IconAdd} alt="incluir" onClick={handleAdiciona} />
        </Linha>
      }
      
      { items && items.length > 0 &&
        <Lista>
          {items.map((item) => (
            <ListaItem key={item.descricao}>
                <p >{item.descricao}</p>
                <img src={BtnRemoverUm} onClick={() => handleRemover(item.descricao)} alt="remover"/>
              </ListaItem>
            ))
          }
        </Lista>
      }
    </Container>
  );
}