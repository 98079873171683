export function isDev() {
  return (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");
}

export function ditarResponseExample2() {
  return { 
    "data": {
      "metadata": {
        "id": 3514,
        "timestamp": "2024-08-14T10:01:25",
        "transcritoPor": "whisper-1",
        "interpretadoPor": "gpt-4o"
    },
    "payload": {
        "paciente": {
            "nome": "Paula Teste",
            "whatsapp": "11988887777",
            "CPF": "22236344405",
            "dataNascimento": "1980-08-01"
        }
    }
  }
}    
}

export function ditarResponseExample() {
  return { 
    "data": {
      "metadata" : {
        "id" : 55,
        "timestamp" : "2024-06-25T22:47:46",
        "transcritoPor" : "whisper-1",
        "interpretadoPor" : "gpt-4o"
      },
      "payload" : {
        "paciente": {
          "id" : "pid4571a2846c0d726e9844c4377de7e4a9",
          "nome" : "Ana Elisa Santos Torres",
          "whatsapp" : "5511987654321",
          "cpf" : "83510940059",
          "dataNascimento" : "2002-12-20",
          "preAnamnese" : {
            "respondidaEm" : "2024-03-20T11:43:23-03:00",
            "respostasConsolidadas" : "Motivo: avaliação para cirurgia.\nUsa óculos.\nUsa óculos de 5 a 10 anos..\nDetesta usar óculos.\nDificuldade para ver de perto.\nCoceiras: Ocasionalmente.\nRessecamento: Nunca.\nArdência ou queimação: Ocasionalmente.\nCorpo estranho: Ocasionalmente.\nUso diário de computador: Menos de 1 hora.\nUso diário de celular: 1-3 horas.\nLentes de contato: Não.\nOrigem: Reputação da instituição.\nCompartilha dados: Sim"
          }
        },
        "receita" : {
          "refracao" : {
            "OD" : {
              "esf" : "-1,50",
              "cil" : "-1,50",
              "eixo" : "20",
              "adicao" : "+0,50"
            },
            "OE" : {
              "esf" : "-1,50",
              "cil" : "-3,50",
              "eixo" : "123",
              "adicao" : "+2,50"
            }
          },
          "tipo": "Progressiva",
          "uso" : {
            "longe" : true,
            "perto" : false
          },
          "prisma" : {
            "ativarPrisma" : true,
            "OD" : {
              "prisma" : "2",
              "base" : "nasal"
            },
            "OE" : {
              "prisma" : "1",
              "base" : "temporal"
            }
          },
          "orientacoes" : {
            "ativarOrientacoes" : true,
            "orientacoesPaciente" : "Utilize para dirigir"
          },
          "tecnologias" : [
            {
              "id" : "tec879823489899",
              "marca" : "Essilor",
              "nome" : "Crizal Easy",
              "descricao" : "Antirreflexo mais fácil de limpar"
            }
          ]
        },
        "jornadas" : {
          "cirurgiaImplanteLIO" : {
            "dataIndicacao" : "2023-12-20T15:17:22-03:00",
            "status" : "em_andamento",
            "OD" : {
              "indicacao" : "Catarata",
              "tiposDeLentes" : [
                {
                  "id" : "tec5h46er5t4y",
                  "nome" : "Trifocal/Quadrifocal",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tecytrety45",
                  "nome" : "Monofocal/EDOF",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tech1b56ert41yh6",
                  "nome" : "Monofocal Plus",
                  "indicada" : false
                },
                {
                  "id" : "tec6532455154",
                  "nome" : "Bifocal",
                  "indicada" : false
                },
                {
                  "id" : "tec46554654264554",
                  "nome" : "Monofocal Asferica",
                  "indicada" : false
                },
                {
                  "id" : "tec3245980934628",
                  "nome" : "Monofocal Esferica",
                  "indicada" : false
                },
                {
                  "id" : "tec2655444534546",
                  "nome" : "Lente Facica",
                  "indicada" : false
                }
              ],
              "alvoMonofocal" : "Perto",
              "torica" : false,
              "tecnologias" : [
                {
                  "id" : "tec8798234423",
                  "tipo" : "Trifocal/Quadrifocal",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular A",
                  "descricao" : "Adequada para...."
                },
                {
                  "id" : "tec927652384983",
                  "tipo" : "Monofocal EDOF",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular B",
                  "descricao" : "Adequada para...."
                }
              ]
            },
            "OE" : {
              "indicacao" : "Catarata",
              "tiposDeLentes" : [
                {
                  "id" : "tec5h46er5t4y",
                  "nome" : "Trifocal/Quadrifocal",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tecytrety45",
                  "nome" : "Monofocal/EDOF",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tech1b56ert41yh6",
                  "nome" : "Monofocal Plus",
                  "indicada" : false
                },
                {
                  "id" : "tec6532455154",
                  "nome" : "Bifocal",
                  "indicada" : false
                },
                {
                  "id" : "tec46554654264554",
                  "nome" : "Monofocal Asferica",
                  "indicada" : false
                },
                {
                  "id" : "tec3245980934628",
                  "nome" : "Monofocal Esferica",
                  "indicada" : false
                },
                {
                  "id" : "tec2655444534546",
                  "nome" : "Lente Facica",
                  "indicada" : false
                }
              ],
              "alvoMonofocal" : "Perto",
              "torica" : false,
              "tecnologias" : [
                {
                  "id" : "tec8798234423",
                  "tipo" : "Trifocal/Quadrifocal",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular A",
                  "descricao" : "Adequada para...."
                },
                {
                  "id" : "tec927652384983",
                  "tipo" : "Monofocal EDOF",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular B",
                  "descricao" : "Adequada para...."
                }
              ]
            },
            "recursosIntraoperatorios" : {
              "femtoFlacs" : false,
              "guiadoPorImagem" : false,
              "aberrometria" : false
            }
          },
          "cirurgiaRefrativa" : {
            "status" : "draft",
            "OD" : {
              "indicacao" : "Longe",
              "tecnicas" : [
                {
                  "id" : "tec24234542543456",
                  "nome" : "Femto LASIK",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tec24234542543455",
                  "nome" : "LASIK com microceratomo",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tec24234542543454",
                  "nome" : "TransPRK",
                  "indicada" : false
                },
                {
                  "id" : "tec24234542543453",
                  "nome" : "PRK",
                  "indicada" : false
                },
                {
                  "id" : "tec24234542543452",
                  "nome" : "SMILE",
                  "indicada" : false
                },
                {
                  "id" : "tec24234542543451",
                  "nome" : "PTK",
                  "indicada" : false
                }
              ],
              "tratamentoGuiado" : {
                "topografia" : true,
                "frenteDeOnda" : false
              }
            },
            "OE" : {
              "indicacao" : "Longe",
              "tecnicas" : [
                {
                  "id" : "tec242345432452347",
                  "nome" : "Femto LASIK",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tec242345432452346",
                  "nome" : "LASIK com microceratomo",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tec242345432452345",
                  "nome" : "TransPRK",
                  "indicada" : false
                },
                {
                  "id" : "tec242345432452344",
                  "nome" : "PRK",
                  "indicada" : false
                },
                {
                  "id" : "tec242345432452343",
                  "nome" : "SMILE",
                  "indicada" : false
                },
                {
                  "id" : "tec242345432452342",
                  "nome" : "PTK",
                  "indicada" : false
                }
              ],
              "tratamentoGuiado" : {
                "topografia" : true,
                "frenteDeOnda" : false
              }
            }
          }
        }    
      }
    }
  };
}

export function consultasResponseExample() {
  return {
    "Status": "Sucesso",
    "Médico": "pro73a40ae45f91c5de0f98d58c3eab488e",
    "Clínica selecionada": "11493",
    "Próximas consultas": [
        {
            "id": "jorfb224560ea903219f05fbf1127b91361",
            "Horário": "2024-05-27T14:00:00-03:00",
            "Etapa": "3_scheduled",
            "Paciente": "Marcos Exemplo da Silva"
        }
    ]
  };
}

export function iniciarV2ResponseExample() {
  return {
    "metadata" : {
      "id" : "jor8dbe09b98cb2fb231b86bf7430132ff6"
    },
    "payload" : {
      "situacao" : {
        "etapa" : "em_consulta",
        "agendadaPara" : "2023-12-20T15:00:00-03:00",
        "transicoes" : [
          {
            "id" : "encerrar_consulta",
            "nome" : "Encerrar consulta",
            "proximaEtapa" : "pos_consulta"
          },
          {
            "id" : "retornar_sala_de_espera",
            "nome" : "Retornar à sala de espera",
            "proximaEtapa" : "sala_de_espera"
          }
        ]
      },
      "paciente" : {
        "id" : "pid4571a2846c0d726e9844c4377de7e4a9",
        "nome" : "Ana Elisa Santos Torres",
        "whatsapp" : "5511987654321",
        "cpf" : "83510940059",
        "dataNascimento" : "2002-12-20",
        "preAnamnese" : {
          "respondidaEm" : "2024-03-20T11:43:23-03:00",
          "respostasConsolidadas" : "Motivo: avaliação para cirurgia.\nUsa óculos.\nUsa óculos de 5 a 10 anos..\nDetesta usar óculos.\nDificuldade para ver de perto.\nCoceiras: Ocasionalmente.\nRessecamento: Nunca.\nArdência ou queimação: Ocasionalmente.\nCorpo estranho: Ocasionalmente.\nUso diário de computador: Menos de 1 hora.\nUso diário de celular: 1-3 horas.\nLentes de contato: Não.\nOrigem: Reputação da instituição.\nCompartilha dados: Sim"
        },
        "classificadores" : [
          "Hipertenso",
          "60+"
        ],
        "dadosClinicos" : {
          "tratamentosRealizados" : {
            "ativarTratamentosRealizados" : false,
            "tratamentosRealizadosArray" : [
              {
                "codigo" : "xxx",
                "descricao" : "Implante intraocular"
              }
            ]
          },
          "medicamentosEmUso" : {
            "ativarMedicamentosEmUso" : false,
            "medicamentosEmUsoArray" : [
              {
                "codigo" : "xxx",
                "descricao" : "Metformina 500mg"
              }
            ]
          },
          "acuidadeVisualDeLonge" : {
            "ativarAcuidadeVisualDeLonge" : true,
            "OD" : {
              "semCorrecao" : "20/40",
              "correcaoAnterior" : "20/30",
              "novaCorrecao" : "20/20"
            },
            "OE" : {
              "semCorrecao" : "20/40",
              "correcaoAnterior" : "20/30",
              "novaCorrecao" : "20/20"
            },
            "AO" : {
              "semCorrecao" : "20/40",
              "correcaoAnterior" : "20/30",
              "novaCorrecao" : "20/20"
            }
          },
          "acuidadeVisualDePerto" : {
            "ativarAcuidadeVisualDePerto" : true,
            "OD" : {
              "semCorrecao" : "J3",
              "correcaoAnterior" : "J2",
              "novaCorrecao" : "J1"
            },
            "OE" : {
              "semCorrecao" : "J3",
              "correcaoAnterior" : "J2",
              "novaCorrecao" : "J1"
            },
            "AO" : {
              "semCorrecao" : "J3",
              "correcaoAnterior" : "J2",
              "novaCorrecao" : "J1"
            }
          },
          "pressaoIntraocular" : {
            "ativarPressaoIntraocular" : true,
            "OD" : {
              "mmHg" : "14"
            },
            "OE" : {
              "mmHg" : "15"
            }
          },
          "segmentoAnterior" : {
            "ativarSegmentoAnterior" : true,
            "OD" : {
              "palpebraECilios" : "normais",
              "conjuntivaPalpebral" : "sem hiperemia",
              "conjuntivaBulbar" : "sem hiperemia",
              "esclera" : "branca",
              "cornea" : "transparente",
              "camaraAnterior" : "profunda",
              "angulo" : "aberto",
              "iris" : "normotropica",
              "cristalino" : "transparente",
              "vitreoAnterior" : "sem células"
            },
            "OE" : {
              "palpebraECilios" : "normais",
              "conjuntivaPalpebral" : "hiperemia leve",
              "conjuntivaBulbar" : "hiperemia leve",
              "esclera" : "ligeiram. amarelada",
              "cornea" : "opacidade central",
              "camaraAnterior" : "rasa",
              "angulo" : "fechado",
              "iris" : "pigmentada",
              "cristalino" : "catarata",
              "vitreoAnterior" : "células presentes"
            }
          },
          "fundoDeOlho" : {
            "ativarFundoDeOlho" : true,
            "OD" : {
              "escDiscoV" : "0,2",
              "escDiscoH" : "0,2",
              "coloracao" : "róseo",
              "margens" : "delimitadas",
              "rimaNeural" : "ISNT preservado",
              "macula" : "seca",
              "poloPosterior" : "0,2",
              "vasos" : "sem alterações",
              "periferia" : "sem alterações",
              "vitreo" : "transparente"
            },
            "OE" : {
              "escDiscoV" : "0,3",
              "escDiscoH" : "0,2",
              "coloracao" : "pálido",
              "margens" : "borradas",
              "rimaNeural" : "Hoyt superior",
              "macula" : "edema",
              "poloPosterior" : "0,2",
              "vasos" : "tortuosos",
              "periferia" : "lesões de rotura",
              "vitreo" : "com flocos"
            }
          },
          "diagnosticos" : {
            "ativarDiagnosticos" : false,
            "diagnosticosArray" : [
              {
                "codigo" : "H28",
                "descricao" : "Catarata"
              },
              {
                "codigo" : "H04.1",
                "descricao" : "Olhos secos"
              },
              {
                "codigo" : "H43.9",
                "descricao" : "Moscas volantes"
              }
            ]
          }
        }
      },
      "receita" : {
        "metadata" : {
          "status" : "emitida",
          "id" : "LENc53227c8830cef48ad0ebfea6cef68fe",
          "dataEmissao" : "2023-12-20T15:00:00-03:00",
          "enviadaVia" : "WhatsApp Web",
          "enviadaEm" : "2023-12-20T15:00:14-03:00",
          "urlPublico" : "https://app.ver.ai/receita/lenc53227c8830cef48ad0ebfea6cef68fe/"
        },
        "refracao" : {
          "OD" : {
            "esf" : "-1,25",
            "cil" : "-0,75",
            "eixo" : "80º",
            "adicao" : "+0,50"
          },
          "OE" : {
            "esf" : "-1,25",
            "cil" : "-0,75",
            "eixo" : "80º",
            "adicao" : "+0,50"
          }
        },
        "tipo" : "Monofocal",
        "uso" : {
          "longe" : true,
          "perto" : false
        },
        "prisma" : {
          "ativarPrisma" : true,
          "OD" : {
            "prisma" : "2",
            "base" : "nasal"
          },
          "OE" : {
            "prisma" : "1",
            "base" : "temporal"
          }
        },
        "orientacoes" : {
          "ativarOrientacoes" : true,
          "orientacoesPaciente" : "Utilize para dirigir"
        },
        "tecnologias" : [
          {
            "id" : "tec879823489899",
            "marca" : "Essilor",
            "nome" : "Crizal Easy",
            "descricao" : "Antirreflexo mais fácil de limpar"
          }
        ]
      },
      "jornadasDisponiveis" : [
        {
          "id" : "cirurgiaImplanteLIO",
          "nome" : "Cirurgia de implante de LIO"
        },
        {
          "id" : "cirurgiaRefrativa",
          "nome" : "Cirurgia refrativa"
        }
      ],
      "jornadas" : {
        "cirurgiaImplanteLIO" : {
          "dataIndicacao" : "2023-12-20T15:17:22-03:00",
          "status" : "em_andamento",
          "OD" : {
            "indicacao" : "Catarata",
            "tiposDeLentes" : [
              {
                "id" : "tec5h46er5t4y",
                "nome" : "Trifocal/Quadrifocal",
                "indicada" : true,
                "posicao" : 1
              },
              {
                "id" : "tecytrety45",
                "nome" : "Monofocal/EDOF",
                "indicada" : true,
                "posicao" : 2
              },
              {
                "id" : "tech1b56ert41yh6",
                "nome" : "Monofocal Plus",
                "indicada" : false
              },
              {
                "id" : "tec6532455154",
                "nome" : "Bifocal",
                "indicada" : false
              },
              {
                "id" : "tec46554654264554",
                "nome" : "Monofocal Asferica",
                "indicada" : false
              },
              {
                "id" : "tec3245980934628",
                "nome" : "Monofocal Esferica",
                "indicada" : false
              },
              {
                "id" : "tec2655444534546",
                "nome" : "Lente Facica",
                "indicada" : false
              }
            ],
            "alvoMonofocal" : "Perto",
            "torica" : false,
            "tecnologias" : [
              {
                "id" : "tec8798234423",
                "tipo" : "Trifocal/Quadrifocal",
                "marca" : "JnJ",
                "nome" : "Lente Intraocular A",
                "descricao" : "Adequada para...."
              },
              {
                "id" : "tec927652384983",
                "tipo" : "Monofocal EDOF",
                "marca" : "JnJ",
                "nome" : "Lente Intraocular B",
                "descricao" : "Adequada para...."
              }
            ]
          },
          "OE" : {
            "indicacao" : "Catarata",
            "tiposDeLentes" : [
              {
                "id" : "tec5h46er5t4y",
                "nome" : "Trifocal/Quadrifocal",
                "indicada" : true,
                "posicao" : 1
              },
              {
                "id" : "tecytrety45",
                "nome" : "Monofocal/EDOF",
                "indicada" : true,
                "posicao" : 2
              },
              {
                "id" : "tech1b56ert41yh6",
                "nome" : "Monofocal Plus",
                "indicada" : false
              },
              {
                "id" : "tec6532455154",
                "nome" : "Bifocal",
                "indicada" : false
              },
              {
                "id" : "tec46554654264554",
                "nome" : "Monofocal Asferica",
                "indicada" : false
              },
              {
                "id" : "tec3245980934628",
                "nome" : "Monofocal Esferica",
                "indicada" : false
              },
              {
                "id" : "tec2655444534546",
                "nome" : "Lente Facica",
                "indicada" : false
              }
            ],
            "alvoMonofocal" : "Perto",
            "torica" : false,
            "tecnologias" : [
              {
                "id" : "tec8798234423",
                "tipo" : "Trifocal/Quadrifocal",
                "marca" : "JnJ",
                "nome" : "Lente Intraocular A",
                "descricao" : "Adequada para...."
              },
              {
                "id" : "tec927652384983",
                "tipo" : "Monofocal EDOF",
                "marca" : "JnJ",
                "nome" : "Lente Intraocular B",
                "descricao" : "Adequada para...."
              }
            ]
          },
          "recursosIntraoperatorios" : {
            "femtoFlacs" : false,
            "guiadoPorImagem" : false,
            "aberrometria" : false
          }
        },
        "cirurgiaRefrativa" : {
          "status" : "draft",
          "OD" : {
            "indicacao" : "Longe",
            "tecnicas" : [
              {
                "id" : "tec24234542543456",
                "nome" : "Femto LASIK",
                "indicada" : true,
                "posicao" : 1
              },
              {
                "id" : "tec24234542543455",
                "nome" : "LASIK com microceratomo",
                "indicada" : true,
                "posicao" : 2
              },
              {
                "id" : "tec24234542543454",
                "nome" : "TransPRK",
                "indicada" : false
              },
              {
                "id" : "tec24234542543453",
                "nome" : "PRK",
                "indicada" : false
              },
              {
                "id" : "tec24234542543452",
                "nome" : "SMILE",
                "indicada" : false
              },
              {
                "id" : "tec24234542543451",
                "nome" : "PTK",
                "indicada" : false
              }
            ],
            "tratamentoGuiado" : {
              "topografia" : true,
              "frenteDeOnda" : false
            }
          },
          "OE" : {
            "indicacao" : "Longe",
            "tecnicas" : [
              {
                "id" : "tec242345432452347",
                "nome" : "Femto LASIK",
                "indicada" : true,
                "posicao" : 1
              },
              {
                "id" : "tec242345432452346",
                "nome" : "LASIK com microceratomo",
                "indicada" : true,
                "posicao" : 2
              },
              {
                "id" : "tec242345432452345",
                "nome" : "TransPRK",
                "indicada" : false
              },
              {
                "id" : "tec242345432452344",
                "nome" : "PRK",
                "indicada" : false
              },
              {
                "id" : "tec242345432452343",
                "nome" : "SMILE",
                "indicada" : false
              },
              {
                "id" : "tec242345432452342",
                "nome" : "PTK",
                "indicada" : false
              }
            ],
            "tratamentoGuiado" : {
              "topografia" : true,
              "frenteDeOnda" : false
            }
          }
        }
      },
      "tecnologiasDisponiveis" : {
        "oculos" : [
          {
            "id" : "tec879823489899",
            "fabricante" : "Essilor",
            "nome" : "Crizal Easy",
            "descricao" : "Antirreflexo mais fácil de limpar"
          },
          {
            "id" : "tec923482384983",
            "marca" : "Essilor",
            "nome" : "UV Filter",
            "descricao" : "Proteção contra UV e luz azul-violeta nociva"
          },
          {
            "id" : "tec1231299393757",
            "marca" : "Essilor",
            "nome" : "Visioffice",
            "descricao" : "Aferição de medidas computadorizada"
          }
        ],
        "LIO" : [
          {
            "id" : "tec8798234423",
            "tipo" : "Trifocal/Quadrifocal",
            "marca" : "JnJ",
            "nome" : "Lente Intraocular A",
            "descricao" : "Adequada para...."
          },
          {
            "id" : "tec927652384983",
            "tipo" : "Monofocal EDOF",
            "marca" : "JnJ",
            "nome" : "Lente Intraocular B",
            "descricao" : "Adequada para...."
          },
          {
            "id" : "tec14569393757",
            "tipo" : "Monofocal Plus",
            "marca" : "JnJ",
            "nome" : "Lente Intraocular C",
            "descricao" : "Adequada para...."
          },
          {
            "id" : "tec14569393757",
            "tipo" : "Bifocal",
            "marca" : "JnJ",
            "nome" : "Lente Intraocular D",
            "descricao" : "Adequada para...."
          },
          {
            "id" : "tec14569393757",
            "tipo" : "Monofocal Asférica",
            "marca" : "JnJ",
            "nome" : "Lente Intraocular E",
            "descricao" : "Adequada para...."
          },
          {
            "id" : "tec14569393757",
            "tipo" : "Monofocal Esférica",
            "marca" : "JnJ",
            "nome" : "Lente Intraocular F",
            "descricao" : "Adequada para...."
          }
        ],
        "tiposDeLIO": [
          {
            "id" : "tec5h46er5t4y",
            "nome" : "Trifocal/Quadrifocal",
            "indicada" : true,
            "posicao" : 1
          },
          {
            "id" : "tecytrety45",
            "nome" : "Monofocal/EDOF",
            "indicada" : true,
            "posicao" : 2
          },
        ]
      }
    }
  };
}

export function processarV2ResponseExample() {
  return {
    "data": {
      "metadata": {
          "id": 354,
          "timestamp": "2024-07-12T01:24:27",
          "transcritoPor": "whisper-1",
          "interpretadoPor": "gpt-4o"
      },
      "payload": {
          "paciente": {
              "nome": "Simone Pereira de Albuquerque",
              "CPF": "01375799690",
              "anamneseMedica": "Textyo teste",
              "dadosClinicos": {
                  "tratamentosRealizados": {
                      "ativarTratamentosRealizados": true,
                      "tratamentosRealizadosArray": [
                          {
                              "descricao": "Cirurgia refrativa"
                          }
                      ]
                  }
              }
          },
          "receita": {
            "refracao": {
              "OD": {
                "cil": "1,25",
                "adicao": "0,15",
              },
              "OE": {
                "cil": "1,25",
                "adicao": "0,15",
                "eixo": "0"
              }
            }
          },
          "jornadas": {
              "cirurgiaRefrativa": {
                  "OD": {
                      "indicacao": "Longe",
                      "tratamentoGuiado": {
                          "topografia": false,
                          "frenteDeOnda": true
                      }
                  },
                  "OE": {
                      "indicacao": "Perto",
                      "tratamentoGuiado": {
                          "topografia": true,
                          "frenteDeOnda": false
                      }
                  }
              },

              "cirurgiaImplanteLIO": {
                "OD": {
                    "alvoMonofocal": "Longe",
                    "indicacao": "Catarata",
                    "torica": true
                },
                "OE": {
                  "alvoMonofocal": "Longe",
                  "indicacao": "Catarata",
                  "torica": true
              }
            }

          }
      }
  }
  };
}

export function ditarV2ResponseExample() {
  return {
    "data": {
      "metadata" : {
        "id" : 55,
        "timestamp" : "2024-06-25T22:47:46",
        "transcritoPor" : "whisper-1",
        "interpretadoPor" : "gpt-4o"
      },
      "payload" : {
        "paciente": {
          "nome" : "Ana Elisa Santos Torres",
          "whatsapp" : "5511987654321",
          "CPF" : "83510940059",
          "dataNascimento" : "2002-12-20",
          "preAnamnese" : {
            "respondidaEm" : "2024-03-20T11:43:23-03:00",
            "respostasConsolidadas" : "Motivo: avaliação para cirurgia.\nUsa óculos.\nUsa óculos de 5 a 10 anos..\nDetesta usar óculos.\nDificuldade para ver de perto.\nCoceiras: Ocasionalmente.\nRessecamento: Nunca.\nArdência ou queimação: Ocasionalmente.\nCorpo estranho: Ocasionalmente.\nUso diário de computador: Menos de 1 hora.\nUso diário de celular: 1-3 horas.\nLentes de contato: Não.\nOrigem: Reputação da instituição.\nCompartilha dados: Sim"
          }
        },
        "receita" : {
          "refracao" : {
            "OD" : {
              "esf" : "-7,50",
              "cil" : "-8,50",
              "eixo" : "90",
              "adicao" : "+1,00"
            },
            "OE" : {
              "esf" : "-7,50",
              "cil" : "-8,50",
              "eixo" : "90",
              "adicao" : "+1,00"
            }
          },
          "tipo": "Progressiva",
          "uso" : {
            "longe" : true,
            "perto" : false
          },
          "prisma" : {
            "ativarPrisma" : true,
            "OD" : {
              "prisma" : "2",
              "base" : "nasal"
            },
            "OE" : {
              "prisma" : "1",
              "base" : "temporal"
            }
          },
          "orientacoes" : {
            "ativarOrientacoes" : true,
            "orientacoesPaciente" : "Utilize para dirigir"
          },
          "tecnologias" : [
            {
              "id" : "tec879823489899",
              "marca" : "Essilor",
              "nome" : "Crizal Easy",
              "descricao" : "Antirreflexo mais fácil de limpar"
            }
          ]
        },
        "jornadas" : {
          "cirurgiaImplanteLIO" : {
            "dataIndicacao" : "2023-12-20T15:17:22-03:00",
            "status" : "em_andamento",
            "OD" : {
              "indicacao" : "Catarata",
              "tiposDeLentes" : [
                {
                  "id" : "tec5h46er5t4y",
                  "nome" : "Trifocal/Quadrifocal",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tecytrety45",
                  "nome" : "Monofocal/EDOF",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tech1b56ert41yh6",
                  "nome" : "Monofocal Plus",
                  "indicada" : false
                },
                {
                  "id" : "tec6532455154",
                  "nome" : "Bifocal",
                  "indicada" : false
                },
                {
                  "id" : "tec46554654264554",
                  "nome" : "Monofocal Asferica",
                  "indicada" : false
                },
                {
                  "id" : "tec3245980934628",
                  "nome" : "Monofocal Esferica",
                  "indicada" : false
                },
                {
                  "id" : "tec2655444534546",
                  "nome" : "Lente Facica",
                  "indicada" : false
                }
              ],
              "alvoMonofocal" : "Perto",
              "torica" : false,
              "tecnologias" : [
                {
                  "id" : "tec8798234423",
                  "tipo" : "Trifocal/Quadrifocal",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular A",
                  "descricao" : "Adequada para...."
                },
                {
                  "id" : "tec927652384983",
                  "tipo" : "Monofocal EDOF",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular B",
                  "descricao" : "Adequada para...."
                }
              ]
            },
            "OE" : {
              "indicacao" : "Catarata",
              "tiposDeLentes" : [
                {
                  "id" : "tec5h46er5t4y",
                  "nome" : "Trifocal/Quadrifocal",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tecytrety45",
                  "nome" : "Monofocal/EDOF",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tech1b56ert41yh6",
                  "nome" : "Monofocal Plus",
                  "indicada" : false
                },
                {
                  "id" : "tec6532455154",
                  "nome" : "Bifocal",
                  "indicada" : false
                },
                {
                  "id" : "tec46554654264554",
                  "nome" : "Monofocal Asferica",
                  "indicada" : false
                },
                {
                  "id" : "tec3245980934628",
                  "nome" : "Monofocal Esferica",
                  "indicada" : false
                },
                {
                  "id" : "tec2655444534546",
                  "nome" : "Lente Facica",
                  "indicada" : false
                }
              ],
              "alvoMonofocal" : "Perto",
              "torica" : false,
              "tecnologias" : [
                {
                  "id" : "tec8798234423",
                  "tipo" : "Trifocal/Quadrifocal",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular A",
                  "descricao" : "Adequada para...."
                },
                {
                  "id" : "tec927652384983",
                  "tipo" : "Monofocal EDOF",
                  "marca" : "JnJ",
                  "nome" : "Lente Intraocular B",
                  "descricao" : "Adequada para...."
                }
              ]
            },
            "recursosIntraoperatorios" : {
              "femtoFlacs" : false,
              "guiadoPorImagem" : false,
              "aberrometria" : false
            }
          },
          "cirurgiaRefrativa" : {
            "status" : "draft",
            "OD" : {
              "indicacao" : "Longe",
              "tecnicas" : [
                {
                  "id" : "tec24234542543456",
                  "nome" : "Femto LASIK",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tec24234542543455",
                  "nome" : "LASIK com microceratomo",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tec24234542543454",
                  "nome" : "TransPRK",
                  "indicada" : false
                },
                {
                  "id" : "tec24234542543453",
                  "nome" : "PRK",
                  "indicada" : false
                },
                {
                  "id" : "tec24234542543452",
                  "nome" : "SMILE",
                  "indicada" : false
                },
                {
                  "id" : "tec24234542543451",
                  "nome" : "PTK",
                  "indicada" : false
                }
              ],
              "tratamentoGuiado" : {
                "topografia" : true,
                "frenteDeOnda" : false
              }
            },
            "OE" : {
              "indicacao" : "Longe",
              "tecnicas" : [
                {
                  "id" : "tec242345432452347",
                  "nome" : "Femto LASIK",
                  "indicada" : true,
                  "posicao" : 1
                },
                {
                  "id" : "tec242345432452346",
                  "nome" : "LASIK com microceratomo",
                  "indicada" : true,
                  "posicao" : 2
                },
                {
                  "id" : "tec242345432452345",
                  "nome" : "TransPRK",
                  "indicada" : false
                },
                {
                  "id" : "tec242345432452344",
                  "nome" : "PRK",
                  "indicada" : false
                },
                {
                  "id" : "tec242345432452343",
                  "nome" : "SMILE",
                  "indicada" : false
                },
                {
                  "id" : "tec242345432452342",
                  "nome" : "PTK",
                  "indicada" : false
                }
              ],
              "tratamentoGuiado" : {
                "topografia" : true,
                "frenteDeOnda" : false
              }
            }
          }
        }
      }
    }
  };
}

export function executarV2RequestExample() {
  return {
    "Paciente" : {
      "id" : "pid4571a2846c0d726e9844c4377de7e4a9",
      "Nome" : "Ana Elisa Santos Torres",
      "Whatsapp" : "5511987654321",
      "CPF" : "83510940059",
      "Data de nascimento" : "2002-12-20"
    },
    "Receita" : {
      "Refracao" : {
        "OD" : {
          "Esf" : "-1,25",
          "Cil" : "-0,75",
          "Eixo" : "80º",
          "Adicao" : "+0,50"
        },
        "OE" : {
          "Esf" : "-1,25",
          "Cil" : "-0,75",
          "Eixo" : "80º",
          "Adicao" : "+0,50"
        }
      },
      "Tipo" : "Monofocal",
      "Uso" : {
        "Longe" : true,
        "Perto" : false
      },
      "Prisma" : {
        "OD" : {
          "Prisma" : "2",
          "Base" : "nasal"
        },
        "OE" : {
          "Prisma" : "1",
          "Base" : "temporal"
        }
      },
      "Orientacoes paciente" : "Utilize para dirigir",
      "Tecnologias" : [
        {
          "id" : "tec879823489899",
          "marca" : "Essilor",
          "nome" : "Crizal Easy",
          "descrição" : "Antirreflexo mais fácil de limpar"
        }
      ]
    },
    "Jornadas" : {
      "Cirurgia implante LIO" : {
        "OD" : {
          "Indicacao" : "Catarata",
          "TiposDeLentes" : [
            {
              "Nome" : "Trifocal/Quadrifocal",
              "Indicada" : true,
              "Posicao" : 1
            },
            {
              "Nome" : "Monofocal/EDOF",
              "Indicada" : true,
              "Posicao" : 2
            },
            {
              "Nome" : "Monofocal Plus",
              "Indicada" : false
            },
            {
              "Nome" : "Bifocal",
              "Indicada" : false
            },
            {
              "Nome" : "Monofocal Asférica",
              "Indicada" : false
            },
            {
              "Nome" : "Monofocal Esférica",
              "Indicada" : false
            },
            {
              "Nome" : "Lente Fácica",
              "Indicada" : false
            }
          ],
          "AlvoMonofocal" : "Perto",
          "Torica" : false,
          "Tecnologias" : [
            {
              "id" : "tec8798234423",
              "tipo" : "Trifocal/Quadrifocal",
              "marca" : "JnJ",
              "nome" : "Lente Intraocular A",
              "descrição" : "Adequada para...."
            },
            {
              "id" : "tec927652384983",
              "tipo" : "Monofocal EDOF",
              "marca" : "JnJ",
              "nome" : "Lente Intraocular B",
              "descrição" : "Adequada para...."
            }
          ]
        },
        "OE" : {
          "Indicacao" : "Catarata",
          "TiposDeLentes" : [
            {
              "Nome" : "Trifocal/Quadrifocal",
              "Indicada" : true,
              "Posicao" : 1
            },
            {
              "Nome" : "Monofocal/EDOF",
              "Indicada" : true,
              "Posicao" : 2
            },
            {
              "Nome" : "Monofocal Plus",
              "Indicada" : false
            },
            {
              "Nome" : "Bifocal",
              "Indicada" : false
            },
            {
              "Nome" : "Monofocal Asférica",
              "Indicada" : false
            },
            {
              "Nome" : "Monofocal Esférica",
              "Indicada" : false
            },
            {
              "Nome" : "Lente Fácica",
              "Indicada" : false
            }
          ],
          "AlvoMonofocal" : "Perto",
          "Torica" : false,
          "Tecnologias" : [
            {
              "id" : "tec8798234423",
              "tipo" : "Trifocal/Quadrifocal",
              "marca" : "JnJ",
              "nome" : "Lente Intraocular A",
              "descrição" : "Adequada para...."
            },
            {
              "id" : "tec927652384983",
              "tipo" : "Monofocal EDOF",
              "marca" : "JnJ",
              "nome" : "Lente Intraocular B",
              "descrição" : "Adequada para...."
            }
          ]
        },
        "Recursos intraoperatorios" : {
          "Femto (FLACS)" : false,
          "Guiado por imagem" : false,
          "Aberrometria" : false
        }
      },
      "Cirurgia refrativa" : {
        "OD" : {
          "Indicacao" : "Longe",
          "Tecnicas" : [
            {
              "Nome" : "Femto LASIK",
              "Indicada" : true,
              "Posicao" : 1
            },
            {
              "Nome" : "LASIK com microcerátomo",
              "Indicada" : true,
              "Posicao" : 2
            },
            {
              "Nome" : "TransPRK",
              "Indicada" : false
            },
            {
              "Nome" : "PRK",
              "Indicada" : false
            },
            {
              "Nome" : "SMILE",
              "Indicada" : false
            },
            {
              "Nome" : "PTK",
              "Indicada" : false
            }
          ],
          "Tratamento guiado" : {
            "Topografia" : true,
            "Frente de onda" : false
          }
        },
        "OE" : {
          "Indicacao" : "Longe",
          "Tecnicas" : [
            {
              "Nome" : "Femto LASIK",
              "Indicada" : true,
              "Posicao" : 1
            },
            {
              "Nome" : "LASIK com microcerátomo",
              "Indicada" : true,
              "Posicao" : 2
            },
            {
              "Nome" : "TransPRK",
              "Indicada" : false
            },
            {
              "Nome" : "PRK",
              "Indicada" : false
            },
            {
              "Nome" : "SMILE",
              "Indicada" : false
            },
            {
              "Nome" : "PTK",
              "Indicada" : false
            }
          ],
          "Tratamento guiado" : {
            "Topografia" : true,
            "Frente de onda" : false
          }
        }
      }
    }
  };
}

export function transicionarV2RequestExample() {
  return {
    "id" : "jor8dbe09b98cb2fb231b86bf7430132ff6",
    "transicao" : "encerrar_consulta"
  };
}

export function transicionarV2ResponseExample() {
  return {
    "id" : "jor8dbe09b98cb2fb231b86bf7430132ff6",
    "Etapa" : "pos_consulta"
  };
}