import { AcuidadeVisualDePertoDTO } from '@dtos/ConsultaDTO';
import { AcuidadeVisualPertoLinha } from './linha';
import {
  Container, 
  Linha,
  Label,
} from './styles';
import { Radio } from '@components/Radio';

interface Props {
  data: AcuidadeVisualDePertoDTO,
  handleChange: (values: AcuidadeVisualDePertoDTO) => void;
}

export function AcuidadeVisualPerto({ data, handleChange} : Props) {

  function handleChangeOlhoDireito(values) {
    const new_data = {
      ...data,
      "OD": values
    };
    handleChange(new_data);
  }

  function handleChangeOlhoEsquerdo(values) {
    const new_data = {
      ...data,
      "OE": values
    };
    handleChange(new_data);
  }

  function handleChangeAmbosOlhos(values) {
    const new_data = {
      ...data,
      "AO": values
    };
    handleChange(new_data);
  }

  function handleAtivar() {
    handleChange({...data, "ativarAcuidadeVisualDePerto": !data.ativarAcuidadeVisualDePerto});
  }

  if(!data) {
    data = {
      "ativarAcuidadeVisualDePerto": false, 
      "OD": {semCorrecao: "", correcaoAnterior: "", novaCorrecao: ""},
      "OE": {semCorrecao: "", correcaoAnterior: "", novaCorrecao: ""},
      "AO": {semCorrecao: "", correcaoAnterior: "", novaCorrecao: ""}
    };
  }

  return (
    <>
      <Radio label="Acuidade visual de perto" status={data.ativarAcuidadeVisualDePerto} 
        onChange={handleAtivar}
      />
      { data.ativarAcuidadeVisualDePerto &&
      <Container>
        <Linha key="od">
          <Label>OD</Label>
          <AcuidadeVisualPertoLinha 
            data={data && data.hasOwnProperty("OD") ? data.OD : {}} 
            handleChange={handleChangeOlhoDireito}
          />
        </Linha>
        <Linha key="oe">
          <Label>OE</Label>
          <AcuidadeVisualPertoLinha 
            data={data && data.hasOwnProperty("OE") ? data.OE : {}} 
            handleChange={handleChangeOlhoEsquerdo}
          />
        </Linha>
        <Linha key="ao">
          <Label>AO</Label>
          <AcuidadeVisualPertoLinha 
            data={data && data.hasOwnProperty("AO") ? data.AO : {}} 
            handleChange={handleChangeAmbosOlhos}
          />
        </Linha>
      </Container>
    }
  </>);
}