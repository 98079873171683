import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  gap: 20px;
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const Label = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #BFB3AF;
`;

export const Column = styled.div`
`;

export const ColumnLabel = styled.div`
  color: #BFB3AF;
`;

export const Select = styled.select.attrs(props => ({
  error: props.error || "false"
}))`
  margin: 5px;
  text-indent: 1px;
  text-overflow: '';
  border-radius: 5px;
  border: ${props => props.error === "true" ? "2px inset #C71173" : "none"}; 
`;
