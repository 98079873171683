import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
  border-radius: 20px;
  background-color: #342745;
  margin-bottom: 20px;
`;

export const Title = styled.h3`
  color: #fff;
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Botao = styled.button`
  background: transparent;
  border: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #FFFFFF;

  & > img {
    margin-right: 8px;
  }
`;

export const RecomendacoesLista = styled.div`
  border: 2px solid #811EF9;
  padding: 11px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const RecomendacaoLinha = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;
`;