import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  border: none;
  background: transparent;
`;

export const Message = styled.div`
  position: absolute;
  left: -10px;
`;