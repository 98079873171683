const macula = [ 
  'seca', 
  'edema', 
  'drusas', 
  'hemorragia'
];
const vasos = [ 
  'sem alterações', 
  'tortuosos', 
  'estreitados', 
  'dilatados'
];
const periferia = [ 
  'sem alterações', 
  'lesões de rotura', 
  'degeneração lattice', 
  'buracos retinianos'
];
const vitreo = [ 
  'transparente', 
  'com flocos', 
  'opacidades', 
  'hemorragia vítrea', 
];

import { FundoDeOlhoOlhoDTO } from '@dtos/ConsultaDTO';
import {
  Select,
  Input
} from './styles';

interface FundoDeOlhoRetinaProps {
  data: FundoDeOlhoOlhoDTO;
  handleChange: (values: FundoDeOlhoOlhoDTO) => void;
}

export function FundoDeOlhoRetina({
  data,
  handleChange,  
}: FundoDeOlhoRetinaProps) {

  function handleChangeField(field, value) {
    const new_data = {...data, [field]:value };
    handleChange(new_data);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  return (
    <>
      <Select 
        key="macula"
        value={data && data.macula}
        onChange={(e) => handleChangeField('macula', e.target.value)}
      >
        {macula.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Input 
        key="poloPosterior"
        value={data ? data.poloPosterior : ''}
        onChange={(e) => handleChangeField('poloPosterior', e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Select 
        key="vasos"
        value={data && data.vasos}
        onChange={(e) => handleChangeField('vasos', e.target.value)}
      >
        {vasos.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="periferia"
        value={data && data.periferia}
        onChange={(e) => handleChangeField('periferia', e.target.value)}
      >
        {periferia.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="vitreo"
        value={data && data.vitreo}
        onChange={(e) => handleChangeField('vitreo', e.target.value)}
      >
        {vitreo.map(e => (<option key={e}>{e}</option>))}
      </Select>
    </>
  );
}