import styled from 'styled-components';

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubTitle = styled.h3`
  color: #BFB3AF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
`;

export const OlhoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

export const MiniLabel = styled.span`
  color: #BFB3AF;
`;

export const TecnicasContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
`;

export const DraggableLinha = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > img {
    height: 22px;
  }
`;

export const Posicao = styled.span`
  color: #ffffff;
  background: #35155C;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
`;
