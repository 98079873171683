const nervo_optico_coloracao = [ 
  'róseo', 
  'pálido', 
  'hiperêmico', 
];
const nervo_optico_margens = [ 
  'delimitadas', 
  'borradas', 
  'escavadas', 
];
const nervo_optico_rima_neural = [ 
  'ISNT preservado', 
  'Hoyt superior', 
  'afinamento', 
];

import { FundoDeOlhoOlhoDTO } from '@dtos/ConsultaDTO';
import {
  Select,
  Input
} from './styles';

interface FundoDeOlhoOlhoProps {
  data: FundoDeOlhoOlhoDTO;
  handleChange: (values: FundoDeOlhoOlhoDTO) => void;
}

export function FundoDeOlhoOlho({
  data,
  handleChange,  
}: FundoDeOlhoOlhoProps) {

  function handleChangeField(field, value) {
    const new_data = {...data, [field]:value };
    handleChange(new_data);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  return (
    <>
      <Input 
        value={data ? data.escDiscoV : ''}
        onChange={(e) => handleChangeField('escDiscoV', e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Input 
        key="escDiscoH"
        value={data ? data.escDiscoH : ''}
        onChange={(e) => handleChangeField('escDiscoH', e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Select 
        key="coloracao"
        value={data && data.coloracao}
        onChange={(e) => handleChangeField('coloracao', e.target.value)}
      >
        {nervo_optico_coloracao.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="margens"
        value={data && data.margens}
        onChange={(e) => handleChangeField('margens', e.target.value)}
      >
        {nervo_optico_margens.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="rimaNeural"
        value={data && data.rimaNeural}
        onChange={(e) => handleChangeField('rimaNeural', e.target.value)}
      >
        {nervo_optico_rima_neural.map(e => (<option key={e}>{e}</option>))}
      </Select>
    </>
  );
}