import {
  Coluna,
  MiniLabel,
  Valor
} from './styles';

const options = [
  "",
  "J1",
  "J2",
  "J3",
  "J4",
  "J5",
  "J6",
  "J7",
  "J8",
  "J9",
  "J10",
  "J11",
  "J12",
  "J13",
  "J14",
  "J15",
];

export function AcuidadeVisualPertoLinha({ data, handleChange }) {

  function handleChangeSemCorrecao(e) {
    const new_data = {...data, "semCorrecao": e.target.value};
    handleChange(new_data);
  }

  function handleChangeCorrecaoAnterior(e) {
    const new_data = {...data, "correcaoAnterior": e.target.value};
    handleChange(new_data);
  }

  function handleChangeNovaCorrecao(e) {
    const new_data = {...data, "novaCorrecao": e.target.value};
    handleChange(new_data);
  }

  return (
    <>
      <Coluna key="sem_correcao">
        <MiniLabel className="ui_mini_labels">Sem correção</MiniLabel>
        <Valor className="ui_labels" 
          value={data.hasOwnProperty("semCorrecao") ? data.semCorrecao : ""}
          onChange={handleChangeSemCorrecao}
        >
          {options.map((e) => <option key={e} value={e}>{e}</option>)}
        </Valor>
      </Coluna>
      <Coluna key="correcao_anterior">
        <MiniLabel className="ui_mini_labels">Última correção</MiniLabel>
        <Valor className="ui_labels"
          value={data.hasOwnProperty("correcaoAnterior") ? data.correcaoAnterior : ""}
          onChange={handleChangeCorrecaoAnterior}
        >
          {options.map((e) => <option key={e} value={e}>{e}</option>)}
        </Valor>
      </Coluna>
      <Coluna key="nova_correcao">
        <MiniLabel className="ui_mini_labels">Nova correção</MiniLabel>
        <Valor className="ui_labels"
          value={data.hasOwnProperty("novaCorrecao") ? data.novaCorrecao : ""}    
          onChange={handleChangeNovaCorrecao}    
        >
          {options.map((e) => <option key={e} value={e}>{e}</option>)}
        </Valor>
      </Coluna>
    </>
  );
}