const palpebras_e_cilios = [ 
  'normais', 
  'blefarite', 
  'triquíase', 
  'madarose', 
  'ptose',
];
const conjuntiva_palpebral = [
  'sem hiperemia', 
  'hiperemia leve', 
  'hiperemia moderada', 
  'hiperemia intensa', 
  'papilas', 
  'folículos', 
  'cicatrizes'
];
const conjuntiva_bulbar = [
  'sem hiperemia', 
  'hiperemia leve', 
  'hiperemia moderada', 
  'hiperemia intensa', 
  'quemoses', 
  'pigmentação', 
  'lesão'
];
const esclera = [
  'branca', 
  'ligeiramente amarelada',
  'ictérica', 
  'injetada', 
  'nodular'
];
const cornea = [
  'transparente', 
  'opacidade central', 
  'opacidade periférica', 
  'edema', 
  'neovascularização', 
  'cicatriz'
];
const camara_anterior = [
  'profunda', 
  'rasa', 
  'moderada', 
  'células presentes', 
  'flare', 
  'hifema', 
  'hipopion'
];
const angulo = [
  'aberto', 
  'fechado', 
  'estreito', 
  'sinequias'
];
const iris = [
  'normotrópica', 
  'pigmentada', 
  'atrófica', 
  'sinequias', 
  'rubeose'
];
const cristalino = [
  'transparente', 
  'catarata nuclear', 
  'catarata cortical', 
  'catarata subcapsular posterior',
  'luxado', 
  'subluxado'
];
const vitreo_anterior = [
  'sem células', 
  'células presentes', 
  'sinérese', 
  'opacidades', 
  'hemorragia'
];

import { SegmentoAnteriorOlhoDTO } from '@dtos/ConsultaDTO';
import {
  Select
} from './styles';
import { useEffect } from 'react';

interface SegmentoAnteriorOlhoProps {
  data: SegmentoAnteriorOlhoDTO,
  handleChange: (values: SegmentoAnteriorOlhoDTO) => void;
}

export function SegmentoAnteriorOlho({
  data,
  handleChange,  
}: SegmentoAnteriorOlhoProps) {

  useEffect(() => {

  }, []);

  function handleChangeField(field, value) {
    const new_data = {...data, [field]:value };
    handleChange(new_data);
  }

  return (
    <>
      <Select 
        key="palpebras_e_cilios" 
        value={(data && data.palpebraEcilios)}
        onChange={(e) => handleChangeField('palpebraEcilios', e.target.value)}
      >
        {palpebras_e_cilios.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="conjuntiva_palpebral"
        value={(data && data.conjuntivaPalpebral)}
        onChange={(e) => handleChangeField('conjuntivaPalpebral', e.target.value)}
      >
        {conjuntiva_palpebral.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="conjuntiva_bulbar"
        value={data && data.conjuntivaBulbar}
        onChange={(e) => handleChangeField('conjuntivaBulbar', e.target.value)}
      >
        {conjuntiva_bulbar.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="esclera"
        value={data && data.esclera}
        onChange={(e) => handleChangeField('esclera', e.target.value)}
      >
        {esclera.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="cornea"
        value={data && data.cornea}
        onChange={(e) => handleChangeField('cornea', e.target.value)}
      >
        {cornea.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="camara_anterior"
        value={data && data.camaraAnterior}
        onChange={(e) => handleChangeField('camaraAnterior', e.target.value)}
      >
        {camara_anterior.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="angulo"
        value={data && data.angulo}
        onChange={(e) => handleChangeField('angulo', e.target.value)}
      >
        {angulo.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="iris"
        value={data && data.iris}
        onChange={(e) => handleChangeField('iris', e.target.value)}
      >
        {iris.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="cristalino"
        value={data && data.cristalino}
        onChange={(e) => handleChangeField('cristalino', e.target.value)}
      >
        {cristalino.map(e => (<option key={e}>{e}</option>))}
      </Select>
      <Select 
        key="vitreo_anterior"
        value={data && data.vitreoAnterior}
        onChange={(e) => handleChangeField('vitreoAnterior', e.target.value)}
      >
        {vitreo_anterior.map(e => (<option key={e}>{e}</option>))}
      </Select>
    </>
  );
}