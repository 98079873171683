import { useState, useEffect, useRef } from "react";
import { TriplaOpcao } from "@components/TriplaOpcao";
import { Radio } from "@components/Radio";
import { CirurgiaRefrativaOlhoDTO } from '@dtos/ConsultaDTO';
import BtnReplicar from '@assets/btn_replicar.png';

import {
  Linha,
  SubTitle,
  OlhoContainer,
  MiniLabel,
  TecnicasContainer,
  DraggableLinha
} from './styles';
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { Tecnica } from "./tecnica";
import { getTecnologiasDisponiveisRefrativa } from "@utils/conversaoV2";
import { useConsulta } from "@hooks/useConsulta";

const indicacoesRefrativa = [
  {label: 'Não indicada', value:'Não indicada'},
  {label: 'Longe', value:'Longe'},
  {label: 'Perto', value:'Perto'}
];

interface Props {
  data: CirurgiaRefrativaOlhoDTO;
  title: string;
  handleChange: (data: CirurgiaRefrativaOlhoDTO) => void;
  replicar?: boolean;
  handleReplicar?: () => void;
}

export const CirurgiaRefrativaOlho = ({
  data, 
  title, 
  handleChange,
  replicar=false,
  handleReplicar
} : Props) => {
  const [tecnicasIndicada, setTecnicasIndicada] = useState([]);
  const [tecnicasNaoIndicada, setTecnicasNaoIndicada] = useState([]);

  const { consultaData } = useConsulta();  
  const tipos = getTecnologiasDisponiveisRefrativa(consultaData);

  useEffect(() => {
    var indicadas = data.tecnicas;
    if(indicadas) {
      setTecnicasIndicada(indicadas);
      const indicadasIds = new Set(indicadas.map(({ id }) => id));    
      var nao_indicadas = tipos.filter((item) => !indicadasIds.has(item.id))
      setTecnicasNaoIndicada(nao_indicadas);
    } else {
      setTecnicasIndicada([]);
      setTecnicasNaoIndicada(tipos);
    }
  }, [data]);

  function handleDragEnd(result: any) {
    if(!result.destination) {
      return;
    }
    const indicadas = reorder(tecnicasIndicada, result.source.index, result.destination.index);
    setTecnicasIndicada(indicadas);

    const ordenados = ordenaPosicao(indicadas);
    handleChangeField("tecnicas", ordenados);
  }

  function handleChangeTopografia(value) {
    const new_data : CirurgiaRefrativaOlhoDTO =  data;
    new_data.tratamentoGuiado.topografia = value;
    handleChange(new_data);
  }

  function handleChangeFrenteDeOnda(value) {
    const new_data : CirurgiaRefrativaOlhoDTO =  data;
    new_data.tratamentoGuiado.frenteDeOnda = value;
    handleChange(new_data);
  }

  function reorder<T>(List: T[], startIndex: number, endIndex:number) {
    const result = Array.from(List);
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed);
    return result;
  }

  function handleIndicadaChange(option) {
    const tipos = data.tecnicas.filter((e) => option.id !== e.id);
    const ordenados = ordenaPosicao(tipos);
    handleChangeField("tecnicas", ordenados);
  }

  function handleNaoIndicadaChange(option) {
    var max_item = data.tecnicas?.reduce((acc, i)=>(i.posicao > acc.posicao ? i : acc), { posicao: 0 })
    var max = (max_item ? max_item.posicao + 1 : 0);
    if(!data.tecnicas)
      data.tecnicas = [];
    const indicadas = [...data.tecnicas, {...option, posicao: max}];
    const ordenados = ordenaPosicao(indicadas);
    handleChangeField("tecnicas", ordenados);
  }

  function handleChangeField(field, value) {
    const new_data : CirurgiaRefrativaOlhoDTO = {...data, [field]: value};
    handleChange(new_data);
  }

  function ordenaPosicao(items) {
    var posicao = 0;
    const new_items = items.map(e => {
      posicao++;
      e.posicao = posicao;
      return e;
    })
    return new_items;
  }

  if(!data) {
    data = {
      "indicacao": "",
      "tecnicas": [],
      "tratamentoGuiado": {
        "frenteDeOnda": false,
        "topografia": false,
      }
    }
  }

  const indicada = (data?.indicacao === "Longe" || data?.indicacao === "Perto");

  return (
    <OlhoContainer>
      <SubTitle>
        {title}
        {replicar && <button onClick={handleReplicar}><img src={BtnReplicar} style={{ height: '20px'}} /></button>}
      </SubTitle>

      <TriplaOpcao options={indicacoesRefrativa} selected={data?.indicacao} onClick={(value) => handleChangeField('indicacao', value)}></TriplaOpcao>
      {indicada &&
      <>
        <TecnicasContainer>
          <MiniLabel className="ui_mini_labels">Técnicas</MiniLabel>
          <div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="tipolente" type="list" direction="vertical">
                {(provided) =>(
                  <article ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {tecnicasIndicada.map((lente, i) => (
                      <Tecnica key={lente.id} lente={lente} index={i} handleChange={() => handleIndicadaChange(lente)} />
                    ))}
                    {provided.placeholder}
                  </article>
                )}
              </Droppable>
            </DragDropContext>
            {tecnicasNaoIndicada.map((option, i) => (
              <DraggableLinha key={i}>
                <Radio label={option.nome} status={false} onChange={() => handleNaoIndicadaChange(option)} />
              </DraggableLinha>
              ))
            }
          </div>
        </TecnicasContainer>
        
        <div>
          <Linha>
            <MiniLabel className="ui_mini_labels">Tratamento personalizado guiado</MiniLabel>
          </Linha>
          <Linha>
            <Radio label="Topografia" status={data.tratamentoGuiado.topografia} onChange={handleChangeTopografia}/>
            <Radio label="Frente de onda" status={data.tratamentoGuiado.frenteDeOnda} onChange={handleChangeFrenteDeOnda}/>
          </Linha>
        </div>
      </>
      }
    </OlhoContainer>
  );
}