import styled from 'styled-components'

export const Container = styled.div.attrs(props => ({
  $pronto: props.$pronto || 'false',
  $ai: props.$ai || false,
  $mode: props.mode || ''
}))<{ $pronto?: string; $ai: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 16px 15px 15px;
  border-radius: 20px;
  gap: 15px;
  position: relative;
  margin-bottom: 15px;
  background: ${({ $mode }) => $mode === 'dark' ? '#F5F5F5' : '#FFFFFF' };
  border: ${({ $pronto }) => $pronto === 'true' ? '2px solid #811EF9' : 'none'};

  ${({ $ai, $mode }) => $ai && `
    background: 
      linear-gradient(`+($mode === 'dark' ? '#F5F5F5' : '#FFFFFF')+` 0 0) padding-box,
      linear-gradient(270deg, #1296A8 0%, #811EF9 100%) border-box;
    border: 3px solid transparent;
  `}
`;

export const Cabecalho = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const Titulo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const MiniTitulo = styled.span`
  color: #35155C;
`;

export const CloseIcon = styled.button``;
