import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const Image = styled.img`
`;

export const Label = styled.span`
  margin-left: 4px;
  &.ui_labels_bold {
    color: #35155C;
  }
`;
