import { useState, useEffect } from "react";
import { isValid, parse, format, isWithinInterval, parseISO } from "date-fns";
import { cpfMask, phoneMask, formatData } from '@utils/format';
import { Editable } from '../Editable';
import { EditableNome } from '../EditableNome';
import { useConsulta } from "@hooks/useConsulta";
import { ConsultaBloco } from '../ConsultaBloco';

import { 
  Content,
  Label,
  Linha,
  Campo,
} from './styles';

export function DadosCadastrais({ is_new = false }) {
  
  const [nome, setNome] = useState('');
  const [cpf, setCPF] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [dataNascimentoError, setDataNascimentoError] = useState(false);

  const { 
    paciente, 
    alteraPaciente, 
    validarPaciente, 
    pacientePronto, 
    errorPaciente, 
    setErrorPaciente,
    alteradoPorAi
  } = useConsulta();
  
  useEffect(() => {
    if(!is_new) {
      validarPaciente(false);
    }
  }, []);

  useEffect(() => {
    setNome(paciente.nome);
  }, [paciente.nome]);

  useEffect(() => {
    if(paciente.CPF)
      setCPF(cpfMask(paciente.CPF));
  }, [paciente.CPF]);

  useEffect(() => {
    if(paciente.whatsapp)
      setWhatsApp(phoneMask(paciente.whatsapp));
}, [paciente.whatsapp]);

  useEffect(() => {
    if(paciente.dataNascimento && isValid(new Date(paciente.dataNascimento)))
      setDataNascimento(formatData(paciente.dataNascimento));    
  }, [paciente.dataNascimento]);

  useEffect(() => {
    setDataNascimentoError(errorPaciente.dataNascimento);
  }, [errorPaciente.dataNascimento]);

  function clearError(field) {
    if(errorPaciente[field]) {
      let newError = {...errorPaciente};
      delete newError[field];
      setErrorPaciente(newError);
    }
  }

  function handleChangeNome(e) {
    setNome(e.target.value);
    clearError('nome');
  }

  function handleChangeCPF(e) {
    setCPF(cpfMask(e.target.value));
    clearError('cpf');
  }
  
  function handleChangeWhatsapp(e) {
    setWhatsApp(phoneMask(e.target.value)); 
    clearError('whatsapp');
  }

  function handleBlurNome() {
    var new_paciente = {...paciente, 'nome': nome };
    alteraPaciente(new_paciente);
    validarPaciente(false, new_paciente);

    // validar ao tirar o foco
    if(nome) {
      const new_error = errorPaciente;
      delete new_error.nome;
      setErrorPaciente(new_error);
    } else {
      setErrorPaciente({...errorPaciente, 'nome': true})
    }

  }

  function handleBlurCPF() {
    var new_paciente = {...paciente, 'CPF': cpf };
    alteraPaciente(new_paciente);
    validarPaciente(false, new_paciente);

    // validar ao tirar o foco
    if(cpf) {
      const new_error = errorPaciente;
      delete new_error.cpf;
      setErrorPaciente(new_error);
    } else {
      setErrorPaciente({...errorPaciente, 'cpf': true})
    }
  }

  function handleBlurWhatsApp() {
    var new_paciente = {...paciente, 'whatsapp': whatsApp };
    alteraPaciente(new_paciente);
    validarPaciente(false, new_paciente);

    // validar ao tirar o foco
    if(whatsApp) {
      const new_error = errorPaciente;
      delete new_error.whatsapp;
      setErrorPaciente(new_error);
    } else {
      setErrorPaciente({...errorPaciente, 'whatsapp': true})
    }
  }

  function handleBlurNascimento() {
    if(dataNascimento && dataNascimento !== '__/__/____') {
      const parsed = parse(dataNascimento, 'dd/MM/yyyy', new Date());
      const isValidDate = isValid(parsed);
      if(!isValidDate) {
        setDataNascimentoError(true);
        return;
      }
      const isWithin = isWithinInterval(parsed, { start: parseISO('1910-01-01'), end: new Date() })
      if(!isWithin) {
        setDataNascimentoError(true);
        return;
      }
      const date = format(parse(dataNascimento, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
      var new_paciente = {...paciente, 'dataNascimento': date };
      alteraPaciente(new_paciente);

      setDataNascimentoError(false);
    } else {
      const new_error = errorPaciente;
      delete new_error.dataNascimento;
      setErrorPaciente(new_error);
      
      setDataNascimentoError(false);

      var new_paciente = {...paciente, 'dataNascimento': '' };
      alteraPaciente(new_paciente);
    }
  }

  return (
  <ConsultaBloco text="Dados cadastrais" pronto={pacientePronto} ai={alteradoPorAi.paciente}>
    <Content>
    <Linha>
      <Campo>
        <Label className="ui_mini_labels">
          Nome Completo
        </Label>
        {is_new ? 
        <EditableNome value={nome} 
          onChange={handleChangeNome}
          onBlur={handleBlurNome}
          error={!errorPaciente.nome ? 'false': 'true'}
        >
        </EditableNome>
        : <div className="ui_labels">{nome}</div>
      }
      </Campo>
    </Linha>
    
    <Linha key="2">
      <Campo key="cpf" style={{ minWidth: '125px'}}>
        <Label className="ui_mini_labels">CPF</Label>
        {is_new ? 
        <Editable
          value={cpf} 
          onChange={handleChangeCPF}
          onBlur={handleBlurCPF}
          error={!errorPaciente.cpf ? 'false' : 'true'}
          inputMode="numeric"
        />
        : <div className="ui_labels">{cpf}</div>}        
      </Campo>
      <Campo key="wa" style={{ minWidth: '130px'}}>
        <Label className="ui_mini_labels">WhatsApp</Label>
        <Editable
          value={whatsApp} 
          onChange={handleChangeWhatsapp}
          onBlur={handleBlurWhatsApp}
          error={!errorPaciente.whatsapp ? 'false' : 'true'}
          inputMode="numeric"
        />
      </Campo>
      <Campo key="nascimento">
        <Label className="ui_mini_labels">Data Nascimento</Label>
        <Editable
          value={dataNascimento} 
          onChange={(e) => { 
            setDataNascimento(e.target.value)
          }}
          mask="99/99/9999"
          onBlur={handleBlurNascimento}
          error={!dataNascimentoError ? 'false' : 'true'}
          minwidth="85px"
          inputMode="numeric"
        />
      </Campo>
    </Linha>
    </Content>
  </ConsultaBloco>);
}