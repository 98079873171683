import { format, intervalToDuration } from 'date-fns';

export function cpfMask(value) {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function phoneMask(value) {

  if(value.replace(/\D/g,'').length < 11) { // <11 sem formatacao e <15 com formatacao
    return value.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  } else if (value.replace(/\D/g,'').length === 13){
    return value.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '+$1 $2')
      .replace(/(\d{2})(\d)/, '$1 $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  } else {
    return value.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
}

export function formatDecimal(num) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

export function isNumeric(str: number) {
  if (typeof str != "string") 
    return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function formatData(date) {
  const datetime = new Date(date+'T00:00:00');
  return format(datetime, "dd/MM/yyyy");
}

export function emptyRefracao() {
  return {
    "O.D.": {
      "Esf": "",
      "Cil": "",
      "Eixo": "",
      "ADD": "",
    },
    "O.E.": {
      "Esf": "",
      "Cil": "",
      "Eixo": "",
      "ADD": "",
    }
  };
}

export function apiProcessarResponseExample() {
  return { "data": {
    "Paciente": {
        "nome": "Luiza Barbosa Gama",
        "CPF": "074.797.129-36"
    },
    "Receita": {
        "Tipo": "Óculos monofocal para longe",
        "Refração": {
            "Ajustada": {
                "O.D.": {
                    "Esf": "-0,75",
                    "Cil": "0,00"
                },
                "O.E.": {
                    "Esf": "-0,75",
                    "Cil": "-0,25",
                    "Eixo": "175\u02da"
                }
            }
        },
        "Recomendações": [
            "Antirreflexo mais fácil de limpa"
        ]
    }
  }};
}

export function apiProcessarResponseToPaciente(response, paciente) {
  var new_paciente = paciente;
  if(response.hasOwnProperty("Paciente")) {
    var response_paciente = response["Paciente"];
    // se o paciente ja estava na lista de consultas, nao alterar nome e CPF
    if(!new_paciente.id) {
      if(response_paciente.hasOwnProperty("nome")) {
        new_paciente.nome = response_paciente["nome"];
      }
      if(response_paciente.hasOwnProperty("CPF")) {
        new_paciente.cpf = response_paciente["CPF"];
      }
    }
    if(response_paciente.hasOwnProperty("whatsapp")) {
      new_paciente.whatsapp = response_paciente["whatsapp"];
    }
    if(response_paciente.hasOwnProperty("data_nascimento")) {
      new_paciente.data_nascimento = response_paciente["data_nascimento"];
    }
    if(response_paciente.hasOwnProperty("diagnosticos")) {
      new_paciente.diagnosticos = response_paciente["diagnosticos"];
    }
  }

  if(response.hasOwnProperty("Pré-anamnese")) {
    new_paciente.preanamnese = response["Pré-anamnese"];
  }

  return new_paciente;
}

export function apiDitarResponseToPaciente(paciente, response) {
  let new_paciente = pacienteType();
  if(response.data.hasOwnProperty("Paciente")) {
    // se o paciente ja estava na lista de consultas, nao alterar nome e CPF
    if(paciente.id) {
      if(response.data["Paciente"].hasOwnProperty("nome")) {
        delete response.data["Paciente"].nome;
      }
      if(response.data["Paciente"].hasOwnProperty("CPF")) {
        delete response.data["Paciente"].CPF;
      }
    }
    new_paciente = {...new_paciente, ...paciente, ...response.data["Paciente"]};
  } else {
    new_paciente = {...new_paciente, ...paciente };
  }

  if(response.data["Paciente"].hasOwnProperty("Diagnósticos")) {
    new_paciente["diagnosticos"] = response.data["Paciente"]["Diagnósticos"];
  }
  if(response.data.hasOwnProperty("Pré-anamnese")) {
    new_paciente["preanamnese"] = response.data["Pré-anamnese"];
  }
  
  return new_paciente;
}

export function pacienteType() {
  return {
    id: "",
    nome: "",
    whatsapp: "",
    CPF: "",
    data_nascimento: "",
    diagnosticos: [],
    preanamnese: []
  };
}

export function onKeyDownAllowNumeric(e) {
  const possibleKeys= [
    'ArrowRight', 'ArrowLeft', 'Delete', 'Backspace', '-', '+',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Tab'];
  if(!possibleKeys.includes(e.key)) {
    e.preventDefault();
    return false;
  }
  if(e.key === "-" || e.key === "+") {
    if(e.target.value.includes("-") || e.target.value.includes("+")) {
      e.target.value = e.key + e.target.value.replace(/^(\+|-)/, "");
    }
  }
}

export function onKeyDownAllowNegative(e) {
  const possibleKeys= [
    'ArrowRight', 'ArrowLeft', 'Delete', 'Backspace', '-',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Tab'];
  if(!possibleKeys.includes(e.key)) {
    e.preventDefault();
    return false;
  }
}

export function onKeyDownAllowOnlyPositiveNumbers(e) {
  const possibleKeys= [
    'ArrowRight', 
    'ArrowLeft', 
    'Delete', 
    'Backspace',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'Tab'
  ];

  if(!possibleKeys.includes(e.key)) {
    e.preventDefault();
    return false;
  }
}


export function formatDuration(date) {
  if(date) {
    const age = intervalToDuration({
      start: new Date(date),
      end: new Date()
    })
    return (age.years ? age.years +'a ' : '0a ')+(age.months ? age.months +'m ': '0m ' )+(age.days ? age.days +'d' : '0d');  
  }
}
