import { ConsultaBloco } from '@components/ConsultaBloco'
import styled from 'styled-components'

export const Container = styled(ConsultaBloco)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BlocoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Bloco = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
