import { useState, useRef, useEffect } from "react";
import { getWidthOfInput, isValidEixo } from '../../utils/consultaHelper';

import BtnAlterar from '../../assets/btn_alterar.svg';
import {
  Container,
  Input,
  Imagem,
} from '@components/Editable/styles';

export function EditableEixo(props) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [hasError, setHasError] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const [eixo, setEixo] = useState('');

  const resizeInput = () => {
    inputRef.current.style.width = getWidthOfInput(inputRef) + "px";
  };

  useEffect(() => {
    if(props.value) {
      var res = props.value.replace(/[^0-9]/g, ""); // remove formatação    
      if(res !== '') {
        res = res.toString()+"˚";
        setEixo(res);
      }
    }
    setHasError(props.error);
  }, [props]);

  useEffect(() => {
    if(inputRef.current) {
      // quando muda o valor por API, o valor do input muda sem estar ativo, entao recalcular tamanho dinamico
      if(document.activeElement !== inputRef.current) {
        inputRef.current.style.width = getWidthOfInput(inputRef) + "px";
      }
    }
  }, [eixo]);

  function handleFocus () {
    if(inputRef.current) {
      inputRef.current.style.width = "95%";
    }
    setIsMouseOver(false);

    // quando estiver editando, remover formatação
    var newValue = inputRef.current.value.replace(/[^0-9]/g,'');
    setEixo(newValue);
  }

  function handleMouseOver () {
    if (document.activeElement === inputRef.current) {
      return;
    }
    setIsMouseOver(true);
  };

  function handleMouseOut () {
    setIsMouseOver(false);
  }
  
  function handleClick() {
    inputRef.current.focus();
    setIsMouseOver(false);
  }

  function handleInputBlur(e) {
    var res = e.target.value.replace(/[^0-9]/g, ""); // remove formatação    
    if(res !== '') {
      var is_valid = isValidEixo(res);
      // se tem erro, mostrar visualmente e nao permitir tirar o foco
      if(!is_valid) { 
        setHasError(!is_valid); 
        inputRef.current.focus();
        return;
      }
      // se é válido, formatar
      res = res.toString()+"˚";
      setEixo(res);
    }
    // altera o valor no state receita
    props.handleBlur(res);

    // redimenciona o input se nao houver erro
    if(inputRef.current) {
      resizeInput();
    }
  }
  
  function handleChange(e) {
    setEixo(e.target.value);
    setHasError(false);
  }

  function onKeyDownEixo(e) {
    if (e.key === 'Enter') {
      var form = e.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      e.preventDefault();
    }

    const possibleKeys= [
      'ArrowRight', 
      'ArrowLeft', 
      'Delete', 
      'Backspace',
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'Tab'
    ];
    if(!possibleKeys.includes(e.key)) {
      e.preventDefault();
      return false;
    }
      
    // bloquear mais de três digitos
    const numeros = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (numeros.indexOf(e.key) !== -1) {
      const conteudo = e.target.value.replace(/[^0-9]/g, "");
      if(conteudo.length > 2 && (inputRef.current.selectionStart === inputRef.current.selectionEnd)) { // e já tem tres numeros, nao permitir
        e.preventDefault();
        return false;
      }  
    }
  }

  return (
    <Container onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Input
        onBlur={handleInputBlur}
        onFocus={handleFocus}
        ref={inputRef}
        maxLength="4"
        onKeyDown={onKeyDownEixo}
        error={hasError.toString()}
        value={eixo} 
        onChange={handleChange}
        style={{ width: '50px'}}
        className="ui_labels"
        inputMode="numeric"
        />
      {isMouseOver && <Imagem src={BtnAlterar} alt="editar" onClick={handleClick}/>}
    </Container>
  );
}