import styled from 'styled-components'

export const Image = styled.img`
  display: block;
`;

export const Container = styled.button`
  border: none;
  background: transparent;
  width: 121px;
`;

export const Button = styled.button``;

export const DefaultButton = styled.button`
  width: 121px;
  height: 44px;
  background: #00A99F;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: left;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
`;