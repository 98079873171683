import React from "react";
import { 
  Container, 
  LogoContainer,
  Logo,
  ProfileContainer,
} from './styles';

import DefaultLogo from '../../assets/title_login.svg';

export const LoginHeader = () => {
  return (
  <Container>    
    <LogoContainer>
      <Logo src={DefaultLogo} />
    </LogoContainer>
    <ProfileContainer>
    </ProfileContainer>
  </Container>);
};