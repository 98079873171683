import styled from 'styled-components'

export const Image = styled.img`
  display: block;
`;

export const Container = styled.button`
  border: none;
  //background: transparent;
  background: linear-gradient(90deg, #811EF9 0%, #1296A8 100%);
  height: 44px;
  border-radius: 50px;
  color: #fff;
  text-align: center;
`;

export const DefaultButton = styled.div`
  background: linear-gradient(90deg, #811EF9 0%, #1296A8 100%);
  width: 121px;
  height: 44px;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: left;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  padding: 0 20px;
`;