import { ErrorInfoConsulta, ErrorPaciente, ErrorReceita, PrismaOlhoDTO } from "@dtos/ConsultaDTO";
import { BlobOptions } from "buffer";
import { isValid, parse } from "date-fns";
const refracao_prop = "refracao";
const od_prop = "OD";
const oe_prop = "OE";
const esf_prop = "esf";
const cil_prop = "cil";
const eixo_prop = "eixo";
const add_prop = "adicao";

// considerando que só vai haver o parametro se tiver erro
export function erroReceita(receita) {
  var error : ErrorReceita = {};  
  if(!receita) {
    error.od_esferico = true;
    error.oe_esferico = true;
  } else {
    if(!receita.hasOwnProperty(refracao_prop)) {
      error.od_esferico = true;
      error.oe_esferico = true;
    } else {
      if(!(receita[refracao_prop].hasOwnProperty(od_prop) && receita[refracao_prop].hasOwnProperty(oe_prop))) {
        error.od_esferico = true;
        error.oe_esferico = true;
      }
    }
  }
  if(!error.od_esferico && !error.oe_esferico) {
    // se nao tem esferico, nem cilindrico
    if(receita[refracao_prop][od_prop][esf_prop] === '' &&
      receita[refracao_prop][od_prop][cil_prop] === '') {
      error.od_esferico = true;
    }
    if(receita[refracao_prop][oe_prop][esf_prop] === '' &&
      receita[refracao_prop][oe_prop][cil_prop] === '') {
      error.oe_esferico = true;
    }
    const od_eixo = (!receita[refracao_prop][od_prop][eixo_prop] ? '' : receita[refracao_prop][od_prop][eixo_prop]);
    const oe_eixo = (!receita[refracao_prop][oe_prop][eixo_prop] ? '' : receita[refracao_prop][oe_prop][eixo_prop]);
    // se tem cilindrico, tem que ter eixoß
    if(receita[refracao_prop][od_prop][cil_prop] && od_eixo === '') {
      error.od_eixo = true;
    }
    if(receita[refracao_prop][oe_prop][cil_prop] && oe_eixo === '') {
      error.oe_eixo = true;
    }
    // se tem adicao, tem que ter esferico
    if(receita[refracao_prop][od_prop][add_prop] && !receita[refracao_prop][od_prop][esf_prop]) {
      error.od_esferico = true;
    }
    if(receita[refracao_prop][oe_prop][add_prop] && !receita[refracao_prop][oe_prop][esf_prop]) {
      error.oe_esferico = true;
    }
  }
  // se tem prisma, tem que ter base
  if(receita.tipo === "Monofocal" && receita.prisma?.ativarPrisma) {
    const prisma_od : PrismaOlhoDTO = receita.prisma.OD;
    const prisma_oe : PrismaOlhoDTO= receita.prisma.OE;
    if(prisma_od.dioptriaPrismatica && !prisma_od.base) {
      error.od_prisma_base = true;
    }
    if(prisma_oe.dioptriaPrismatica && !prisma_oe.base) {
      error.oe_prisma_base = true;
    }
  }
  return error;
}

// considerando que só vai haver o parametro se tiver erro
export function erroPaciente(paciente) {
  var error : ErrorPaciente = {};
  if(!paciente) {
    return error;
  }  
  if(!paciente.nome) {
    error.nome = true;
  }
  if(!paciente.whatsapp) {
    error.whatsapp = true;
  }
  if(!paciente.CPF) {
    error.cpf = true;
  }
  if(paciente.dataNascimento) {
    const isValidDate = isValid(parse(paciente.dataNascimento, 'yyyy-MM-dd', new Date()));
    if(!isValidDate) {
      error.dataNascimento = true;
    }
  }
  return error;
}

export function validaDataConsulta(date) {
  if(!date && date === '__/__/____') {
    return false; 
  }
  const parsed = parse(date, 'dd/MM/yyyy', new Date());
  const isValidDate = isValid(parsed);
  if(!isValidDate) {
    return false;
  } else {
    return true;
  } 
}

export function validaHora(hour) {
  let regex = new RegExp(/^([01]\d|2[0-3]):?([0-5]\d)$/);
  if (hour == '') {
    return false;
  } 
  return regex.test(hour);
}

export function erroInfoConsulta(date, hour) {
  var error : ErrorInfoConsulta = {};
  if(!validaDataConsulta(date)) {
    error.date = true;
  }
  if(!validaHora(hour)) {
    error.hour = true;
  }
  return error;
}