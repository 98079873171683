import { SegmentoAnteriorDTO } from '@dtos/ConsultaDTO';
import { SegmentoAnteriorOlho } from './olho';
import {
  Tabela,
  Coluna,
  Label,
  LabelOlho,
  Select
} from './styles';
import { Radio } from '@components/Radio';

interface Props {
  data: SegmentoAnteriorDTO,
  handleChange: (values: SegmentoAnteriorDTO) => void;
}

export function SegmentoAnterior({
  data,
  handleChange,  
}: Props) {
  function handleChangeOlhoDireito(values) {
    const new_data = {...data, "OD": values};
    handleChange(new_data);
  }

  function handleChangeOlhoEsquerdo(values) {
    const new_data = {...data, "OE": values};
    handleChange(new_data);
  }

  function handleAtivar() {
    handleChange({...data, "ativarSegmentoAnterior": !data.ativarSegmentoAnterior});
  }

  if(!data) {
    data = {
      "ativarSegmentoAnterior": false, 
      "OD": {
        "palpebraEcilios": "normais",
        "conjuntivaPalpebral": "sem hiperemia",
        "conjuntivaBulbar": "sem hiperemia",
        "esclera": "branca",
        "cornea": "transparente",
        "camaraAnterior": "profunda",
        "angulo": "aberto",
        "iris": "normotrópica",
        "cristalino": "transparente",
        "vitreoAnterior": "sem células",
      },
      "OE": {
        "palpebraEcilios": "normais",
        "conjuntivaPalpebral": "sem hiperemia",
        "conjuntivaBulbar": "sem hiperemia",
        "esclera": "branca",
        "cornea": "transparente",
        "camaraAnterior": "profunda",
        "angulo": "aberto",
        "iris": "normotrópica",
        "cristalino": "transparente",
        "vitreoAnterior": "sem células",
      },
    };
  }

  return (
  <>
    <Radio label="Segmento anterior" status={data.ativarSegmentoAnterior} 
      onChange={handleAtivar}
    />
    { data.ativarSegmentoAnterior && 
      <Tabela>
        <Coluna>
          <Label className="ui_labels"></Label>
          <Label className="ui_labels">Pálp. e cílios</Label>
          <Label className="ui_labels">Conj. palpebral</Label>
          <Label className="ui_labels">Conj. bulbar</Label>
          <Label className="ui_labels">Esclera</Label>
          <Label className="ui_labels">Córnea</Label>
          <Label className="ui_labels">CA</Label>
          <Label className="ui_labels">Ângulo</Label>
          <Label className="ui_labels">Íris</Label>
          <Label className="ui_labels">Cristalino</Label>
          <Label className="ui_labels">Vítreo anterior</Label>
        </Coluna>
        <Coluna>
          <LabelOlho>OD</LabelOlho>
          <SegmentoAnteriorOlho 
            data={data && data.OD} 
            handleChange={handleChangeOlhoDireito}
          />
        </Coluna>
        <Coluna>
          <LabelOlho>OE</LabelOlho>
          <SegmentoAnteriorOlho 
            data={data && data.OE} 
            handleChange={handleChangeOlhoEsquerdo}
          />
        </Coluna>
      </Tabela>
    }        
  </>
  );
}