import { Draggable } from '@hello-pangea/dnd';
import { DraggableLinha, Posicao } from './styles';
import { Radio } from '@components/Radio';
import IconDrag from '@assets/drag_handle.png';

interface LenteProps {
  lente: {
    nome: string;
    indicada: boolean;
    posicao: number;
    id: string;
  };
  index: number;
  handleChange: (value) => void;
}

export function Lente({ lente, index, handleChange }: LenteProps) {
  return (
    <Draggable draggableId={lente.id} index={index}>
      {(provided) => (
      <DraggableLinha 
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <Radio label={lente.nome} status={true} onChange={() => handleChange(lente)} />
        <Posicao className="ui_labels_bold">{lente.posicao}</Posicao>
        <img src={IconDrag} />
      </DraggableLinha>
    )}
    </Draggable>
  )
}