import { useState } from 'react';
import { useConsulta } from '@hooks/useConsulta';
import { formatDuration } from '@utils/format';

import ImgCopy from '../../assets/new-header/botao_copy.svg';
import ImgCopied from '../../assets/new-header/botao_copy_copied.svg';
import { Container, Button } from './styles';
import { CirurgiaImplanteLioOlhoDTO, CirurgiaRefrativaOlhoDTO } from '@dtos/ConsultaDTO';
import { format } from 'date-fns';

export function BotaoCopiar() {
  const { paciente, receita, jornadas } = useConsulta();
  const [ copied, setCopied ] = useState(false);

  function cabecalho() {
    var text = paciente.nome ? paciente.nome : '';
    if(paciente.dataNascimento) {
      const idade = formatDuration(paciente.dataNascimento);
      text += ", " + idade;
    }
    text += "\n";
    const hoje = format(new Date(), 'dd/MM/yyyy');
    text += "Histórico de consulta em "+hoje+"\n\n";
    return text;
  }

  function preanamnese_text() {
    var text = "*PRÉ-ANAMNESE*\n";
    var div = document.createElement("div");
    if(paciente && paciente.anamneseMedica) {
      div.innerHTML = paciente.anamneseMedica.replace(/<br\s*[/]?>/gi, "\n");
    } else if(paciente && paciente.preAnamnese && paciente.preAnamnese.respostasConsolidadas) {
      div.innerHTML = paciente.preAnamnese.respostasConsolidadas.replace(/<br\s*[/]?>/gi, "\n"); // /<br\s*[\/]?>/gi      
    }
    var preanamnese = div.innerText;
    text += preanamnese + "\n\n";
    return text;
  }

  function receita_text() {
    if(receita.refracao) {
      var text = "*RECEITA DE LENTES*\n" + (receita.tipo ? receita.tipo+"\n" : "");
      if(receita.refracao.OE) {
        text += "".padEnd(6, " ") + "Esf".padEnd(10, " ") + "Cil".padEnd(10, " ") + "Eixo".padEnd(10, " ") + "Adição".padEnd(10, " ")+"\n";
        if(receita.refracao.OD) {
          text += "OD".padEnd(6, " ");
          text += (receita.refracao?.OD.esf ? receita.refracao?.OD.esf : "").padEnd(10, " ");
          text += (receita.refracao?.OD.cil ? receita.refracao?.OD.cil : "").padEnd(10, " ");
          text += (receita.refracao?.OD.eixo ? receita.refracao?.OD.eixo : "").padEnd(10, " ");
          text += (receita.refracao?.OD.adicao ? receita.refracao?.OD.adicao : "")+"\n";
        }
        if(receita.refracao.OE) {
          text += "OE".padEnd(6, " ");
          text += (receita.refracao?.OE.esf ? receita.refracao?.OE.esf : "").padEnd(10, " ");
          text += (receita.refracao?.OE.cil ? receita.refracao?.OE.cil : "").padEnd(10, " ");
          text += (receita.refracao?.OE.eixo ? receita.refracao?.OE.eixo : "").padEnd(10, " ");
          text += (receita.refracao?.OE.adicao ? receita.refracao?.OE.adicao : "")+"\n";
        }
        text += "\n";
        return text;
      }
    }
    return '';
  }

  function tratamentos_text() {
    if(paciente.dadosClinicos?.tratamentosRealizados?.ativarTratamentosRealizados) {
      var text = "*TRATAMENTOS REALIZADOS*\n";
      var lista = paciente.dadosClinicos.tratamentosRealizados.tratamentosRealizadosArray.map((value) => 
        value.descricao
      );
      return text + lista.join("\n") +"\n\n";
    }
    return '';
  }

  function acuidade_longe_text() {
    if(paciente.dadosClinicos?.acuidadeVisualDeLonge?.ativarAcuidadeVisualDeLonge) {
      var text = "*ACUIDADE VISUAL DE LONGE*\n";
      text += '      Sem correção      Correção anterior      Nova correção\n';
      if(paciente.dadosClinicos.acuidadeVisualDeLonge.OD) {
        const od = paciente.dadosClinicos.acuidadeVisualDeLonge.OD;
        text += "OD    "+(od.semCorrecao ? od.semCorrecao : "     ")+"             ";
        text += (od.correcaoAnterior ? od.correcaoAnterior : "     ")+"                  ";
        text += (od.novaCorrecao)+"\n";
      }
      if(paciente.dadosClinicos.acuidadeVisualDeLonge.OE) {
        const oe = paciente.dadosClinicos.acuidadeVisualDeLonge.OE;
        text += "OE    ";
        text += (oe.semCorrecao ? oe.semCorrecao : "     ")+"             ";
        text += (oe.correcaoAnterior ? oe.correcaoAnterior : "     ")+"                  ";
        text += oe.novaCorrecao+"\n";
      }
      if(paciente.dadosClinicos.acuidadeVisualDeLonge.AO) {
        const ao = paciente.dadosClinicos.acuidadeVisualDeLonge.AO;
        text += "AO    ";
        text += (ao.semCorrecao ? ao.semCorrecao : "     ")+"             ";
        text += (ao.correcaoAnterior ? ao.correcaoAnterior : "     ")+"                  ";
        text += ao.novaCorrecao+"\n";
      }
      return text+"\n";
    }
    return '';
  }

  function acuidade_perto_text() {
    if(paciente.dadosClinicos?.acuidadeVisualDePerto?.ativarAcuidadeVisualDePerto) {
      var text = "*ACUIDADE VISUAL DE PERTO*\n";
      text += '      Sem correção      Última correção      Nova correção\n';
      if(paciente.dadosClinicos.acuidadeVisualDePerto.OD) {
        const od = paciente.dadosClinicos.acuidadeVisualDePerto.OD;
        text += "OD    "+(od.semCorrecao ? od.semCorrecao : "  ")+"                ";
        text += (od.correcaoAnterior ? od.correcaoAnterior : "  ")+"                   ";
        text += od.novaCorrecao+"\n";
      }
      if(paciente.dadosClinicos.acuidadeVisualDePerto.OE) {
        const oe = paciente.dadosClinicos.acuidadeVisualDePerto.OE;
        text += "OE    "+(oe.semCorrecao ? oe.semCorrecao : "  ")+"                ";
        text += (oe.correcaoAnterior ? oe.correcaoAnterior : "  ")+"                   ";
        text += oe.novaCorrecao+"\n";
      }
      if(paciente.dadosClinicos.acuidadeVisualDePerto.AO) {
        const ao = paciente.dadosClinicos.acuidadeVisualDePerto.AO;
        text += "AO    "+(ao.semCorrecao ? ao.semCorrecao : "  ")+"                ";
        text += (ao.correcaoAnterior ? ao.correcaoAnterior : "  ")+"                   ";
        text += ao.novaCorrecao+"\n";
      }
      return text+"\n";
    }
    return '';
  }

  function pressao_intraocular_text() {
    var text = '';    
    if(paciente.dadosClinicos?.pressaoIntraocular?.ativarPressaoIntraocular) {
      text = "*PRESSÃO INTRAOCULAR*\n";
      if(paciente.dadosClinicos.pressaoIntraocular.OD.mmHg) {
        text += "OD    "+paciente.dadosClinicos.pressaoIntraocular.OD.mmHg+"mmHg\n";
      }
      if(paciente.dadosClinicos.pressaoIntraocular.OE.mmHg) {
        text += "OE    "+paciente.dadosClinicos.pressaoIntraocular.OE.mmHg+"mmHg\n";
      }
      text += "\n";
    }
    return text;
  }

  function segmento_anterior_text() {
    var text = ''; 
    if(paciente.dadosClinicos?.segmentoAnterior?.ativarSegmentoAnterior) {
      var od = paciente.dadosClinicos?.segmentoAnterior.OD;
      var oe = paciente.dadosClinicos?.segmentoAnterior.OE;
      text = "*SEGMENTO ANTERIOR*\n";
      text += "".padEnd(20, " ")+"OD".padEnd(20, " ")+"OE".padEnd(20, " ")+"\n";
      text += "Pálp. e cílios".padEnd(20, " ") +
        od.palpebraEcilios.padEnd(20," ") +
        oe.palpebraEcilios.padEnd(20," ") + "\n";
      text += "Conj. palpebral".padEnd(20, " ") + 
        od.conjuntivaPalpebral.padEnd(20," ") +
        oe.conjuntivaPalpebral.padEnd(20," ") + "\n";
      text += "Conj. bulbar".padEnd(20, " ") + 
        od.conjuntivaBulbar.padEnd(20," ") +
        oe.conjuntivaBulbar.padEnd(20," ") + "\n";
      text += "Esclera".padEnd(20, " ") + 
        od.esclera.padEnd(20," ") +
        oe.esclera.padEnd(20," ") + "\n";
      text += "Córnea".padEnd(20, " ") +
        od.cornea.padEnd(20," ") +
        oe.cornea.padEnd(20," ") + "\n";        
      text += "CA".padEnd(20, " ") +
        od.camaraAnterior.padEnd(20," ") +
        oe.camaraAnterior.padEnd(20," ") + "\n";
      text += "Ângulo".padEnd(20, " ") +
        od.angulo.padEnd(20," ") +
        oe.angulo.padEnd(20," ") + "\n";
      text += "Íris".padEnd(20, " ") +
        od.iris.padEnd(20," ") +
        oe.iris.padEnd(20," ") + "\n";
      text += "Cristalino".padEnd(20, " ") +
        od.cristalino.padEnd(20," ") +
        oe.cristalino.padEnd(20," ") + "\n";
      text += "Vítreo anterior".padEnd(20, " ") +
        od.vitreoAnterior.padEnd(20," ") +
        oe.vitreoAnterior.padEnd(20," ") + "\n";
      text += "\n";
    }
    return text;
  }

  function fundo_de_olho_text() {
    var text = '';    
    if(paciente.dadosClinicos?.fundoDeOlho?.ativarFundoDeOlho) {
      text = "*FUNDO DE OLHO*\n";
      if(paciente.dadosClinicos.fundoDeOlho.OD && paciente.dadosClinicos.fundoDeOlho.OE) {
        const od = paciente.dadosClinicos.fundoDeOlho.OD;
        const oe = paciente.dadosClinicos.fundoDeOlho.OE;
        text += "Nervo ótico".padEnd(20, " ")+"OD".padEnd(20, " ")+"OE".padEnd(20, " ")+"\n";
        text += "Esc/Disco V".padEnd(20, " ") +
          od.escDiscoV.padEnd(20," ") +
          oe.escDiscoV.padEnd(20," ") + "\n";
        text += "Esc/Disco H".padEnd(20, " ") +
          od.escDiscoH.padEnd(20," ") +
          oe.escDiscoH.padEnd(20," ") + "\n";
        text += "Coloração".padEnd(20, " ") +
          od.coloracao.padEnd(20," ") +
          oe.coloracao.padEnd(20," ") + "\n";
        text += "Margens".padEnd(20, " ") +
          od.margens.padEnd(20," ") +
          oe.margens.padEnd(20," ") + "\n";
        text += "Rima neural".padEnd(20, " ") +
          od.rimaNeural.padEnd(20," ") +
          oe.rimaNeural.padEnd(20," ") + "\n";
        text += "Retina".padEnd(20, " ")+"OD".padEnd(20, " ")+"OE".padEnd(20, " ")+"\n";        
        text += "Mácula".padEnd(20, " ") +
          od.macula.padEnd(20," ") +
          oe.macula.padEnd(20," ") + "\n";
        text += "Pólo posterior".padEnd(20, " ") +
          od.poloPosterior.padEnd(20," ") +
          oe.poloPosterior.padEnd(20," ") + "\n";
        text += "Vasos".padEnd(20, " ") +
          od.vasos.padEnd(20," ") +
          oe.vasos.padEnd(20," ") + "\n";
        text += "Periferia".padEnd(20, " ") +
          od.periferia.padEnd(20," ") +
          oe.periferia.padEnd(20," ") + "\n";
        text += "Vítreo".padEnd(20, " ") +
          od.vitreo.padEnd(20," ") +
          oe.vitreo.padEnd(20," ") + "\n";
      }
      text += "\n";
    }
    return text;
  }

  function diagnosticos_text() {
    var text = "*DIAGNÓSTICOS*\n";
    if(paciente.dadosClinicos?.diagnosticos?.ativarDiagnosticos) {
      var diagnosticos_str = paciente.dadosClinicos.diagnosticos.diagnosticosArray.map((cid) => "["+cid.codigo+"] "+cid.descricao);
      return text + diagnosticos_str.join("\n") + '\n\n';
    }
    return '';
  }

  function cirurgia_lio_olho(olho: CirurgiaImplanteLioOlhoDTO) {
    var text = "Indicação: "+olho.indicacao + "\n";
    if(olho.tiposDeLentes.length > 0) {
      text += "Tipos de lente: " + olho.tiposDeLentes.map((lente) => {
        return lente.nome;
      }).filter(Boolean).join(", ")+"\n";
    }
    if(olho.alvoMonofocal)
      text += 'Alvo: '+olho.alvoMonofocal + "\n";
    if(olho.torica)
      text += 'Tórica' + "\n";
    if(olho.tecnologias) {
      text += "Tecnologias indicadas: " + olho.tecnologias.map((tec) => {
        return tec.nome+"("+tec.marca +")";
      }).join(", ")+"\n";
    }
    text += "\n";
    return text;
  }

  function cirurgia_lio() {
    var text = '';    
    if(jornadas.cirurgiaImplanteLIO) {
      text = "*CIRURGIA DE IMPLANTE DE LIO*\n";
      if(jornadas.cirurgiaImplanteLIO.OD) {
        const od = jornadas.cirurgiaImplanteLIO.OD;
        text += "OD\n"
        text += cirurgia_lio_olho(od);
        //text = od.tecnologias + "\n";
      }
      if(jornadas.cirurgiaImplanteLIO.OE) {
        const oe = jornadas.cirurgiaImplanteLIO.OE;
        text += "OE\n"
        text += cirurgia_lio_olho(oe);
      }
      const recursos =[];
      if(jornadas.cirurgiaImplanteLIO.recursosIntraoperatorios.femtoFlacs)
        recursos.push("Femto (FLACS)");
      if(jornadas.cirurgiaImplanteLIO.recursosIntraoperatorios.guiadoPorImagem)
        recursos.push("Guiado por imagem");
      if(jornadas.cirurgiaImplanteLIO.recursosIntraoperatorios.aberrometria)
        recursos.push("Aberrometria");
      if(recursos.length > 0) {
        text += "Recursos intraoperatórios\n"+recursos.join(", ")+"\n";
      }
      text += "\n";
    }
    return text;
  }

  function cirurgia_refrativa() {
    var text = '';    
    if(jornadas.cirurgiaRefrativa) {
      text = "*CIRURGIA REFRATIVA*\n";
      if(jornadas.cirurgiaRefrativa.OD){
        const od = jornadas.cirurgiaRefrativa.OD;
        text += "OD\n"
        text += cirurgia_refrativa_olho(od);
      }
      if(jornadas.cirurgiaRefrativa.OE) {
        const oe = jornadas.cirurgiaRefrativa.OE;
        text += "OE\n"
        text += cirurgia_refrativa_olho(oe);
      }
    }
    return text;
  }

  function cirurgia_refrativa_olho(olho: CirurgiaRefrativaOlhoDTO) {
    var text = 'Indicação: '+olho.indicacao+'\n';
    text += 'Técnicas: '+olho.tecnicas.map((tecnica) => {
      return tecnica.nome;
    }).filter(Boolean).join(", ")+"\n";
    const tratamentoGuiado = [];
    if(olho.tratamentoGuiado.topografia) {
      tratamentoGuiado.push("Topografia");
    }
    if(olho.tratamentoGuiado.frenteDeOnda) {
      tratamentoGuiado.push("Frente de onda");
    }
    if(tratamentoGuiado.length > 0) {
      text += 'Tratamento Guiado: '+tratamentoGuiado.join(", ");
    }
    text += "\n";
    return text;
  }

  function handleCopiar() {
    var text = cabecalho() + preanamnese_text() + receita_text();
    text += tratamentos_text();
    text += acuidade_longe_text();
    text += acuidade_perto_text();
    text += pressao_intraocular_text();
    text += segmento_anterior_text();
    text += fundo_de_olho_text();
    text += diagnosticos_text();
    text += cirurgia_lio();
    text += cirurgia_refrativa();

    navigator.clipboard.writeText(text).then(function() {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000)
    
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  return (
  <Container>
    {copied 
    ?
    <Button>
      <img src={ImgCopied} alt="Copiado"/>
    </Button>
    : 
    <Button onClick={handleCopiar}>
      <img src={ImgCopy} alt="Copiar"/>
    </Button>
    }
  </Container>
  );
}