import {
  Container,
  Opcao,
} from './styles';

type TriplaOpcaoProps = {
  selected: string;
  options: {
    value: string;
    label: string;
  }[];
  onClick: (value: any) => void;
}

export const TriplaOpcao = ({selected, options, onClick}: TriplaOpcaoProps) => {
  return (
    <Container>
      {options.map((option) => 
        <Opcao 
          className={selected === option.value ? "ui_labels_bold" : "ui_labels"} 
          selected={selected === option.value} 
          onClick={() => onClick(option.value)}
          key={option.value}
        >
          {option.label}
        </Opcao>

      )}
    </Container>
  );
}