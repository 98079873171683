import { useState, useEffect, useMemo, useCallback } from "react";
import {useNavigate} from 'react-router-dom';

import { format } from "date-fns";
import { ptBR } from "date-fns/locale/pt-BR";

import {HeaderDashboard} from '../../components/HeaderDashboard';
import { api } from '../../services/api';
import { AppError } from '../../utils/AppError';

import IconToday from "../../assets/icon_today.svg";
import BtnNovaConsulta from "../../assets/btn_nova_consulta.svg";

import BtnIniciarConsulta from "../../assets/btn_iniciar_consulta.svg";
import BtnAcessarConsulta from "@assets/botao_acessar_consulta.svg";

import { TbLoader } from "react-icons/tb";

import { 
  Container, 
  ConsultasContainer,
  Title,
  Data,
  LinkNovaConsulta,
  AgendaList,
  AgendaLinha,
  AgendaHora,
  AgendaIcon,
  AgendaPaciente,
  ConsultasNaData,
  BtnIniciar,
  Loader,
  Spacer,
  BotaoContainer
} from './styles';
import { toast } from "react-toastify";
import { getStatusIcon } from "@utils/consultaHelper";

export const Home = () => {

  const [date, setDate] = useState(new Date());
  const [selectedId, setSelectedId] = useState('');
  const [clinicaId, setClinicaId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const navigate = useNavigate();


  const dateFormatted = useMemo(
    () => format(date, "d 'de' MMMM 'de' y", { locale: ptBR }),
    [date]
  );

  const loadResponseToPageData = useCallback((response) => {
    const data = response.data["proximasConsultas"].map((consulta) => {
      var iconImage = getStatusIcon(consulta.etapa);
      return {
        id: consulta["id"],
        hora: format(new Date(consulta["horario"]), 'HH:mm'),
        icon: <img src={iconImage} alt={consulta.etapa}/>,
        etapa: consulta.etapa,
        paciente: consulta.paciente,
      };
    });

    setSchedule(data);
    setSelectedId('');
    setSelectedStatus('');
    setClinicaId(response.data.clinicaSelecionada);
  }, []);

  useEffect(() => {
    //encerraConsulta();
    setDate(new Date());

    async function loadSchedule() {
      try {
        setLoading(true);
        const response = await api.get("consultas");
        loadResponseToPageData(response);
      } catch(error) {
        console.log(error);
        const isAppError = error instanceof AppError;    
        const title = isAppError ? error.message : 'Não foi possível entrar. Tente novamente mais tarde.';
        toast.error(title);
      } finally {
        setLoading(false);
      }
    }

    loadSchedule();
    const interval = setInterval(() => loadSchedule(), 30000); // repeat every 30 seconds
    return () => clearInterval(interval);
  }, []);

  function handleIniciarConsulta() {
    navigate("/iniciar/"+selectedId);
  }

  function handleAcessarConsulta() {
    navigate("/consulta/"+selectedId);
  }

  function handleAgendaLinhaClick(time) {
    setSelectedId(time.id);
    setSelectedStatus(time.etapa);
  }

  function handleNovaConsulta() {
    navigate("/consulta");
  }

  async function handleClinicaChange(clinica_id: string) {
    try {
      setLoading(true);
      if(clinica_id) {
        const response = await api.post("clinica", {"switchTo" : clinica_id});
        loadResponseToPageData(response);
      }
    } catch(error) {

    } finally {
      setLoading(false);
    }  
  }

  const pode_iniciar = ["not_started", "3_scheduled", "2_checked-in"];
  const pode_acessar = [...pode_iniciar, "1_in_appointment", "4_following-up"];
  const show_iniciar = selectedId !== "" && pode_iniciar.includes(selectedStatus);
  const show_acessar = selectedId !== "" && pode_acessar.includes(selectedStatus);

  return (
  <Container>
    <Spacer>
      <HeaderDashboard handleClinicaChange={handleClinicaChange} selected_clinica={clinicaId} />
    </Spacer>
    <ConsultasContainer>
      <Title>Consultas</Title>

      <ConsultasNaData>
        <Data className="ui_labels">
          <img src={IconToday} alt="today icon"/>&nbsp; Hoje, {dateFormatted} 
        </Data>

        <AgendaList>
          <LinkNovaConsulta className="ui_labels" onClick={handleNovaConsulta}><img src={BtnNovaConsulta} alt="BtnNovaConsulta"/></LinkNovaConsulta>
          {loading ? 
            <Loader>
              <TbLoader />
            </Loader>
          :
          schedule.map(time => (
            <AgendaLinha key={time.id} onClick={() => handleAgendaLinhaClick(time)}
              selected={selectedId === time.id}>
              <AgendaHora className="ui_labels">{time.hora}</AgendaHora>
              <AgendaIcon>{time.icon}</AgendaIcon>
              <AgendaPaciente className="ui_labels">{time.paciente}</AgendaPaciente>
            </AgendaLinha>
          ))}
        </AgendaList>
      </ConsultasNaData>
      <BotaoContainer>
      {show_acessar &&
        <BtnIniciar onClick={handleAcessarConsulta}>
          <img src={BtnAcessarConsulta} alt="Acessar Consulta" style={{ width: '100%'}}/>
        </BtnIniciar>
      }
      {show_iniciar &&
        <BtnIniciar onClick={handleIniciarConsulta}>
          <img src={BtnIniciarConsulta} alt="Iniciar Consulta" style={{ width: '100%'}}/>
        </BtnIniciar>
      }
      </BotaoContainer>
    </ConsultasContainer>
  </Container>);
};