import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-width: 430px;
    max-width: 430px;
    background: #201E23;
    margin: 0 auto;    
    /*    min-width: 340px;
    max-width: 340px;*/
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 14px 'Inter', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    border: none;
    background: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  img {
    
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .ui_labels {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .ui_mini_labels {
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .ui_labels_bold {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  h3, .h3 {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  p, .paragraph {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .ql-toolbar.ql-snow {
    border-radius: 20px 20px 0 0;
  }

  .ql-container.ql-snow {
    border-radius: 0 0 20px 20px;
  }  
`;