import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
  overflow: hidden;

  & :first-child {
    border-radius: 10px 0 0 10px;
    border-right: none;
  }

  & :not(:last-child) {
    border-right: none;
  }

  & :last-child {
    border-radius: 0 10px 10px 0;
    border-left: none;
  }

  & .ui_labels + .ui_labels {
    border-left: 2px solid #E6D4E6;
  }

  & .ui_labels_bold + .ui_labels {
    border-left: 0;
  }
`;

export const Opcao = styled.div`
  border: 2px solid ${(props) => props.selected ? '#35155C' : '#E6D4E6'};
  background: ${(props) => props.selected ? '#35155C' : '#FFFFFF'};
  color: ${(props) => props.selected ? '#FFFFFF' : '#000000'};

  font-size: 14px;
  font-weight: 500;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

`;
