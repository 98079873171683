import styled from 'styled-components'

export const Container = styled.div`
  background: #FFFFFFC7;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);

  position: fixed;
  width: 430px;
  padding: 0 20px;
  z-index: 9;

  left: 50%;
  top: 0%;
  transform: translateX(-50%);  
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 72px;
  width: 100%;
  padding: 0 0 0 0px;
`;

export const Background = styled.img`
  position: absolute;
  z-index: 0;
  opacity: 0.5;
  top: 0;
  left: 0;
  border: 1px solid blue;
  width: 430px;
`;

export const BotaoVoltarContainer = styled.button`
  border: none;
  background: transparent;
`;

export const BotaoCopiar = styled.button`
  border: none;
`;

export const BotaoImage = styled.img`
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  flex: 1;
`;

export const StatusContainer = styled.div`
  font-weight: 500;
  font-size: 0.8rem;  
  color: #BFB3AF;
`;

export const Botoes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-direction: row ;
  align-items: center;
`;

export const ButtonAIActions = styled.button`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const EtapaStatusIcon = styled.img`
  height: 20px;
`;

export const Nome = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  display: flex; 
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
  justify-content: space-between;
`;

export const BotaoColar = styled.button`
  border: none;
  border-radius: 20px;
`;

export const Button = styled.button`
  display: flex;
`;