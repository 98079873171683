import styled from 'styled-components'

export const Tabela = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
`;

export const Coluna = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;

export const Label = styled.div`
  color: #BFB3AF;
  height: 18px;
  text-align: right;
  white-space: nowrap;
`;

export const LabelOlho = styled.h3`
  color: #BFB3AF;
  height: 18px;
  text-align: center;
`;

export const Select = styled.select`
  width: 109px;
  height: 18px;
  border: none;
  text-align: center;
`;

export const Input = styled.input`
  width: 109px;
  height: 18px;
  padding: 2px;
  border: none;
  border: ${props => props.error === 'true' ? "2px inset #C71173" : "none"};
  border-radius: 5px;
  text-align: center;

  &:focus {
    border: 2px inset #EBE9ED;
    border:default;
    border-radius: 5px;
    border-color: #e0e0e0;
  }
`;
