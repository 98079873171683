import styled from 'styled-components'

export const Content = styled.div`
`;

export const Label = styled.div` 
  color: #BFB3AF;
  margin-bottom: 6px;
  white-space: nowrap;
`;

export const Nome = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000;
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Campo = styled.div.attrs(({ $error }): any => ({
  error: $error || "false",
}))`
  width: 100%;
`;
