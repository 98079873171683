import styled from 'styled-components'
import InputMask from "react-input-mask";

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

export const Valor = styled.div`
  width: 100%;
  padding: 3px;
  display: flex;
  min-height: 23px;
`;

export const DateInputMask = styled(InputMask)`
  padding: 2px;
  border-radius: 5px;
  border-color: #e0e0e0;
  border: none;
  border: ${props => props.$error === 'true' ? "2px inset #C71173" : "none"};

  &:focus {
    border: 2px inset #EBE9ED;
    border:default;
    border-radius: 5px;
    border-color: #e0e0e0;
  }  
`;

export const Input = styled.input.attrs<{ $error?: string; }>(props => ({
  $error: props.$error || "false",
}))`
  padding: 2px;
  border: none;
  width: ${props => (props.error === 'true') ? '90% !important' : '100%'};
  border: ${props => props.error === 'true' ? "2px inset #C71173" : "none"};
  border-radius: 5px;

  &:focus {
    border: 2px inset #EBE9ED;
    border:default;
    border-radius: 5px;
    border-color: #e0e0e0;
  }  
`;

export const Texto = styled.span`
  font-size: 14px;
  white-space: nowrap;
  display: ${props => props.is_visible ? "block" : "none"};
`;

export const Imagem = styled.img`
  margin-left: 5px;
`;
