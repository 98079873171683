import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { useConsulta } from '@hooks/useConsulta';
import { BotaoBuscarNaPagina } from '@components/BotaoBuscarNaPagina';
import { BotaoDitar } from '../../components/BotaoDitar';
import { BotaoColar } from '../../components/BotaoColar';
import { BotaoCopiar } from '../../components/BotaoCopiar';
import { formatDuration } from '@utils/format';

import TabAIInactive from '../../assets/new-header/tab_AI_inactive.svg';
import TabAIActive from '../../assets/new-header/tab_AI_active.svg';
import BtnOptions from '../../assets/new-header/botao_options.svg';
import ImgVoltar from '../../assets/new-header/botao_voltar.svg';

import {
  Container,
  TopContainer,
  BotaoVoltarContainer,
  BotaoImage,
  MainContent,
  StatusContainer,
  Nome,
  ActionsContainer,
  Botoes,
  ButtonAIActions,
  Button
} from './styles';
import {BrowserView, MobileView} from 'react-device-detect';
import { BotaoCapturar } from "@components/BotaoCapturar";

export function HeaderConsulta({ id, transicaoOpenClose }) {
  const { paciente } = useConsulta();
  const navigate = useNavigate();
  const [ openAIActions, setOpenAIActions ] = useState(false);

  const [ loadingBuscar, setLoadingBuscar ] = useState(false);
  const [ loadingColar, setLoadingColar ] = useState(false);
  const [ loadingFalar, setLoadingFalar ] = useState(false);
  const inExtension = window.chrome && chrome.runtime && chrome.runtime.id;

  useEffect(()=> {
    if(openAIActions) {
      document.getElementById("header").parentElement.style["padding-top"] = '124px';//document.getElementById("header").offsetHeight +'px';
    } else {
      document.getElementById("header").parentElement.style["padding-top"] = '90px';
    }
  }, [openAIActions]);

  function handleOpenAIClick() {
    setOpenAIActions(!openAIActions);
  }

  function handleVoltar() {
    navigate("/");
  }

  return (
  <Container id="header">    
    <TopContainer>
      <BotaoVoltarContainer onClick={handleVoltar}>
        <BotaoImage src={ImgVoltar} alt="Voltar"/>
      </BotaoVoltarContainer>
      <MainContent>
        <Nome className="ui_labels">{paciente.nome ? paciente.nome : "-"}</Nome>
        <StatusContainer className="ui_labels">
          {paciente.dataNascimento ? formatDuration(paciente.dataNascimento) : "-"} · {paciente.whatsapp ? paciente.whatsapp : "-"}
        </StatusContainer>
      </MainContent>
      <Botoes>
        <ButtonAIActions onClick={handleOpenAIClick}>
          <img src={openAIActions ? TabAIActive : TabAIInactive} alt="Mostrar Botões de AI" />
        </ButtonAIActions>
        <BotaoCopiar />
        <Button onClick={transicaoOpenClose}>
          <img src={BtnOptions} alt="Transição de Jornada"/>
        </Button>
      </Botoes>
    </TopContainer>
    { openAIActions &&
    <ActionsContainer>
      { inExtension ?
        <BotaoBuscarNaPagina 
          disabled={loadingColar || loadingFalar} 
          loading={loadingBuscar} 
          changeLoading={(status) => setLoadingBuscar(status)} 
        />
        :
        <BotaoCapturar
          disabled={loadingColar || loadingFalar}  
        />
      }
      <BotaoColar 
        disabled={loadingBuscar || loadingFalar} 
        loading={loadingColar} 
        changeLoading={(status) => setLoadingColar(status)} 
      />
      <BotaoDitar 
        disabled={loadingColar || loadingBuscar} 
        loading={loadingFalar} 
        changeLoading={(status) => setLoadingFalar(status)} 
      />
    </ActionsContainer>
    }
  </Container>
  );
}