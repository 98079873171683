import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  z-index: 1;
`;

export const SelectContainer = styled.div`
  border-color: #BFB3AF;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  transition: all 100ms;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 2px;
`;

export const InputContainer = styled.div`
  position: relative;
  flex: 1;
`;

export const Input = styled.input<{ mode: string }>`
  flex: 1;
  border: none;
  padding: 2px 8px;
  background: transparent;
  color: ${props => props.mode === 'dark' ? '#fff' : '#000'};
`;

export const Options = styled.div`
  border-color: #BFB3AF;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 10px;
  min-width: 100%;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  flex: 1;
`;

export const OptionsContainer = styled.div`
  margin-top: 6px;
  position: absolute;
  z-index: 2;
  min-width: 100%;
`;

export const Option = styled.button`
  padding: 2px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 24px;
  gap: 5px;
  width: 100%;
  &:hover {
    background-color: #BFB3AF;
    .ui_mini_labels {
      color: #fff;
    }
  }
`;

export const Descricao = styled.div`
  width: 100%;
  border-radius: 4px;
  opacity: 0px;
  background: #BFB3AFB2;
  color: #FFF;
  padding: 7px 10px;
  backdrop-filter: blur(35px);
  margin-top: 4px;
`;

export const OptionSubLabel = styled.div`
  color: #BFB3AF;
`;

export const IconDown = styled.div`
  color: #BFB3AF;
  border-left: 1px solid #BFB3AF;
  margin: 2px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;