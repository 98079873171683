import { useEffect, useState } from "react";
import { ConsultaBloco } from "@components/ConsultaBloco";
import {
  Content,
  Label,
  Linha, 
  Campo
} from './styles';
import { Editable } from "@components/Editable";
import { getStatusIcon } from "@utils/consultaHelper";
import { useConsulta } from "@hooks/useConsulta";
import { format } from "date-fns";
import { validaDataConsulta, validaHora } from "@utils/validacao";

export function InformacoesConsulta() {
  const [etapa, setEtapa] = useState('');

  const [date, setDate] = useState('');
  const [dateError, setDateError] = useState(false);

  const [hour, setHour] = useState('');
  const [hourError, setHourError] = useState(false);


  const { consultaData, validarInfo, infoPronto } = useConsulta();

  useEffect(() => {
    if(consultaData?.payload?.situacao) {
      setEtapa(consultaData.payload.situacao.etapa);

      var date = consultaData.payload.situacao.agendadaPara.substring(0,19)
      const date_utc = new Date(date);
      var new_date = format(date_utc, 'dd/MM/yyyy');
      var new_hour = format(date_utc, 'HH:mm');
      setDate(new_date);
      setHour(new_hour)

      validarInfo(new_date, new_hour);
    }    
  }, [consultaData]);

  function handleBlurHour() {
    setHourError(!validaHora(hour));
    validarInfo(date, hour);
  }

  function handleBlurDate() {
    setDateError(!validaDataConsulta(date));
    validarInfo(date, hour);
  }

  function handleBlurContent(e) {
    if (
      e.relatedTarget !== e.currentTarget &&
      !e.currentTarget.contains(e.relatedTarget)
    ) {
      validarInfo(date, hour);
    }
  }

  return (
  <ConsultaBloco text="Informações da consulta" pronto={infoPronto}>
    <Content onBlur={handleBlurContent}>
      <Linha>
        <Campo>
          <Label className="ui_mini_labels">
            Etapa
          </Label>
          {etapa && <img src={getStatusIcon(etapa)} /> }
        </Campo>
        <Campo>
          <Label className="ui_mini_labels">
            Data
          </Label>
          <Editable
            value={date} 
            onChange={(e) => { 
              setDate(e.target.value)
            }}
            mask="99/99/9999"
            onBlur={handleBlurDate}
            error={dateError.toString()}
            minwidth={90}
            inputMode="numeric"
          />
        </Campo>
        <Campo>
          <Label className="ui_mini_labels">
            Horário
          </Label>
          <Editable
            value={hour} 
            onChange={(e) => { 
              setHour(e.target.value)
            }}
            mask="99:99"
            onBlur={handleBlurHour}
            error={hourError.toString()}
            minwidth={90}
            inputMode="numeric"
          />
        </Campo>
      </Linha>
    </Content>
  </ConsultaBloco>
  );
}