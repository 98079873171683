import SwitchOn from '../../assets/switch_on.png';
import SwitchOff from '../../assets/switch_off.png';

import { 
  Container,
  Image,
  Label
} from './styles';

type RadioProps = {
  label: string;
  status: boolean;
  onChange: (status: boolean) => void;
}

export function Radio({ label, status = false, onChange } : RadioProps) {
  return (
    <Container onClick={() => onChange(!status)}>
      <Image src={status ? SwitchOn : SwitchOff} alt="on/off"/>
      <Label className={status ? "ui_labels_bold" : "ui_labels"}>{label}</Label>
    </Container>
  );
}
