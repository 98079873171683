import { ListaTexto } from "@components/ListaTexto";
import { Radio } from "@components/Radio";
import { MedicamentosEmUsoDTO } from "@dtos/ConsultaDTO";

interface MedicamentosEmUsoProps {
  data: MedicamentosEmUsoDTO,
  handleChange: (values: MedicamentosEmUsoDTO) => void;
}

export function MedicamentosEmUso({ data, handleChange }: MedicamentosEmUsoProps) {
  function handleAtivar() {
    handleChange({...data, "ativarMedicamentosEmUso": !data.ativarMedicamentosEmUso});
  }

  function handleChangeMedicamentosEmUso(values) {
    handleChange({...data, "medicamentosEmUsoArray": values});
  }

  if(!data) {
    data = {"ativarMedicamentosEmUso": false, "medicamentosEmUsoArray": []};
  }

  return (
  <>
    <Radio label="Medicamentos em uso" 
      status={data.ativarMedicamentosEmUso} 
      onChange={handleAtivar}
    />
    { data.ativarMedicamentosEmUso && 
      <ListaTexto 
        items={data.medicamentosEmUsoArray} 
        adicionar_texto="Adicionar medicamento" 
        handleChange={handleChangeMedicamentosEmUso}
      />
    }
  </>
  );
}
