import { ListaTexto } from "@components/ListaTexto";
import { Radio } from "@components/Radio";
import { TratamentosRealizadosDTO } from "@dtos/ConsultaDTO";

interface TratamentosRealizadosProps {
  data: TratamentosRealizadosDTO,
  handleChange: (values: TratamentosRealizadosDTO) => void;
}

export function TratamentosRealizados({ data, handleChange }: TratamentosRealizadosProps) {
  function handleAtivar() {
    handleChange({...data, "ativarTratamentosRealizados": !data.ativarTratamentosRealizados});
  }

  function handleChangeTratamentosRealizados(values) {
    handleChange({...data, "tratamentosRealizadosArray": values});
  }

  if(!data) {
    data = {"ativarTratamentosRealizados": false, "tratamentosRealizadosArray": []};
  }

  return (
  <>
    <Radio label="Tratamentos realizados" 
      status={data.ativarTratamentosRealizados} 
      onChange={handleAtivar}
    />
    { data.ativarTratamentosRealizados && 
      <ListaTexto 
        items={data.tratamentosRealizadosArray} 
        adicionar_texto="Adicionar tratamento realizado" 
        handleChange={handleChangeTratamentosRealizados}
      />
    }
  </>
  );
}
