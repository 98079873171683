import { useState, useEffect, useRef } from "react";
import { Radio } from "@components/Radio";
import { CirurgiaImplanteLioDTO, CirurgiaImplanteLioOlhoDTO } from '@dtos/ConsultaDTO';
import { CirurgiaImplanteLIOOlho } from './olho';
import { monofocalExists } from '@utils/consultaHelper';
import {
  Container,
  Linha,
  Content
} from './styles';
import { useConsulta } from "@hooks/useConsulta";

interface Props {
  data: CirurgiaImplanteLioDTO
}

export const CirurgiaImplanteLIO = ({data} : Props) => {
  const [aberrometria, setAberrometria] = useState(false);
  const [femtoFlacs, setFemtoFlacs] = useState(false);
  const [guiadoPorImagem, setGuiadoPorImagem] = useState(false);
  const [pronto, setPronto] = useState(false);

  const { jornadas, alteraCirurgiaImplanteLIO, alteradoPorAi } = useConsulta();

  useEffect(() => {
    setPronto(valida())
  }, [jornadas]);

  function changeOlhoDireito(data: CirurgiaImplanteLioOlhoDTO) {
    var data_lio = jornadas.cirurgiaImplanteLIO;
    data_lio = { ...data_lio, OD: data};
    alteraCirurgiaImplanteLIO(data_lio);
  }

  function changeOlhoEsquerdo(data: CirurgiaImplanteLioOlhoDTO) {
    var data_lio = jornadas.cirurgiaImplanteLIO;
    data_lio = { ...data_lio, OE: data};
    alteraCirurgiaImplanteLIO(data_lio);
  }

  function changeAberrometria() {
    var data_lio = jornadas.cirurgiaImplanteLIO;
    if(!data_lio.recursosIntraoperatorios) {
      data.recursosIntraoperatorios = {
        aberrometria: false,
        guiadoPorImagem: false,
        femtoFlacs: false
      }
    }
    data_lio.recursosIntraoperatorios.aberrometria = !data.recursosIntraoperatorios?.aberrometria;
    alteraCirurgiaImplanteLIO(data_lio);
  }

  function changeFemtoFlacs() {
    var data_lio = jornadas.cirurgiaImplanteLIO;
    if(!data_lio.recursosIntraoperatorios) {
      data.recursosIntraoperatorios = {
        aberrometria: false,
        guiadoPorImagem: false,
        femtoFlacs: false
      }
    }
    data_lio.recursosIntraoperatorios.femtoFlacs = !data.recursosIntraoperatorios?.femtoFlacs;
    alteraCirurgiaImplanteLIO(data_lio);
  }

  function changeGuiadoPorImagem() {
    var data_lio = jornadas.cirurgiaImplanteLIO;
    if(!data_lio.recursosIntraoperatorios) {
      data.recursosIntraoperatorios = {
        aberrometria: false,
        guiadoPorImagem: false,
        femtoFlacs: false
      }
    }
    data_lio.recursosIntraoperatorios.guiadoPorImagem = !data.recursosIntraoperatorios?.guiadoPorImagem;
    alteraCirurgiaImplanteLIO(data_lio);
  }

  function valida() {
    if(!data.OD || !data.OE) {
      return false
    }
    if(!data.OD.indicacao && !data.OE.indicacao) {
      return false
    }
    if(data.OD.indicacao === "Catarata" || data.OD.indicacao === "Facorrefrativa") {
      if(data.OD.tiposDeLentes?.length === 0) {
        return false;
      }
    }
    const monofocal = monofocalExists(data.OD.tiposDeLentes);
    if(monofocal) {
      if(!data.OD.alvoMonofocal) {
        return false;
      }
    }
    return true
  }

  function handleReplicar() {
    changeOlhoEsquerdo(jornadas.cirurgiaImplanteLIO.OD);
  }

  return (
  <Container text="Cirurgia de implante de LIO" mode="dark" pronto={pronto} ai={alteradoPorAi.cirurgiaImplanteLio}>
    <CirurgiaImplanteLIOOlho 
      data={jornadas.cirurgiaImplanteLIO?.OD} 
      title="Olho Direito" 
      handleChange={changeOlhoDireito}
      />

    <CirurgiaImplanteLIOOlho 
      data={jornadas.cirurgiaImplanteLIO?.OE} 
      title="Olho Esquerdo" 
      handleChange={changeOlhoEsquerdo} 
      replicar={true}
      handleReplicar={handleReplicar}
    />
    <Content>
      <h3 style={{color: '#BFB3AF'}}>Recursos intraoperatórios</h3>
      <Linha>
        <Radio label="Femto (FLACS)" status={data.recursosIntraoperatorios?.femtoFlacs} onChange={changeFemtoFlacs}/>
        <Radio label="Guiado por imagem" status={data.recursosIntraoperatorios?.guiadoPorImagem} onChange={changeGuiadoPorImagem}/>
      </Linha>
      <Linha>
        <Radio label="Aberrometria" status={data.recursosIntraoperatorios?.aberrometria} onChange={changeAberrometria}/>
      </Linha>
    </Content>
  </Container>
  );
}