import { useState, useEffect } from "react";

import { Loading } from '@components/Loading';
import { useConsulta } from '@hooks/useConsulta';
import { 
  verificaSeMiopia, 
  verificaSeHipermetropia, 
  verificaSeAstigmatismo, 
  verificaSePresbiopia,
  verificaSeEstrabismo
} from '@utils/consultaHelper';
import { Refracao } from '@components/Receita/refracao';

import { Lente } from './lente';
import { Recomendacoes } from './recomendacoes';
import { ConsultaBloco } from '../ConsultaBloco';

import BtnAssinarEnviar from '@assets/btn_assinar_enviar.svg';
import BtnVisualizar from '@assets/botao_visualizar.svg';

import { 
  Subtitulo,
  Content,
  Botao,
  LoadingEnviar,
  Block
} from './styles';

export function Receita({
  receita,
  loading,
  handleAssinarReceita,
  handleVisualizar,
}) {
  const { 
    paciente, 
    setPaciente,
    alteraDadosClinicos, 
    validarReceita, 
    receitaPronta, 
    enviado, 
    errorReceita,
    alteradoPorAi
  } = useConsulta();
  
  useEffect(() => {
    if(receita) {
      validarReceita(false);
    }
    var new_diagnosticos = {"ativarDiagnosticos": false, "diagnosticosArray": []};
    if(paciente.dadosClinicos?.diagnosticos) {
      new_diagnosticos = paciente.dadosClinicos?.diagnosticos;
    }
    if(verificaSeMiopia(receita)) {
      new_diagnosticos = adicionaDiagnostico(new_diagnosticos, 'H52.1', 'Miopia');
    }
    if(verificaSeHipermetropia(receita)) {
      new_diagnosticos = adicionaDiagnostico(new_diagnosticos, 'H52.0', 'Hipermetropia');
    }
    if(verificaSeAstigmatismo(receita)) {
      new_diagnosticos = adicionaDiagnostico(new_diagnosticos, 'H52.2', 'Astigmatismo');
    }
    if(verificaSePresbiopia(receita)) {
      new_diagnosticos = adicionaDiagnostico(new_diagnosticos, 'H52.4', 'Presbiopia');
    }
    if(verificaSeEstrabismo(receita)) {
      new_diagnosticos = adicionaDiagnostico(new_diagnosticos, 'H50.0', 'Estrabismo');
    }
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "diagnosticos": new_diagnosticos
    }
    setPaciente({...paciente, "dadosClinicos": new_dados_clinicos})
  }, [receita]);

  function adicionaDiagnostico(diagnosticos, codigo, descricao) {
    const found = (diagnosticos.diagnosticosArray ? diagnosticos.diagnosticosArray.find((element) => element.codigo === codigo) : false);
    if(found) { // se ja existe, nao insere
      return diagnosticos;
    }
    var new_items = [];
    if(diagnosticos.diagnosticosArray) {
      new_items = [...diagnosticos.diagnosticosArray, {codigo, descricao}];
    } else {
      new_items = [{codigo, descricao}];
    }
    return {...diagnosticos, "diagnosticosArray": new_items};
  }

  var botao = <></>;
  if(loading) {
    botao = <LoadingEnviar><Loading /></LoadingEnviar>;
  } else if(enviado) {
    botao = <Botao onClick={handleVisualizar}><img src={BtnVisualizar} alt="visualizar"/></Botao>; 
  } else if(receitaPronta) {
    botao = <Botao onClick={handleAssinarReceita}><img src={BtnAssinarEnviar} alt="enviar"/></Botao>; 
  }

  return (
  <ConsultaBloco text="Receita de lentes" pronto={receitaPronta} ai={alteradoPorAi.receita}>
    <Content>
    <>
      <Subtitulo className="ui_labels_bold">Refração</Subtitulo>
      <div style={{ position: 'relative'}}>
        { enviado && <Block></Block>}

        <Refracao />

        <Lente />

        <Recomendacoes recomendacoes={receita?.tecnologias}/>
      </div>
      {botao}
    </>
    </Content>
  </ConsultaBloco>);
}