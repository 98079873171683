import { useState, useEffect } from "react";

import BtnRemoverUm from '../../assets/btn_remover_um_white.svg';
import IconRemover from '../../assets/icon_remover_white.svg';
import IconAdd from '../../assets/icon_add_white.svg';

import {
  Container,
  Title,
  Linha,
  RecomendacoesLista,
  RecomendacaoLinha,
  Botao,
} from './styles';

import { Select } from "@components/Select";
import { useConsulta } from "@hooks/useConsulta";
import { CirurgiaImplanteLIO } from "@components/CirurgiaImplanteLIO";
import { CirurgiaRefrativa } from "@components/CirurgiaRefrativa";
import { getJornadasDisponiveis } from '@utils/conversaoV2';

type JornadaOptionsType = {
  value: string;
  label: string;
  sublabel: string;
}

export const Jornadas = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [jornadasOptions, setJornadasOptions] = useState<JornadaOptionsType[]>([]);

  const {
    jornadas,
    setJornadas,
    consultaData
  } = useConsulta();

  function getCadastradas() {
    const keys = Object.keys(jornadas);
    var jornadasDisponiveis = getJornadasDisponiveis(consultaData);

    const as_cadastradas = keys.map((key) => {
      var disponivel = jornadasDisponiveis.find((element) => element.id === key);
      if(disponivel) {
        return disponivel;
      } else {
        return {id: key, nome: key};
      }
    })
    return as_cadastradas;
  }

  useEffect(() => {
    const cadastradas = getCadastradas();
    var jornadasDisponiveis = getJornadasDisponiveis(consultaData);
    if(jornadasDisponiveis) {
      const options = jornadasDisponiveis.map((jornada) => ({
          id: jornada.id,
          label: jornada.nome, 
          sublabel: '',
          value: jornada.id
      }));
      const jornadasNaoCadastradas = options.filter((jornada) => !cadastradas.find((el) => el.id == jornada.id));
      setJornadasOptions(jornadasNaoCadastradas);
    }
  }, [consultaData, jornadas]);


  function handleIncluir() {
    setIsAdding(!isAdding);
  }

  function temJornada(id) {
    return jornadas.hasOwnProperty(id);
  }

  function handleAdiciona(opt) {
    // verifica se ja existe: {value, label, sublabel}
    if(!temJornada(opt.value)) {
      const new_jornadas = {...jornadas, [opt.value]: {}};
      setJornadas(new_jornadas);
    }
    setIsAdding(false);
  }

  function handleRemoverTodas() {
    setJornadas({});
  }
  
  function handleRemover(value: string) {
    var new_jornadas_cadastradas = {...jornadas};
    if(new_jornadas_cadastradas.hasOwnProperty(value)) {
      delete new_jornadas_cadastradas[value];
    }
    setJornadas(new_jornadas_cadastradas);
  }
  
  const temJornadas = (!jornadas ? false : Object.keys(jornadas).length > 0);
  const jornadasCadastradas = getCadastradas();
  
  return (  
  <>
    <Container>
      <Title>Jornadas</Title>
      <Linha>
        {jornadasOptions.length > 0 ?
          <Botao onClick={handleIncluir} className="ui_labels">
            <img src={IconAdd} alt="incluir"/>Adicionar jornada
          </Botao>
          : <div></div>
        }
        {temJornadas &&
        <Botao onClick={handleRemoverTodas} className="ui_labels" style={{ display: "flex", justifyContent: "center" }}>
          <img src={IconRemover} alt="remover"/>Remover todas
        </Botao>}
      </Linha>
      {isAdding &&
        <div style={{marginBottom: '10px'}}>
          <Select 
            options={jornadasOptions} 
            onChange={handleAdiciona} 
            autoFocus={true}
            placeholder="Escolha…"
            mode="dark"
          />
        </div>
      }
      { temJornadas && (
      <RecomendacoesLista>
        {jornadasCadastradas.map(jornada => (
          <RecomendacaoLinha key={jornada.id}>
            <p className="">{jornada.nome}</p>
            <img src={BtnRemoverUm} onClick={() => handleRemover(jornada.id)} alt="remover"/>
          </RecomendacaoLinha>
        ))}
      </RecomendacoesLista>)
      }
    </Container>
    
    { temJornada("cirurgiaImplanteLIO") &&
      <CirurgiaImplanteLIO data={jornadas.cirurgiaImplanteLIO}/>
    }

    { temJornada("cirurgiaRefrativa") &&
      <CirurgiaRefrativa data={jornadas.cirurgiaRefrativa}/>
    }
  </>
  );
};