import { useEffect, useState } from "react";
import { useConsulta } from '@hooks/useConsulta';

import { Radio } from '../Radio';
import { Prisma as InputPrisma, Prisma } from '../Prisma';
import { ReceitaDTO, PrismaDTO } from '@dtos/ConsultaDTO';

import { 
  Subtitulo,
  TriplaOpcao,
  Opcao,
  Minitexto,
  LenteContainer,
  LenteLinha,
  LenteTextarea
} from './styles';
import { merge } from "@utils/conversaoV2";

const monofocal_value = "Monofocal";
const progressica_value = "Progressiva";
const defocus_value = "Defocus";

export function Lente() {
  const { 
    receita,
    alteraReceita, 
    errorReceita
  } = useConsulta();

  function onTextareaInput(ev) {
    var orientacoes_textarea = document.getElementById('lenteorientacoes');
    orientacoes_textarea.style.height = 'auto';
    orientacoes_textarea.style.height = (orientacoes_textarea.scrollHeight > 56 ? orientacoes_textarea.scrollHeight+5 : 56) + "px";
  }

  function handleOrientacoesAtivar(value: boolean) {
    var new_receita : ReceitaDTO = {...receita, orientacoes: { ativarOrientacoes: value, orientacoesPaciente: '' }};
    alteraReceita(new_receita);
  }

  function handleTipoChange(value: string) {
    var new_receita : ReceitaDTO = {...receita};
    new_receita.tipo = value;
    alteraReceita(new_receita);
  }

  function handleUsoLongeChange(value: boolean) {
    var new_receita : ReceitaDTO = {...receita, "uso": {
      longe: value,
      perto: receita.uso?.perto,
    }};
    alteraReceita(new_receita);
  }

  function handleUsoPertoChange(value: boolean) {
    var new_receita : ReceitaDTO = {...receita, "uso": {
      longe: receita.uso?.longe,
      perto: value,
    }};
    alteraReceita(new_receita);
  }

  function handleAtivarPrisma(values: boolean) {
    var new_receita : ReceitaDTO = {...receita, "prisma": {
      "ativarPrisma": values,
      "OD": {},
      "OE": {},
    }};
    alteraReceita(new_receita);
  }

  function handlePrismaChange(values: PrismaDTO) {
    var new_receita : ReceitaDTO = {...receita, "prisma": values};
    alteraReceita(new_receita);
  }

  function handleObservacoesBlur(e) {
    var new_receita : ReceitaDTO = {...receita};
    new_receita.orientacoes.orientacoesPaciente = e.target.value;
    alteraReceita(new_receita);
  }

  return (
    <>
      <Subtitulo className="ui_labels_bold">Lentes de Óculos</Subtitulo>
      <TriplaOpcao>
        <Opcao 
          className={receita?.tipo === monofocal_value ? "ui_labels_bold" : "ui_labels"} 
          selected={receita?.tipo === monofocal_value} 
          onClick={() => handleTipoChange(monofocal_value)}>
            Monofocal
        </Opcao>
        <Opcao 
          className={receita?.tipo === progressica_value ? "ui_labels_bold" : "ui_labels"} 
          selected={receita?.tipo === progressica_value} 
          onClick={() => handleTipoChange(progressica_value)}>
            Progressiva
        </Opcao>
        <Opcao 
          className={receita?.tipo === defocus_value ? "ui_labels_bold" : "ui_labels"} 
          selected={receita?.tipo === defocus_value} 
          onClick={() => handleTipoChange(defocus_value)}>
            <div>Defocus <Minitexto>(Miopia)</Minitexto></div>
        </Opcao>
      </TriplaOpcao>

      <LenteContainer>
        { receita?.tipo === monofocal_value &&
          <LenteLinha>  
            <Radio label="Longe" onChange={(status) => handleUsoLongeChange(status)} 
              status={receita.uso?.longe} />
            <Radio label="Perto" onChange={(status) => handleUsoPertoChange(status)} 
              status={receita.uso?.perto} />
          </LenteLinha>
        }

        <LenteLinha>
          <Radio label="Orientações para o paciente" 
            onChange={(status) => handleOrientacoesAtivar(status)} 
            status={receita?.orientacoes?.ativarOrientacoes} 
          />
        </LenteLinha>

        { receita?.orientacoes?.ativarOrientacoes &&
          <LenteTextarea onInput={onTextareaInput} id="lenteorientacoes" className="ui_labels" 
            defaultValue={receita?.orientacoes?.orientacoesPaciente} 
            onBlur={handleObservacoesBlur} autoFocus>
          </LenteTextarea>
        }

        { receita.tipo === monofocal_value &&
          <LenteLinha>
            <Radio label="Prisma" 
              onChange={(status) => handleAtivarPrisma(status)} 
              status={receita.prisma?.ativarPrisma} />
          </LenteLinha>
        }

        { receita.tipo === monofocal_value && receita.prisma?.ativarPrisma  &&
          <InputPrisma 
            values={receita.prisma} 
            handlePrismaChange={handlePrismaChange} 
            error={errorReceita}/>
        }
      </LenteContainer>
    </>
  );
}
