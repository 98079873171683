import { ConsultaBloco } from "@components/ConsultaBloco";
import { CirurgiaRefrativaDTO, CirurgiaRefrativaOlhoDTO } from '@dtos/ConsultaDTO';
import { CirurgiaRefrativaOlho } from './olho';
import { useConsulta } from "@hooks/useConsulta";
import { useEffect, useState } from "react";

interface Props {
  data: CirurgiaRefrativaDTO
}

export const CirurgiaRefrativa = ({data} : Props) => {
  // nao esquecer do reaplicar OD para olho esquerdo
  const { jornadas, alteraCirurgiaRefrativa, alteradoPorAi } = useConsulta();
  const [pronto, setPronto] = useState(false);

  useEffect(() => {
    setPronto(valida())
  }, [jornadas]);

  function changeOlhoDireito(data: CirurgiaRefrativaOlhoDTO) {
    var data_ref = jornadas.cirurgiaRefrativa;
    data_ref = { ...data_ref, OD: data};

    alteraCirurgiaRefrativa(data_ref)
  }

  function changeOlhoEsquerdo(data: CirurgiaRefrativaOlhoDTO) {
    var data_ref = jornadas.cirurgiaRefrativa;
    data_ref = { ...data_ref, OE: data};
    alteraCirurgiaRefrativa(data_ref)
  }

  function valida() {
    if(!data.OD || !data.OE) {
      return false
    }
    if(!data.OD.indicacao && !data.OE.indicacao) {
      return false
    }
    if(data.OD.indicacao === 'Longe' || data.OD.indicacao === 'Perto') {
      const indicada = data.OD.tecnicas?.length > 0;
      if(!indicada) {
        return false;
      }
    }
    if(data.OE.indicacao === 'Longe' || data.OE.indicacao === 'Perto') {
      const indicada = data.OE.tecnicas?.length > 0;
      if(!indicada) {
        return false;
      }  
    }
    return true;
  }

  function handleReplicar() {
    changeOlhoEsquerdo(jornadas.cirurgiaRefrativa.OD);
  }

  return (
    <ConsultaBloco text="Cirurgia refrativa a laser" mode="dark" pronto={pronto} ai={alteradoPorAi.cirurgiaRefrativa}>
      <CirurgiaRefrativaOlho data={data.OD} title="Olho Direito" handleChange={changeOlhoDireito}/>
      <CirurgiaRefrativaOlho 
        data={data.OE} 
        title="Olho Esquerdo" 
        handleChange={changeOlhoEsquerdo}
        replicar={true}
        handleReplicar={handleReplicar}
      />
    </ConsultaBloco>
  );
}