import { useState, useEffect } from "react";
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { TriplaOpcao } from "@components/TriplaOpcao";
import { Radio } from "@components/Radio";
import { Lente } from "./lente";
import { CirurgiaImplanteLIOTecnologias } from "./tecnologias";
import { getTecnologiasDisponiveisLIO, getTecnologiasDisponiveisTiposLIO } from "@utils/conversaoV2";
import { monofocalExists } from '@utils/consultaHelper';

import { 
  CirurgiaImplanteLioOlhoDTO,
  CirurgiaImplanteLioTecnologias
 } from '@dtos/ConsultaDTO';

import {
  Linha,
  SubTitle,
  OlhoContainer,
  ItemBloco,
  MiniLabel,
  DraggableLinha
} from './styles';
import { useConsulta } from "@hooks/useConsulta";
import BtnReplicar from '@assets/btn_replicar.png';

const indicacoes = [
  {label: 'Não indicada', value:'Não indicada'},
  {label: 'Catarata', value:'Catarata'},
  {label: 'Facorrefrativa', value:'Facorrefrativa'}
];

const alvos = [
  {label: 'Longe', value:'Longe'},
  {label: 'Perto', value:'Perto'},
];

interface Props {
  data: CirurgiaImplanteLioOlhoDTO;
  title?: string;
  handleChange: (data: CirurgiaImplanteLioOlhoDTO) => any;
  replicar?: boolean;
  handleReplicar?: () => void;
}

export const CirurgiaImplanteLIOOlho = ({
  data, 
  title, 
  handleChange, 
  replicar=false,
  handleReplicar
 }: Props) => {
  const [tiposDeLenteIndicada, setTiposDeLenteIndicada] = useState([]);
  const [tiposDeLenteNaoIndicada, setTiposDeLenteNaoIndicada] = useState([]);
  const [showAlvo, setShowAlvo] = useState(false);
  const [tecnologiasDisponiveis, setTecnologiasDisponiveis] = useState<CirurgiaImplanteLioTecnologias[]>([]);

  const { consultaData } = useConsulta();  
  const tipos = getTecnologiasDisponiveisTiposLIO(consultaData);

  useEffect(() => {
    var indicadas = data.tiposDeLentes;
    if(indicadas) {
      setTiposDeLenteIndicada(indicadas);
      const indicadasIds = new Set(indicadas.map(({ id }) => id));
      var nao_indicadas = tipos.filter((item) => !indicadasIds.has(item.id))
      setTiposDeLenteNaoIndicada(nao_indicadas);
    } else {
      setTiposDeLenteIndicada([]);
      setTiposDeLenteNaoIndicada(tipos);
    }
    // se tem algum tipo de lente monofocal, mostra o alvo
    const monofocal = monofocalExists(indicadas);
    setShowAlvo(monofocal);

  }, [data]);

  useEffect(() => {    
    let filtered_disponiveis = filtraTecnologiaPorLente();
    setTecnologiasDisponiveis(filtered_disponiveis);
  }, [tiposDeLenteIndicada]);

  function filtraTecnologiaPorLente() {
    const tecnologiasAll = getTecnologiasDisponiveisLIO(consultaData);
    if(tiposDeLenteIndicada.length > 0 && tecnologiasAll.length > 0) {
      let result = tiposDeLenteIndicada.map(a => a.nome);
      let filtered = tecnologiasAll.filter(t => {
        return result.includes(t.tipo);
      });
      return filtered;
    } else {
      return [];
    }
  }

  function filtraTecnologiaAdicionadaPorLente() {
    const tecnologiasAdicionadas = (data.tecnologias ? data.tecnologias : []);
    if(tiposDeLenteIndicada.length > 0 && tecnologiasAdicionadas.length > 0) {
      let result = tiposDeLenteIndicada.map(a => a.nome);
      let filtered = tecnologiasAdicionadas.filter(t => {
        return result.includes(t.tipo);
      });
      return filtered;
    } else {
      return [];
    }
  }

  function handleChangeField(field, value) {
    const new_data : CirurgiaImplanteLioOlhoDTO = {...data, [field]: value};
    handleChange(new_data);
  }

  function handleChangeTecnologias(value: CirurgiaImplanteLioTecnologias[]) {
    handleChangeField("tecnologias", value);
  }

  function handleDragEnd(result: any) {
    if(!result.destination) {
      return;
    }
    const indicadas = reorder(tiposDeLenteIndicada, result.source.index, result.destination.index);
    setTiposDeLenteIndicada(indicadas);
    const tipos = [...indicadas];

    const ordenados = ordenaPosicao(tipos);
    handleChangeField("tiposDeLentes", ordenados);
  }

  function reorder<T>(List: T[], startIndex: number, endIndex:number) {
    const result = Array.from(List);
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed);
    return result;
  }

  function handleIndicadaChange(option) {
    const tipos = data.tiposDeLentes.filter((e) => option.id !== e.id);
    const ordenados = ordenaPosicao(tipos);
    handleChangeField("tiposDeLentes", ordenados);
  }

  function handleNaoIndicadaChange(option) {
    var max_item = data.tiposDeLentes?.reduce((acc, i) => (i.posicao > acc.posicao ? i : acc), { posicao: 0 });
    const max = (max_item ? max_item.posicao + 1 : 0);
    if(!data.tiposDeLentes) {
      data.tiposDeLentes = [];
    }
    const indicadas = [...data.tiposDeLentes, {...option, posicao: max}];
    const ordenados = ordenaPosicao(indicadas);
    handleChangeField("tiposDeLentes", ordenados);
  }

  function ordenaPosicao(items) {
    var posicao = 0;
    const new_items = items.map(e => {
      posicao++;
      e.posicao = posicao;
      return e;
    })
    return new_items;
  }

  if(!data) {
    data = {
      "indicacao": "",
      "alvoMonofocal": "", 
      "tecnologias": [], 
      "tiposDeLentes": [], 
      "torica": false
    };
  }

  const indicada = (data.indicacao === "Catarata" || data.indicacao === 'Facorrefrativa');

  return (
    <OlhoContainer>
      <SubTitle>
        {title}
        {replicar && <button onClick={handleReplicar}><img src={BtnReplicar} style={{ height: '20px'}} /></button>}
      </SubTitle>

      <TriplaOpcao options={indicacoes} selected={data.indicacao} onClick={(value) => handleChangeField('indicacao', value)}></TriplaOpcao>

      {indicada &&
      <ItemBloco>
        <MiniLabel className="ui_mini_labels">Tipos de lentes</MiniLabel>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="tipolente" type="list" direction="vertical">
            {(provided) =>(
              <article ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tiposDeLenteIndicada.map((lente, i) => (
                  <Lente key={lente.id} lente={lente} index={i} handleChange={() => handleIndicadaChange(lente)} />
                ))}
                {provided.placeholder}
              </article>
            )}
          </Droppable>
        </DragDropContext>
        {tiposDeLenteNaoIndicada.map((option, i) => (
          <DraggableLinha key={i}>
            <Radio label={option.nome} status={false} onChange={() => handleNaoIndicadaChange(option)} />
          </DraggableLinha>
          ))
        }
      </ItemBloco>
      }
      {indicada && showAlvo &&
      <ItemBloco>
        <MiniLabel className="ui_mini_labels">Alvo da lente monofocal</MiniLabel>
        <div style={{ width: '238px', padding: '6px'}}>
        <TriplaOpcao options={alvos} selected={data.alvoMonofocal} onClick={(value) => handleChangeField('alvoMonofocal', value)}></TriplaOpcao>
        </div>
      </ItemBloco>
      }

      {indicada &&
      <>
        <ItemBloco>
          <MiniLabel className="ui_mini_labels">Toricidade</MiniLabel>
          <Linha>
            <Radio label="Tórica" status={data.torica} onChange={(value) => handleChangeField('torica', value)} />
          </Linha>
        </ItemBloco>

        <ItemBloco>
          <MiniLabel className="ui_mini_labels">Indique opções adequadas para seu paciente</MiniLabel>
          <CirurgiaImplanteLIOTecnologias 
            tecnologias={filtraTecnologiaAdicionadaPorLente()}
            tecnologiasDisponiveis={tecnologiasDisponiveis}
            handleChange={handleChangeTecnologias}
            />
        </ItemBloco>
      </>
      }
    </OlhoContainer>
  );
}