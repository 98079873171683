import { ListaTexto } from "@components/ListaTexto";
import { Radio } from "@components/Radio";
import { useConsulta } from "@hooks/useConsulta";
import { useEffect, useState } from "react";
import {
  Container,
  Bloco,
  BlocoContainer
} from './styles';
import { Diagnosticos } from "@components/Diagnosticos";
import { AcuidadeVisualLonge } from "@components/AcuidadeVisualLonge";
import { AcuidadeVisualPerto } from "@components/AcuidadeVisualPerto";
import { PressaoIntraocular } from "@components/PressaoIntraocular";
import { SegmentoAnterior } from "@components/SegmentoAnterior";
import { FundoDeOlho } from "@components/FundoDeOlho";
import { TratamentosRealizados } from "@components/TratamentosRealizados";
import { MedicamentosEmUso } from "@components/MedicamentosEmUso";

export function DadosClinicos() {
  const { 
    paciente,
    alteraDadosClinicos,
    alteradoPorAi,
  } = useConsulta();  

  function handleChangeTratamentosRealizados(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "tratamentosRealizados": value
    }
    alteraDadosClinicos(new_dados_clinicos)
  }

  function handleChangeMedicamentosEmUso(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "medicamentosEmUso": value
    }
    alteraDadosClinicos(new_dados_clinicos)    
  }

  function handleChangeDiagnosticos(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "diagnosticos": value
    }
    alteraDadosClinicos(new_dados_clinicos)
  }

  function handleChangeAcuidadeVisualDeLonge(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "acuidadeVisualDeLonge": value
    }
    alteraDadosClinicos(new_dados_clinicos)
  }

  function handleChangeAcuidadeVisualDePerto(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "acuidadeVisualDePerto": value
    }
    alteraDadosClinicos(new_dados_clinicos)
  }

  function handleChangePressaoIntraocular(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "pressaoIntraocular": value
    }
    alteraDadosClinicos(new_dados_clinicos)
  }

  function handleChangeSegmentoAnterior(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "segmentoAnterior": value
    }
    alteraDadosClinicos(new_dados_clinicos)
  }

  function handleChangeFundoDeOlho(value) {
    const new_dados_clinicos = {
      ...paciente.dadosClinicos,
      "fundoDeOlho": value
    }
    alteraDadosClinicos(new_dados_clinicos)
  }

  return (
    <Container text="Dados clínicos" ai={alteradoPorAi.dadosClinicos}>
      <BlocoContainer>
        <Bloco>
          <TratamentosRealizados 
            data={paciente.dadosClinicos?.tratamentosRealizados} 
            handleChange={handleChangeTratamentosRealizados} 
          />
        </Bloco>

        <Bloco>
          <MedicamentosEmUso 
            data={paciente.dadosClinicos?.medicamentosEmUso} 
            handleChange={handleChangeMedicamentosEmUso} 
          />
        </Bloco>

        <Bloco>
          <AcuidadeVisualLonge 
            data={paciente.dadosClinicos?.acuidadeVisualDeLonge} 
            handleChange={handleChangeAcuidadeVisualDeLonge} 
          />
        </Bloco>

        <Bloco>
          <AcuidadeVisualPerto 
            data={paciente.dadosClinicos?.acuidadeVisualDePerto} 
            handleChange={handleChangeAcuidadeVisualDePerto} 
          />
        </Bloco>

        <Bloco>
          <PressaoIntraocular 
            data={paciente.dadosClinicos?.pressaoIntraocular}
            handleChange={handleChangePressaoIntraocular}        
          />
        </Bloco>

        <Bloco>
          <SegmentoAnterior 
            data={paciente.dadosClinicos?.segmentoAnterior}
            handleChange={handleChangeSegmentoAnterior}
          />
        </Bloco>

        <Bloco>
          <FundoDeOlho 
            data={paciente.dadosClinicos?.fundoDeOlho}
            handleChange={handleChangeFundoDeOlho}
          />
        </Bloco>

        <Bloco>
          <Diagnosticos 
              diagnosticos={paciente.dadosClinicos?.diagnosticos}
              handleChange={handleChangeDiagnosticos}
          />
        </Bloco>
      </BlocoContainer>
    </Container>
  );
}