import { createContext, ReactNode, useEffect, useState } from "react";
import { isDev, ditarResponseExample, processarV2ResponseExample, ditarResponseExample2 } from '@utils/helper';
import { api } from '@services/api';
import { erroReceita, erroPaciente, erroInfoConsulta } from '@utils/validacao';
import { 
  getTecnologiasDisponiveisLIO as getTecnologiasDisponiveisLIOFromResponse ,

  getPacienteFromResponse, 
  getReceitaFromResponse,
  getJornadasFromResponse,
  merge
} from '@utils/conversaoV2';
import { toast } from 'react-toastify';

import { 
  PacienteDTO, 
  ReceitaDTO, 
  TecnologiaDisponivelLioDTO,
  JornadaCadastradaDTO,
  SituacaoDTO,
  AlteradoPorAIDTO,
  DadosClinicosDTO,
  JornadaDTO,
  CirurgiaImplanteLioDTO,
  CirurgiaRefrativaDTO
} from '@dtos/ConsultaDTO';

import { AppError } from "@utils/AppError";
import { IniciarResponse } from "@dtos/ApiDTO";
import { format, parse } from "date-fns";
import { getAlteradosPorAI } from "@utils/AIHelper";
import { preparaData } from "@utils/consultaHelper";
import { time } from "console";

let timeout;

export type ConsultaContextDataProps = {
  paciente: PacienteDTO;
  setPaciente: (paciente: PacienteDTO) => void;
  validarPaciente: (change_error?: boolean, paciente_obj?: PacienteDTO) => boolean;
  pacientePronto: boolean;

  receita: ReceitaDTO;
  setReceita: (receita: ReceitaDTO) => void;
  validarReceita: (change_error?: boolean, receita_obj?: ReceitaDTO) => boolean;
  receitaPronta: boolean;

  enviado: boolean;
  setEnviado: (enviado: boolean) => void;
  
  jornadas: JornadaCadastradaDTO;
  setJornadas: (jornadas: JornadaCadastradaDTO) => void;

  consultaData: any;
  setConsultaData: (data: any) => void;
  
  getTecnologiasDisponiveisLIO: () => TecnologiaDisponivelLioDTO[];
 
  errorPaciente: any;
  setErrorPaciente: (data: any) => void;
  errorReceita: any;
  setErrorReceita: (data: any) => void;

  buscarPagina: () => void;
  enviarDoClipboard: (text: string) => void;
  enviarAudio: (blob, type?: string) => void;

  validarInfo: (new_date: string, new_hour: string) => void;
  info: SituacaoDTO;
  infoPronto: boolean;

  alteradoPorAi: AlteradoPorAIDTO;
  alteraPaciente: (paciente_new: PacienteDTO) => void;
  alteraAnamnese: (text: string) => void;
  alteraDadosClinicos: (new_dados_clinicos: DadosClinicosDTO) => void;
  alteraReceita: (receita_new: ReceitaDTO) => void;
  alteraCirurgiaImplanteLIO: (cirurgiaimplantelio_new: CirurgiaImplanteLioDTO) => void;
  alteraCirurgiaRefrativa: (cirurgiarefrativa_new: CirurgiaRefrativaDTO) => void;
}

type ConsultaContextProviderProps = {
  children: ReactNode;
}

export const ConsultaContext = createContext<ConsultaContextDataProps>({} as ConsultaContextDataProps);

export function ConsultaContextProvider({ children }: ConsultaContextProviderProps)  {
  // objeto com os erros encontrados. se tem o parametro, tem o erro.
  const [ errorPaciente, setErrorPaciente ] = useState({});
  const [ errorReceita, setErrorReceita ] = useState({});

  const [ consultaData, setConsultaData] = useState<IniciarResponse>({});
  const [ info, setInfo] = useState<SituacaoDTO>();
  const [ paciente, setPaciente] = useState<PacienteDTO>({});
  const [ pacientePronto, setPacientePronto] = useState(false);

  const [ receita, setReceita] = useState<ReceitaDTO>({});
  const [ receitaPronta, setReceitaPronta] = useState(false);
  const [ enviado, setEnviado] = useState(false);
  
  const [ jornadas, setJornadas] = useState<JornadaCadastradaDTO>({});

  const [infoPronto, setInfoPronto] = useState(false);
  const [alteradoPorAi, setAlteradoPorAi] = useState<AlteradoPorAIDTO>({});

  function getTecnologiasDisponiveisLIO() {
    return (consultaData ? getTecnologiasDisponiveisLIOFromResponse(consultaData) : []);
  }

  async function getDOM() {
    const url = document.URL;
    const html = document.getElementsByTagName("body")[0].innerHTML;
    const text = document.getElementsByTagName("body")[0].innerText;
    return {
        "id": consultaData.metadata?.id,
        "urlOrigem" : url,
        "innerText" : text,
        "serializedDOM" : html
    };
  }

  useEffect(() => {
    if(consultaData.payload?.situacao) {
      setInfo(consultaData.payload.situacao);
    }
  }, [consultaData]);

  // esta função é chamada apenas apos as respostas da AI
  async function responseToObj(response_data) {
    const jornadas_new = getJornadasFromResponse(response_data);
    const jornadas_merged = merge(jornadas, jornadas_new);
    setJornadas(jornadas_merged);
    // se receita ja foi enviada, nao permitir alterar o paciente e a receita
    if(!enviado) {

      const paciente_new = getPacienteFromResponse(response_data);
      // Nome e CPF nao podem ser sobrescritos pela AI
      if(!consultaData.isNew && paciente_new.nome) {
        delete paciente_new.nome;
      }
      if(paciente.CPF && paciente_new.CPF) {
        delete paciente_new.CPF;
      }
      const paciente_copy = {...paciente};
      const paciente_merged = merge(paciente_copy, paciente_new);

      const receita_new = getReceitaFromResponse(response_data);
      const receita_merged = merge(receita, receita_new);

      const alterados = getAlteradosPorAI({...alteradoPorAi}, paciente, paciente_merged, receita, receita_merged, jornadas, jornadas_merged);

      setPaciente(paciente_merged);
      setReceita(receita_merged);
      setAlteradoPorAi(alterados);
      
      setErrorPaciente({});
      setErrorReceita({});

      // depois de preencher, validar como se estivesse digitado
      validarPaciente(true, paciente_merged);
      validarReceita(true, receita_merged);  
    } else {
      const alterados = getAlteradosPorAI({...alteradoPorAi}, paciente, paciente, receita, receita, jornadas, jornadas_merged);
      setAlteradoPorAi(alterados)
    }
  }

  async function enviarAudio(blob, type = "OGG") {
    //anamnesis
    const data = { 
      "id": consultaData.metadata?.id,
      "format" : type, 
      "serializedAudio" : blob,
      "anamnesis": (paciente.anamneseMedica ? paciente.anamneseMedica : (paciente.preAnamnese ? paciente.preAnamnese.respostasConsolidadas : ''))
    };
    let response;
    try {
      if(isDev()) {
        response = ditarResponseExample2();      
      } else {
        response = await api.post("ditar", data, {
          baseURL: "https://vera.ver.ai/api/extension/",
        });
      }
      responseToObj(response.data);
    } catch(error) {
      const isAppError = error instanceof AppError;    
      const title = isAppError ? error.message : 'Erro. Tente novamente mais tarde.';
      toast.error(title);
    }
  }

  async function processar(data) {
    let response;
    try {
      //if(isDev()) {
      //  response = processarV2ResponseExample();
      //} else {
        response = await api.post("processar", data, {
            baseURL: "https://vera.ver.ai/api/extension/",
          }
        );
      //}
      return response.data;
    } catch(error) {
      return false;
      //console.log(error);
      const isAppError = error instanceof AppError;    
      const title = isAppError ? error.message : 'Erro. Tente novamente mais tarde.';
      toast.error(title);
    }
  }

  async function buscarPagina() {
    let data = {"id": "", "urlOrigem": "", "innerText": "", "serializedDOM" : ""};
    if(chrome.tabs) {
      const [tab] = await chrome.tabs.query({ currentWindow: true, active: true });
      const [res] = await chrome.scripting.executeScript({
          target: { tabId: tab.id },
          func: getDOM,
      });
      data = res.result;
    }
    const response_data = await processar(data);
    responseToObj(response_data); 
  }

  async function enviarDoClipboard(text) {
    let data = {"id": consultaData.metadata?.id, "urlOrigem": "", "innerText": text, "serializedDOM" : text};
    const response_data = await processar(data);
    if(response_data) {
      responseToObj(response_data); 
    }
  }

  const validarPaciente = (change_error = false, paciente_obj = null) => {
    if(!paciente_obj) {
      paciente_obj = paciente;
    }
    var erro_paciente = erroPaciente(paciente_obj);
    if(change_error) {
      setErrorPaciente(erro_paciente);
    }
    const isValid = Object.keys(erro_paciente).length === 0;
    setPacientePronto(isValid);
    return isValid;
  };

  const validarReceita = (change_error = false, receita_obj = null) => {
    if(!receita_obj) {
      receita_obj = receita;
    }
    var erro_receita = erroReceita(receita_obj);
    if(change_error) {
      setErrorReceita(erro_receita);
    }
    const isValid = Object.keys(erro_receita).length === 0;
    setReceitaPronta(isValid);
    return isValid;
  };

  async function autoSave(new_info = {}, new_paciente = {}, new_receita = {}, new_jornadas = {}) {
    var should_update = true;
    if(localStorage.getItem('lastupdate')) {
      const lastupdate = parseInt(localStorage.getItem('lastupdate'));
      const seconds = Math.floor((Date.now()-lastupdate)/1000);
      const diff = lastupdate + 15000 - Date.now();
      if(seconds < 15) {
        should_update = false;
        clearTimeout(timeout);
        timeout = setTimeout(() => autoSave(new_info, new_paciente, new_receita, new_jornadas), diff)
        return;
      }
    }

    if(should_update) {

      clearTimeout(timeout);

      if(Object.keys(new_info).length === 0) {
        new_info = info;
      }
      if(Object.keys(new_paciente).length === 0) {
        new_paciente = paciente;
      }
      if(Object.keys(new_receita).length === 0) {
        new_receita = receita;
      }
      if(Object.keys(new_jornadas).length === 0) {
        new_jornadas = jornadas;
      }
      var data = preparaData(consultaData.metadata.id, new_info, new_paciente, new_receita, new_jornadas);
      const response = await api.post("salvar", data);
      localStorage.setItem('lastupdate', Date.now().toString())
    }
  }

  function validarInfo(new_date, new_hour) {
    const error = erroInfoConsulta(new_date, new_hour);
    if(Object.keys(error).length === 0) {
      const parsed = parse(new_date, 'dd/MM/yyyy', new Date());
      const formatted = format(parsed, 'yyyy-MM-dd')+'T'+new_hour+':00Z';
      if(info) {
        const new_info = info;
        new_info.agendadaPara = formatted;
        setInfo(new_info);
        autoSave(new_info);
      }
      setInfoPronto(true);
    } else {
      setInfoPronto(false);
    }
  }

  function alteraPaciente(paciente_new: PacienteDTO) {
    setAlteradoPorAi({ ...alteradoPorAi, "paciente": false});
    setPaciente(paciente_new)
    autoSave({}, paciente_new);
  }

  function alteraDadosClinicos(new_dados_clinicos: DadosClinicosDTO) {
    setAlteradoPorAi({ ...alteradoPorAi, "dadosClinicos": false});
    const paciente_new = {...paciente, "dadosClinicos": new_dados_clinicos};
    setPaciente(paciente_new)
    autoSave({}, paciente_new);
  }

  function alteraAnamnese(text: string) {
    setAlteradoPorAi({...alteradoPorAi, "anamnese": false});
    const paciente_new = {...paciente, "anamneseMedica": text};
    setPaciente(paciente_new);
    autoSave({}, paciente_new);
  }

  function alteraReceita(receita_new: ReceitaDTO) {
    setAlteradoPorAi({ ...alteradoPorAi, "receita": false});
    setReceita(receita_new)
    autoSave({}, {}, receita_new);
  }

  function alteraCirurgiaImplanteLIO(cirurgiaimplantelio_new: CirurgiaImplanteLioDTO) {
    setAlteradoPorAi({ ...alteradoPorAi, "cirurgiaImplanteLio": false});
    const new_jornadas = {...jornadas, cirurgiaImplanteLIO: cirurgiaimplantelio_new};
    setJornadas(new_jornadas);
    autoSave({}, {}, {}, new_jornadas);
  }

  function alteraCirurgiaRefrativa(cirurgiarefrativa_new: CirurgiaRefrativaDTO) {
    setAlteradoPorAi({ ...alteradoPorAi, "cirurgiaRefrativa": false});
    const new_jornadas = {...jornadas, cirurgiaRefrativa: cirurgiarefrativa_new};
    setJornadas(new_jornadas);
    autoSave({}, {}, {}, new_jornadas);
  }

  return (
    <ConsultaContext.Provider value={{ 
      getTecnologiasDisponiveisLIO,

      paciente,
      setPaciente,
      alteraPaciente,
      validarPaciente,
      pacientePronto,

      receita,
      setReceita,
      alteraReceita,
      validarReceita,
      receitaPronta,

      enviado,
      setEnviado,

      jornadas,
      setJornadas,

      consultaData,
      setConsultaData,

      errorPaciente,
      setErrorPaciente,
      errorReceita,
      setErrorReceita,
    
      buscarPagina,
      enviarDoClipboard,
      enviarAudio,

      validarInfo,
      infoPronto,
      info,

      alteradoPorAi,
      alteraDadosClinicos,
      alteraAnamnese,
      alteraCirurgiaImplanteLIO,
      alteraCirurgiaRefrativa
    }}>
      {children}
    </ConsultaContext.Provider>
  );
}