import { isChrome, isSafari } from 'react-device-detect';
/* global chrome */
export async function askPermission() {
  if(isChrome) {
    try {
      if(chrome.tabs) {
        const [tab] = await chrome.tabs.query({ currentWindow: true, active: true });
        console.log(tab.url)
        await chrome.scripting.executeScript({
          target: { tabId: tab.id },
          func: () => {
            const iframe = document.createElement("iframe");
            iframe.setAttribute("hidden", "hidden");
            iframe.setAttribute("id", "permissionsIFrame");
            iframe.setAttribute("allow", "microphone");
            iframe.src = chrome.runtime.getURL("requestPermissions.html");
            document.body.appendChild(iframe);
            //document.body.style.backgroundColor = "orange";
          },
        });
      }
    } catch(e) {
      var newURL = chrome.runtime.getURL('requestPermissions.html');
      chrome.tabs.create({ url: newURL });
    }
  }
}