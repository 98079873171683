import { Editable } from '@components/Editable';
import {
  Container, 
  Linha,
  Label,
  Coluna,
  MiniLabel,
  Valor
} from './styles';
import { Radio } from '@components/Radio';
import { PressaoIntraocularDTO } from '@dtos/ConsultaDTO';

interface Props {
  data: PressaoIntraocularDTO,
  handleChange: (values: PressaoIntraocularDTO) => void;
}

export function PressaoIntraocular({ data, handleChange }: Props) {

  function handleChangeOlhoDireito(e) {
    const new_data = {...data, "OD": { "mmHg": e.target.value }}
    handleChange(new_data);
  }

  function handleChangeOlhoEsquedo(e) {
    const new_data = {...data, "OE": { "mmHg": e.target.value }}
    handleChange(new_data);
  }

  function handleAtivar() {
    handleChange({...data, "ativarPressaoIntraocular": !data.ativarPressaoIntraocular});
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  if(!data) {
    data = {
      "ativarPressaoIntraocular": false, 
      "OD": {"mmHg": ""},
      "OE": {"mmHg": ""},
    };
  }

  return (
  <>
    <Radio label="Pressão intraocular" status={data.ativarPressaoIntraocular} 
      onChange={handleAtivar}
    />
    {data.ativarPressaoIntraocular &&
    <Container>
      <Linha key="od">
        <Label>OD</Label>
        <Coluna key="od">
          <Valor className="ui_labels" 
            value={data.hasOwnProperty("OD") ? data.OD.mmHg : ""}
            onChange={handleChangeOlhoDireito} 
            onKeyDown={handleKeyDown}
            inputMode="numeric"
          />
          <MiniLabel className="ui_mini_labels">mmHg</MiniLabel>
        </Coluna>
        <Label>OE</Label>
        <Coluna key="oe">
          <Valor className="ui_labels" 
            value={data.hasOwnProperty("OE") ? data.OE.mmHg : ""}
            onChange={handleChangeOlhoEsquedo} 
            onKeyDown={handleKeyDown}
            inputMode="numeric"
            />
          <MiniLabel className="ui_mini_labels">mmHg</MiniLabel>
        </Coluna>
      </Linha>
    </Container>
    }
  </>
  );
}