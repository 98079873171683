import { ReceitaDTO } from "@dtos/ConsultaDTO";

const first_level = "payload";
const paciente_level = "paciente";
const receita_level = "receita";
const preanamnese_level = "preAnamnese";
const situacao_level = "situacao";

const respondida_em_field = "respondidaEm";
const texto_field = "respostasConsolidadas";

const tecnologias_disponiveis_level = "tecnologiasDisponiveis";
const jornadas_disponiveis_level = "jornadasDisponiveis";
const jornadas_level = "jornadas";
const cirurgia_implante_lio_level = "cirurgiaImplanteLIO";

export function merge(obj1_original, obj2) {
  var obj1 = {...obj1_original };
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if ( obj2[p].constructor == Object ) {
        obj1[p] = merge(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch(e) {
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}

export function getPacienteFromResponse(data) {
  if(!data){
    return {};
  }
  if(!data.hasOwnProperty(first_level)){
    return {};
  }
  if(!data[first_level].hasOwnProperty(paciente_level)){
    return {};
  }
  return data[first_level][paciente_level];
}

export function getTecnologiasDisponiveis(data) {
  if(!data.hasOwnProperty(first_level)){
    return {};
  }
  if(!data[first_level].hasOwnProperty(tecnologias_disponiveis_level)){
    return {};
  }
  if(!data[first_level][tecnologias_disponiveis_level].hasOwnProperty('oculos')){
    return {};
  }
  return data[first_level][tecnologias_disponiveis_level]['oculos'];
}

export function getTecnologiasDisponiveisLIO(data) {
  if(!data.hasOwnProperty(first_level)){
    return [];
  }
  if(!data[first_level].hasOwnProperty(tecnologias_disponiveis_level)){
    return [];
  }
  if(!data[first_level][tecnologias_disponiveis_level].hasOwnProperty('LIO')){
    return [];
  }
  return data[first_level][tecnologias_disponiveis_level]['LIO'];
}

export function getTecnologiasDisponiveisTiposLIO(data) {
  if(!data.hasOwnProperty(first_level)){
    return [];
  }
  if(!data[first_level].hasOwnProperty(tecnologias_disponiveis_level)){
    return [];
  }
  if(!data[first_level][tecnologias_disponiveis_level].hasOwnProperty('tiposDeLIO')){
    return [];
  }
  return data[first_level][tecnologias_disponiveis_level]['tiposDeLIO'];
}

export function getTecnologiasDisponiveisRefrativa(data) {
  if(!data.hasOwnProperty(first_level)){
    return [];
  }
  if(!data[first_level].hasOwnProperty(tecnologias_disponiveis_level)){
    return [];
  }
  if(!data[first_level][tecnologias_disponiveis_level].hasOwnProperty('tecnicasCirurgiaRefrativa')){
    return [];
  }
  return data[first_level][tecnologias_disponiveis_level]['tecnicasCirurgiaRefrativa'];
}

export function getReceitaFromResponse(data) {
  if(!data.hasOwnProperty(first_level)){
    return {};
  }
  if(!data[first_level].hasOwnProperty(receita_level)){
    return {};
  }
  const receita_data = data[first_level][receita_level];
  return receita_data;
}

export function getDiagnosticosFromResponse(data) {
  try { 
    return data[first_level][paciente_level]["dadosClinicos"]["diagnosticos"];
  } catch (error) {
    return [];
  }
}

// return array
export function getJornadasDisponiveis(data) {
  if(!data.hasOwnProperty(first_level)){
    return [];
  }
  if(!data[first_level].hasOwnProperty(jornadas_disponiveis_level)){
    return [];
  }
  return data[first_level][jornadas_disponiveis_level];
}

export function getJornadasFromResponse(data) {
  if(!data.hasOwnProperty(first_level)){
    return {};
  }
  if(!data[first_level].hasOwnProperty(jornadas_level)){
    return {};
  }
  const jornadas = data[first_level][jornadas_level];
  return jornadas;
}

export function getTiposDeLentes(data, olho) {
  if(!data.hasOwnProperty(first_level)){
    return [];
  }
  if(!data[first_level].hasOwnProperty(jornadas_level)){
    return [];
  }
  if(!data[first_level][jornadas_level].hasOwnProperty(cirurgia_implante_lio_level)){
    return [];
  }

  var olho_data;
  if(olho === 'd') {
    if(!data[first_level][jornadas_level][cirurgia_implante_lio_level].hasOwnProperty('OD')){
      return [];
    }
    olho_data = data[first_level][jornadas_level][cirurgia_implante_lio_level]['OD'];
  } else {
    if(!data[first_level][jornadas_level][cirurgia_implante_lio_level].hasOwnProperty('OE')){
      return [];
    }
    olho_data = data[first_level][jornadas_level][cirurgia_implante_lio_level]['OE'];
  }
  if(!olho_data.hasOwnProperty("tiposDeLentes")){
    return {};
  }
  return olho_data['tiposDeLentes'];
}

export function getTransicoesFromResponse(data) {
  if(!data.hasOwnProperty(first_level)){
    return [];
  }
  if(!data[first_level].hasOwnProperty(situacao_level)){
    return [];
  }
  if(!data[first_level][situacao_level].hasOwnProperty("transicoes")){
    return [];
  }
  return data[first_level][situacao_level]["transicoes"];
}

export function hasReceita(receita : ReceitaDTO) {
  if(receita.refracao?.OD?.esf ||
    receita.refracao?.OD?.cil ||
    receita.refracao?.OD?.eixo ||
    receita.refracao?.OD?.adicao ||
    receita.refracao?.OE?.esf ||
    receita.refracao?.OE?.cil ||
    receita.refracao?.OE?.eixo ||
    receita.refracao?.OE?.adicao ||
    receita.prisma?.ativarPrisma
  ) {
    return true;
  }
  return false;
}