import { useState, useEffect, PropsWithChildren } from "react";

import BtnCollapse from '../../assets/btn_collapse.svg';
import BtnExpand from '../../assets/btn_expand.svg';

import { 
  Container,
  Cabecalho,
  Titulo,
  MiniTitulo,
  CloseIcon,
} from './styles';

type TituloProps = {
  text: string;
  minitext?: string;
  mode?: "light" | "dark";
  pronto?: boolean;
  ai?: boolean;
}

export function ConsultaBloco({ text, children, minitext = '', mode = 'light', pronto = false, ai = false }: PropsWithChildren<TituloProps>) {
  const [open, setOpen] = useState(true);  
  
  function handleClose(e) {
    e.preventDefault();
    setOpen(!open);
  }

  return (
    <Container mode={mode} $pronto={pronto ? "true" : "false"} $ai={ai}>
      <Cabecalho>
        <Titulo>
          <h3>{text}</h3>
          {minitext && <MiniTitulo className="ui_mini_labels">{minitext}</MiniTitulo> }
        </Titulo>
        <CloseIcon onClick={handleClose}>
          {open ?      
            <img src={BtnCollapse} alt="Collapse"/>
            : 
            <img src={BtnExpand} alt="Expand"/>
          } 
        </CloseIcon>
      </Cabecalho>
      { open && children }
    </Container>
  );
}