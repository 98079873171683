import styled from 'styled-components'

export const Container = styled.div`
  height: 80px;
  //border: 1px solid red;
  display: flex;

`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
`;

export const Logo = styled.img`
`;

export const ProfileContainer = styled.div`
`;
