import { ErrorReceita, PrismaDTO } from '@dtos/ConsultaDTO';
import { 
  Container,
  Linha,
  Label,
  Column,
  ColumnLabel,
  Select
} from './styles';
import { useConsulta } from '@hooks/useConsulta';

interface PrismaProps {
  values: PrismaDTO;
  handlePrismaChange: (values: PrismaDTO) => void;
  error: ErrorReceita;
}

export function Prisma({ values, handlePrismaChange, error }: PrismaProps) {
  
  const {     
  receita,
  errorReceita,
  validarReceita
  } = useConsulta();

  const optionsPrisma =[
    "",
    "0,25",
    "0,50",
    "0,75",
    "1,00",
    "1,50",
    "2,00",
    "2,50",
    "3,00",
    "3,50",
    "4,00",
    "5,00",
    "6,00",
    "7,00",
    "8,00",
    "9,00",
    "10,00"
  ];

  const optionsBase = [
    {value: "", label: ""},
    {value: "N", label: "nasal"},
    {value: "T", label: "temporal"},
    {value: "S", label: "superior"},
    {value: "I", label: "inferior"},
  ];

  function formatPrisma(value1: string) {
    if(value1) {
      const n = parseFloat(value1.replace(',','.').replace(' ','')).toFixed(2);
      return n.replace('.',',');
    }
  }
    
  function handleChangeOlhoDireitoPrisma(value: string) {
    values.OD.dioptriaPrismatica = value;
    handlePrismaChange(values);
    validarPrisma();
  }

  function handleChangeOlhoDireitoBase(value: string) {
    values.OD.base = value;
    if(value && error.od_prisma_base) {
      delete error.od_prisma_base;
    }
    handlePrismaChange(values);
    validarPrisma();
  }

  function handleChangeOlhoEsquerdoPrisma(value: string) {
    values.OE.dioptriaPrismatica = value;
    handlePrismaChange(values);
    validarPrisma();
  }

  function handleChangeOlhoEsquerdoBase(value: string) {
    values.OE.base = value;
    if(value && error.oe_prisma_base) {
      delete error.oe_prisma_base;
    }
    handlePrismaChange(values);
    validarPrisma();
  }

  function validarPrisma() {
    validarReceita(true)
  }

  const defaultOdPrisma = (values && values.OD && values.OD.dioptriaPrismatica && formatPrisma(values.OD.dioptriaPrismatica));
  const defaultOdBase = (values && values.OD && values.OD.base);
  const defaultOePrisma = (values && values.OE && values.OE.dioptriaPrismatica && formatPrisma(values.OE.dioptriaPrismatica));
  const defaultOeBase = (values && values.OE && values.OE.base);

  return (
  <Container>
    <Linha>
      <Label>OD</Label>
      <Column>
        <ColumnLabel className="ui_mini_labels">Prisma</ColumnLabel>
        <Select className="ui_labels" onChange={(e) => handleChangeOlhoDireitoPrisma(e.target.value)} defaultValue={defaultOdPrisma}>
          {optionsPrisma.map(valor => {
            return <option key={valor} value={valor}>{valor} {valor ? '△' : ''}</option>
          })}
        </Select>
        
      </Column>
      <Column>
        <ColumnLabel className="ui_mini_labels">Base</ColumnLabel>
        <Select className="ui_labels" onChange={(e) => handleChangeOlhoDireitoBase(e.target.value)} defaultValue={defaultOdBase}
          error={error.od_prisma_base ? "true" : "false"}
        >
          {optionsBase.map(valor => {
            return <option key={valor.value} value={valor.label}>{valor.label}</option>
          })}
        </Select>
      </Column>
    </Linha>
    <Linha>
      <Label>OE</Label>
      <Column>
        <ColumnLabel className="ui_mini_labels">Prisma</ColumnLabel>
        <Select className="ui_labels" onChange={(e) => handleChangeOlhoEsquerdoPrisma(e.target.value)} defaultValue={defaultOePrisma}>
          {optionsPrisma.map(valor => {
            return <option key={valor} value={valor}>{valor} {valor ? '△' : ''}</option>
          })}
        </Select>
      </Column>
      <Column>
        <ColumnLabel className="ui_mini_labels">Base</ColumnLabel>
        <Select className="ui_labels" onChange={(e) => handleChangeOlhoEsquerdoBase(e.target.value)} defaultValue={defaultOeBase}
          error={error.oe_prisma_base ? "true" : "false"}
          >
         {optionsBase.map(valor => {
            return <option key={valor.value} value={valor.label}>{valor.label}</option>
          })}
        </Select>
      </Column>
    </Linha>
  </Container>
  );
}