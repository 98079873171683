import { toast } from 'react-toastify';
import { Loading } from '@components/Loading';
import BtnCapturar from '@assets/new-header/botao_capturar.svg';
import BtnCapturarDisabled from '@assets/new-header/botao_capturar_disabled.svg';

import {
  Container, 
  Image,
  InputFile,
  DefaultButton
} from './styles';
import { useRef, useState } from 'react';
import { useConsulta } from '@hooks/useConsulta';
import { api } from '@services/api';
import { AppError } from '@utils/AppError';

export function BotaoCapturar({ disabled }) {
  const hiddenFileInput = useRef(null);
  const { consultaData } = useConsulta();
  const [ loadingCapturar, setLoadingCapturar ] = useState(false);

  async function handleCapturar(event) {
    setLoadingCapturar(true);

    const file = event.target.files[0]; // Log the selected file
    const base64 = await convertBase64(file)    
    const data = {
      "id": consultaData.metadata.id,
      "serializedImage": base64
    }
    try {
      const response = await api.post("reconhecer", data, {
          baseURL: "https://vera.ver.ai/api/extension/",
        }
      );
      console.log(response.data)
    } catch(error) {
      const isAppError = error instanceof AppError;
      const title = isAppError ? error.message : 'Erro. Tente novamente mais tarde.';
      toast.error(title);
      console.log(error);
    }
    setLoadingCapturar(false);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  if(disabled) {
    return (<Image src={BtnCapturarDisabled} alt="Buscar Dados" disabled={true} />);
  }

  if(loadingCapturar) {
    return (<DefaultButton><Loading /></DefaultButton>);
  }

  return (
    <Container>
      <DefaultButton onClick={handleClick}><Image src={BtnCapturar} /></DefaultButton>
      <InputFile type="file" 
        name="imageFile" 
        ref={hiddenFileInput} 
        onChange={handleCapturar} />
    </Container>
  );  
}