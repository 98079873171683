import { Route, Routes as Router } from "react-router-dom";
import { Home } from '@pages/Home';
import { Consulta } from '@pages/Consulta';
import { ConsultaContextProvider } from '@contexts/ConsultaContext';

export function AppRoutes() {
  return (    
    <Router>
      <Route path='/' element={<Home />}/>
      <Route path="/consulta/:id?" element={<ConsultaContextProvider><Consulta iniciar={false}/></ConsultaContextProvider>} />
      <Route path="/iniciar/:id?" element={<ConsultaContextProvider><Consulta iniciar={true} /></ConsultaContextProvider>} />
    </Router>
  );
}