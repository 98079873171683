import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to {
      transform: rotate(360deg);
  }
`;

export const Texto = styled.span`
  margin-right: 10px;
`;

export const Loader = styled.span`
  height: 15px;
  width: 15px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 1s linear infinite;
`;