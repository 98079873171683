import styled from 'styled-components'

export const Container = styled.div`
  background: #EFEBE9;
  min-height: 100vh;
`;

export const Spacer = styled.div`
  height: 90px;
  margin-bottom: 15px;
`;

export const ConsultasCloseIcon = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
`;

export const ConsultasContainer = styled.div`
  margin-top: 15px;
  background: #FFF;
  margin: 15px 20px 0;
  position: relative;

  padding: 15px;
  border-radius: 20px;
  gap: 15px;  
`;

export const ConsultasNaData = styled.div`
  gap: 15px;
`;

export const Title = styled.h3`
  margin-bottom:10px;
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const LinkNovaConsulta = styled.button`
  height: 23px;
  gap: 8px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
`;

export const AgendaList = styled.div`
  padding: 15px 5px 15px 5px;
  border-radius: 8px;
  border: 2px solid #9747FF;
  margin-bottom: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

interface AgendaLinhaProps {
  selected: boolean;
}

export const AgendaLinha = styled.div<AgendaLinhaProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 33px;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  gap: 5px;
  background: ${props => props.selected ? "#f5f5f5" : "transparent"};

  &:hover {
    background: #F5F5F5;
  }  
`;

export const AgendaHora = styled.div`
  display: flex;
  align-items: center;
  width: 34px;
`;

export const AgendaIcon = styled.div`
  margin: 0 0px 0 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 99px;
`;

export const AgendaPaciente = styled.div`
  flex: 1;
  margin-left: 5px;
`;

export const BtnIniciar = styled.button`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 44px;
border-radius: 50px;
gap: 10px;
background: #811EF9;

font-weight: 700;
line-height: 17px;
letter-spacing: -0.02em;
text-align: left;
color: #fff;
border: none;
`;

export const Loader = styled.div`
  animation: spin 1s infinite linear;
`;

export const BotaoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
