const refracao_property = "refracao";

import StatusNaoIniciada from "@assets/consulta_status/nao_iniciada.svg";
import StatusAgendada from "@assets/consulta_status/agendada.svg";
import StatusSalaEspera from "@assets/consulta_status/sala_de_espera.svg";
import StatusEmConsulta from '@assets/consulta_status/em_consulta.svg';
import StatusConcluida from '@assets/consulta_status/concluida.svg';
import StatusNaoAconteceu from '@assets/consulta_status/nao_aconteceu.svg';
import StatusPosConsulta from '@assets/consulta_status/pos_consulta.svg';
import { CirurgiaImplanteLioDTO, CirurgiaRefrativaDTO, DadosClinicosDTO, ExecutarRequestDTO, JornadaCadastradaDTO, JornadaDTO, PacienteDTO, ReceitaDTO } from "@dtos/ConsultaDTO";
import { hasReceita } from "./conversaoV2";

export function isValidEixo(value) {
  if(value === '') {
    return true;
  }
  if(value >= 0 && value <= 180) {
    return true;
  } else {
    return false;
  }
}

export function getWidthOfInput(inputRef) {
  if(inputRef.current) {
    var tmp = document.createElement("span");
    tmp.style.padding = "4px";
    tmp.innerHTML = inputRef.current.value.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
    var parent = inputRef.current.parentNode;
    parent.appendChild(tmp);
    var theWidth = Math.floor(tmp.getBoundingClientRect().width);
    parent.removeChild(tmp);
    return theWidth;
  }
}

export function monofocalExists(tiposDeLentes) {
  if(tiposDeLentes) {
    const existe = tiposDeLentes.filter(e  => {
      if(e.nome.includes('Monofocal')) {
        return true
      }
    });
    return existe.length > 0;
  }
}

export function getRefracaoOlhoDireito(receita) {
  if(!receita.hasOwnProperty(refracao_property)) {
    return {};
  }
  if(!receita[refracao_property].hasOwnProperty("OD")) {
    return {};
  }
  return receita[refracao_property]["OD"];
}

export function getRefracaoOlhoEsquerdo(receita) {
  if(!receita.hasOwnProperty(refracao_property)) {
    return {};
  }
  if(!receita[refracao_property].hasOwnProperty("OE")) {
    return {};
  }
  return receita[refracao_property]["OE"];
}

export function verificaSeMiopia(receita) {
  const olhoDireitoEsfericoFloat = (receita.refracao?.OD?.esf ? parseFloat(receita.refracao.OD.esf.replace(',','.')) : 0);
  const olhoEsquerdoEsfericoFloat = (receita.refracao?.OE?.esf ? parseFloat(receita.refracao.OE.esf.replace(',','.')) : 0);
  return (olhoDireitoEsfericoFloat < 0 || olhoEsquerdoEsfericoFloat < 0);
}

export function verificaSeHipermetropia(receita) {
  const olhoDireitoEsfericoFloat = (receita.refracao?.OD?.esf ? parseFloat(receita.refracao.OD.esf.replace(',','.')) : 0);
  const olhoEsquerdoEsfericoFloat = (receita.refracao?.OE?.esf ? parseFloat(receita.refracao.OE.esf.replace(',','.')) : 0);
  return (olhoDireitoEsfericoFloat > 0 || olhoEsquerdoEsfericoFloat > 0);
}

export function verificaSeAstigmatismo(receita) {
  const olhoDireitoCilindrico = (receita.refracao?.OD?.cil ? true : false);
  const olhoEsquerdoCilindrico = (receita.refracao?.OE?.cil ? true : false);
  return (olhoDireitoCilindrico || olhoEsquerdoCilindrico);
}

export function verificaSePresbiopia(receita) {
  const olhoDireitoAdicao = (receita.refracao?.OD?.adicao ? true : false);
  const olhoEsquerdoAdicao = (receita.refracao?.OE?.adicao ? true : false);
  return (olhoDireitoAdicao || olhoEsquerdoAdicao);
}

export function verificaSeEstrabismo(receita) {
  const olhoDireitoPrisma = (receita.prisma?.OD?.prisma ? true : false);
  const olhoEsquerdoPrisma = (receita.prisma?.OE?.prisma ? true : false);
  return (olhoDireitoPrisma || olhoEsquerdoPrisma);
}

export function getStatusIcon(status) {
  if(status === "not_started") {
    return StatusNaoIniciada;
  } else if(status === "1_in_appointment") {
    return StatusEmConsulta;
  } else if(status === "2_checked-in") {
    return StatusSalaEspera;
  } else if(status === "3_scheduled") {
    return StatusAgendada;
  } else if(status === "4_following-up") {
    return StatusPosConsulta;
  } else if(status === "5_noshow") {
    return StatusNaoAconteceu;
  } else if(status === "6_finished") {
    return StatusConcluida;
  } else {
    return StatusNaoIniciada;
  }
}

export function preparaData(id, info, paciente, receita, jornadas = {}) {
  var info_new = {...info};
  delete info_new.etapa;
  delete info_new.transicoes;

  var data : ExecutarRequestDTO = {
    "id": id,
    "situacao": info_new,
    "paciente": paciente
  };

  if(hasReceita(receita)) {
    data.receita = receita;
  }
  if(jornadas) {
    data.jornadas = jornadas;
  }
  return data;
}