import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import BtnRemoverUm from '../../assets/btn_remover_um.svg';
import IconRemover from '../../assets/icon_remover.svg';
import IconAdd from '../../assets/icon_add.svg';

import { 
  Container,
  Botoes,
  Linha,
  Botao,
  Lista,
  ListaItem,
  ItemTexto,
  Input,
  InputCid
} from './styles';
import { DiagnosticosDTO } from "@dtos/ConsultaDTO";
import { Radio } from "@components/Radio";

interface DiagnosticosProps {
  diagnosticos: DiagnosticosDTO,
  handleChange: (values: DiagnosticosDTO) => void;
}

export function Diagnosticos({ diagnosticos, handleChange }: DiagnosticosProps) {
  const [isAdding, setIsAdding] = useState(false);
  const [cid, setCid] = useState('');
  const [texto, setTexto] = useState('');

  function handleIncluir(e) {
    e.preventDefault();
    setIsAdding(!isAdding);
  }

  function handleRemover(codigo) {
    const items = diagnosticos.diagnosticosArray;
    const new_items = items.filter(e => e.codigo !== codigo);
    handleChange({...diagnosticos, "diagnosticosArray": new_items});
  }

  function handleAdiciona() {
    // verifica se ja existe
    const items = diagnosticos.diagnosticosArray;
    const exists = items.find(e => e.codigo === cid);
    if(exists) {
      toast.error('ja existe');
      return;
    }
    const new_items = [...items, {
      codigo: cid,
      descricao: texto,
    }];
    handleChange({...diagnosticos, "diagnosticosArray": new_items});
    setCid('');
    setTexto('');    
    setIsAdding(false);
  }

  function handleRemoverTodas() {
    handleChange({...diagnosticos, "diagnosticosArray": []});
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleAdiciona();
    }
  }

  function handleAtivar() {
    handleChange({...diagnosticos, "ativarDiagnosticos": !diagnosticos.ativarDiagnosticos});
  }

  if(!diagnosticos) {
    diagnosticos = {"ativarDiagnosticos": false, "diagnosticosArray": []};
  }
  const temDiagnosticos = (diagnosticos && diagnosticos.diagnosticosArray ? diagnosticos.diagnosticosArray.length > 0 : false);

  return (
  <>
    <Radio label="Diagnósticos" status={diagnosticos.ativarDiagnosticos}
      onChange={() => handleAtivar()}
    />
    {diagnosticos.ativarDiagnosticos &&
    <Container>
      <Botoes>
        <Botao onClick={handleIncluir} className="ui_labels">
          <img src={IconAdd} alt="incluir"/>Adicionar diagnóstico
        </Botao>
        {temDiagnosticos &&
        <Botao onClick={handleRemoverTodas} className="ui_labels" style={{ display: "flex", justifyContent: "center" }}>
          <img src={IconRemover} alt="remover"/>Remover todos
        </Botao>}
      </Botoes>
    
      {isAdding &&
        <Linha>
          <InputCid placeholder="CID" className="ui_labels" onKeyDown={handleKeyDown} value={cid} 
            onChange={(e) => setCid(e.target.value)}
          />
          <Input placeholder="Descrição" className="ui_labels" onKeyDown={handleKeyDown} value={texto} 
            onChange={(e) => setTexto(e.target.value)}
            autoFocus 
          />
          <input type="image" src={IconAdd} alt="incluir" onClick={handleAdiciona} />
        </Linha>
      }
      
      { temDiagnosticos &&
        <Lista>
          {diagnosticos.diagnosticosArray.map((item) => (
            <ListaItem key={item.codigo}>
              <ItemTexto>
                <span className="ui_mini_labels">[{item.codigo}]</span>
                <span className="paragraph">{item.descricao}</span>
              </ItemTexto>  
              <img src={BtnRemoverUm} onClick={() => handleRemover(item.codigo)} alt="remover"/>
            </ListaItem>
            ))
          }
        </Lista>
      }
    </Container>
    }
  </>  
  );
}