import styled from 'styled-components';
import { ConsultaBloco } from "@components/ConsultaBloco";

export const Container = styled(ConsultaBloco)`
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 15px;
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubTitle = styled.h3`
  color: #BFB3AF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
`;

export const OlhoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 15px;
`;

export const MiniLabel = styled.span`
  color: #BFB3AF;
`;

export const Draggable = styled.div`
  gap: 4px;
`;

export const DraggableLinha = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  & > img {
    height: 22px;
  }
`;

export const ItemBloco = styled.div`
`;

export const TecnologiasContainer = styled.div`
  gap:10px;
`;

export const TecnologiasLinha = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const Botao = styled.button`
  background: transparent;
  border: 0;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #811EF9;
  & > img {
    margin-right: 8px;
  }
`;

export const Lista = styled.div`
  border: 2px solid #BFB3AF;
  padding: 11px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;

  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;  
`;

export const Posicao = styled.span`
  color: #ffffff;
  background: #35155C;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
`;

export const Marca = styled.span`
  color: #BFB3AF;
`;