import styled from 'styled-components'

export const Container = styled.div`
  background: #F5F5F5;
  position: relative;
  padding-bottom: 20px;
  min-height: 100vh;
`;

export const Background = styled.img`
  opacity: 0.8;
  position: absolute;
  z-index: 9;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  //border: 1px solid red;
`;

export const Label = styled.label`
  margin-top: 14px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #811EF9;
`;

export const Input = styled.input`
  margin-top: 10px;
  height: 52px;
  padding: 15px;
  border-radius: 20px;
  gap: 15px;
  border: none;
  background: #FFFFFF;

  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: black;
  margin-bottom: 5px;

  &:focus {
    background: #FFFFFF;
    outline: 2px solid #811ef9;
  }
`;

export const Button = styled.button`
  width: 192px;
  height: 46px;
  border-radius: 50px;
  background: #811EF9;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #FFFFFF;
  border: none;

  margin-top: 16px;
`;

export const LinkSignUp = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  font-weight: bold;
  color: #000;
  text-decoration: underline;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;  
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

export const PassContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`;

export const Icon = styled.span`
  position: absolute;
  right: 16px;
  top: 25px;
  z-index: 2;
  & > svg {
    color: darkgray;
  }
`;