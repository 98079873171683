import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  background: #6B6969C7; //7D
  width: 430px;
  height: 100%;
  z-index: 10;
  left: 50%;
  top: 0%;
  transform: translateX(-50%);  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  backdrop-filter: blur(9px);
`;

export const Background = styled.img`
  position: absolute;
  z-index: -1;
  opacity: 0.5;
  top: 0;
  left: 0;
  border: 1px solid blue;
  width: 430px;
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: end;
  justify-content: end;
  width: 100%;
`;

export const ButtonClose = styled.button`
`;

export const Popup = styled.div`
  width: 380px;
  border-radius: 17px;
  //border: 2px solid red;
  background: #F5F5F5;
`;

export const PopupHeader = styled.p`
  height: 50px;
  background-color: #ffffff;
  border-radius: 17px 17px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopUpContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ItemMenu = styled.button`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  gap: 15px;
  border-radius: 15px;
  align-items: center;
`;

export const ItemMenuTitle = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

export const ItemMenuImages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;
