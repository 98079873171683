import styled from 'styled-components'

export const Container = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const Linha = styled.div`
  gap: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
`;

export const Coluna = styled.div`
  gap: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Label = styled.h3`
  gap: 10px;
  color: #BFB3AF;
`;

export const MiniLabel = styled.label`
  color: #BFB3AF;
`;

export const Valor = styled.input`
  width: 40px;
  height: 18px;
  padding: 2px;
  border: none;
  border: ${props => props.error === 'true' ? "2px inset #C71173" : "none"};
  border-radius: 5px;

  &:focus {
    border: 2px inset #EBE9ED;
    border:default;
    border-radius: 5px;
    border-color: #e0e0e0;
  }
`;
