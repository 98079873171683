import { toast } from 'react-toastify';
import { useConsulta } from '@hooks/useConsulta';
import { Loading } from '@components/Loading';
import {
  Container,
  Image,
  DefaultButton
} from './styles';
import ImgLer from '@assets/new-header/importar_enabled.svg';
import ImgLerDisable from '@assets/new-header/importar_disabled.svg';

export function BotaoBuscarNaPagina({ disabled, loading, changeLoading }) {
  const { 
    buscarPagina, 
  } = useConsulta();

  async function handleBuscarPagina() {
    changeLoading(true);
    try {
      await buscarPagina();
    } catch(e) {
      toast.error(e);
    }
    changeLoading(false);
  }

  if(disabled) {
    return (<Image src={ImgLerDisable} alt="Buscar Dados" disabled={true} />);
  }

  if(loading) {
    return (<DefaultButton><Loading /></DefaultButton>);
  }

  return (
  <Container onClick={handleBuscarPagina}>
    <Image src={ImgLer} alt="Buscar Dados" />
  </Container>
  );
}