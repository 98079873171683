import styled from 'styled-components'

export const Container = styled.div`
  height: 72px;
  display: flex;
`;

export const FotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0px;
  height: 72px;
  overflow: hidden;
`;

export const ProfileContainer = styled.div`
  //border: 1px solid blue;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: 16px;
`;

export const Foto = styled.img`
  width: 87px;
  height: 87px;
  border-radius: 50%;  
`;

export const Nome = styled.div`
  margin-bottom: 8px;
`;

export const Clinica = styled.select`
  background-color: transparent;
  border-radius: 20px;
  border: 0;
  padding: 2px 5px;
`;

export const Menu = styled.div`
  //border: 1px solid green;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  position: relative;
`;

export const MenuPrincipal = styled.div`
  position: absolute;
  width: 207px;
  top: 80px;
  right: 10px;
  padding: 0px 0px 5px 0px;
  border-radius: 20px;
  gap: 15px;

  box-shadow: 0px 0px 60px 0px #00000073;
  background: #fff;
  z-index: 9;
`;

export const MenuTitle = styled.a`
  display: flex;
  height: 50px;
  border-radius: 17px 17px 0px 0px;
  background: #F5F5F5;
  align-items: center;
  padding-left: 21px;
  color: black;
  cursor: pointer;
`;

export const MenuItems = styled.div`
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
`;

export const MenuIcon = styled.img` 
  margin-right: 10px
`;

export const MenuItem = styled.a`
  background: transparent;
  border: none;
  margin-top: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
`;