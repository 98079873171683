import { MemoryRouter } from "react-router-dom";
import { useAuth } from "../hooks/useAuth"; 
import { Loading } from '../components/Loading';

import {AuthRoutes} from './auth.routes';
import {AppRoutes} from './app.routes';
//import { createMemoryHistory } from "history";

//const history = createMemoryHistory();  

export default function Routes() {
  const { user, isLoadingUserStorageData } = useAuth();

  if(isLoadingUserStorageData) {
    return <Loading />
  }

  return (
    <MemoryRouter>
      { user.id ? <AppRoutes /> : <AuthRoutes />}
    </MemoryRouter>
  )
}
