import styled from 'styled-components'

export const Container = styled.button`
  border: none;
  background: transparent;
`;

export const Image = styled.img`
  display: block;
`;

export const InputFile = styled.input`
  display: none;
`;

export const DefaultButton = styled.div`
  width: 121px;
  height: 44px;
  background: ${props => props.disabled ? 'gray' : '#811EF9'};
  font-weight: 500;
  font-size: 0.8rem;
  text-align: left;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  padding: 0 20px;
`;