import { Radio } from '@components/Radio';
import { AcuidadeVisualLongeLinha } from './linha';
import {
  Container, 
  Linha,
  Label,
} from './styles';
import { AcuidadeVisualDeLongeDTO } from '@dtos/ConsultaDTO';

interface Props {
  data: AcuidadeVisualDeLongeDTO,
  handleChange: (values: AcuidadeVisualDeLongeDTO) => void;
}

export function AcuidadeVisualLonge({ data, handleChange}: Props) {

  function handleChangeOlhoDireito(values) {
    const new_data = {
      ...data,
      "OD": values
    };
    handleChange(new_data);
  }

  function handleChangeOlhoEsquerdo(values) {
    const new_data = {
      ...data,
      "OE": values
    };
    handleChange(new_data);
  }

  function handleChangeAmbosOlhos(values) {
    const new_data = {
      ...data,
      "AO": values
    };
    handleChange(new_data);
  }

  function handleAtivar() {
    handleChange({...data, "ativarAcuidadeVisualDeLonge": !data.ativarAcuidadeVisualDeLonge});
  }

  if(!data) {
    data = {
      "ativarAcuidadeVisualDeLonge": false, 
      "OD": {semCorrecao: "", correcaoAnterior: "", novaCorrecao: ""},
      "OE": {semCorrecao: "", correcaoAnterior: "", novaCorrecao: ""},
      "AO": {semCorrecao: "", correcaoAnterior: "", novaCorrecao: ""}
    };
  }

  return (
  <>
    <Radio label="Acuidade visual de longe" status={data.ativarAcuidadeVisualDeLonge} 
      onChange={handleAtivar}
    />
    {data.ativarAcuidadeVisualDeLonge &&
    <Container>
      <Linha key="od">
        <Label>OD</Label>
        <AcuidadeVisualLongeLinha 
          data={data && data.hasOwnProperty("OD") ? data.OD : {}} 
          handleChange={handleChangeOlhoDireito}
        />
      </Linha>
      <Linha key="oe">
        <Label>OE</Label>
        <AcuidadeVisualLongeLinha 
          data={data && data.hasOwnProperty("OE") ? data.OE : {}} 
          handleChange={handleChangeOlhoEsquerdo}
        />
      </Linha>
      <Linha key="ao">
        <Label>AO</Label>
        <AcuidadeVisualLongeLinha 
          data={data && data.hasOwnProperty("AO") ? data.AO : {}} 
          handleChange={handleChangeAmbosOlhos}
        />
      </Linha>
    </Container>
    }
  </>
  );
}