import { useState, useEffect } from "react";
import { 
  TecnologiasContainer,
  TecnologiasLinha,
  Botao,
  Lista,
  Item,
  Marca,
} from './styles';

import BtnRemoverUm from '../../assets/btn_remover_um.svg';
import IconRemover from '../../assets/icon_remover.svg';
import IconAdd from '../../assets/icon_add.svg';
import { Select } from "@components/Select";
import { CirurgiaImplanteLioTecnologias } from "@dtos/ConsultaDTO";
import { toast } from "react-toastify";

interface CirurgiaImplanteLIOTecnologiasProps {
  tecnologias: CirurgiaImplanteLioTecnologias[];
  tecnologiasDisponiveis: CirurgiaImplanteLioTecnologias[];
  handleChange: (data: CirurgiaImplanteLioTecnologias[]) => void;
}

export function CirurgiaImplanteLIOTecnologias({
  tecnologias, 
  tecnologiasDisponiveis,
  handleChange,
}: CirurgiaImplanteLIOTecnologiasProps) {
  const [isAdding, setIsAdding] = useState(false);
  const [options, setOptions] = useState([]);
  //const [selected, setSelected] = useState<CirurgiaImplanteLioTecnologias[]>([]);

  useEffect(() => {
    const options_itens = tecnologiasDisponiveis.map((item) => ({
      value: item.id,
      label: item.nome,
      sublabel: item.marca,
      descricao: item.descricao
    }));
    setOptions(options_itens);
  }, [tecnologiasDisponiveis])

  function handleIncluir() {    
    setIsAdding(!isAdding);
  }

  function handleAdiciona(value) {
    // verifica se ja existe
    const itemExists = tecnologias.find(e => e.id === value.value);
    if(itemExists) {
      toast.error('Item já adicionado');
      return;
    }
    const new_item = tecnologiasDisponiveis.find(e => e.id === value.value);
    handleChange([...tecnologias, new_item]);
    setIsAdding(false);
  }

  function handleRemover(value) {
    let filteredArray = tecnologias.filter(item => item.id !== value);
    handleChange(filteredArray);
  }

  function handleRemoverTodas() {
    handleChange([]);
  }

  return (
  <TecnologiasContainer>
    <TecnologiasLinha>
      <Botao onClick={handleIncluir} className="ui_labels">
        <img src={IconAdd} alt="incluir"/>Adicionar indicação
      </Botao>
      {tecnologias.length > 0 &&
      <Botao onClick={handleRemoverTodas} className="ui_labels" style={{ display: "flex", justifyContent: "center" }}>
        <img src={IconRemover} alt="remover"/>Remover todos
      </Botao>}
    </TecnologiasLinha>
    {isAdding &&
      <div style={{marginBottom: '10px'}}>
        <Select
          options={options}
          onChange={handleAdiciona} 
          autoFocus={true}
          placeholder="Escolha…"
        />
      </div>
    }
    
    { tecnologias.length > 0 &&
    <Lista>
      {tecnologias.map((item) => (
        <Item key={item.id}>
          <p >{item.nome} <Marca className="ui_mini_labels">{item.marca}</Marca></p>
          <img src={BtnRemoverUm} onClick={() => handleRemover(item.id)} alt="remover"/>
        </Item>
        ))
      }
    </Lista>
    }
  </TecnologiasContainer>
  );
}
